import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/tag";
import { colors } from "../../colorStyles";

const FilterTag = ({
  value,
  closeTag,
}: {
  value: string;
  closeTag: () => void;
}) => {
  return (
    <Tag justifyContent="space-between" background={colors.secondary} m={1}>
      <TagLabel textColor="white">{value}</TagLabel>
      <TagCloseButton color={"white"} onClick={() => closeTag()} />
    </Tag>
  );
};

export default FilterTag;
