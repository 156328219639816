import { create } from "zustand";
import { SearchAggregationApothekenRequest } from "../autogen";

interface AggregationFilterStore {
  filterValues: SearchAggregationApothekenRequest;
  resetSingleFilter: (name: string) => void;
  setFilterValues: (values: SearchAggregationApothekenRequest) => void;
  addApothekenIk: (Ik: string) => void;
  deleteApothekenIk: (Ik: string) => void;
  addPZN: (pzn: string) => void;
  deletePZN: (pzn: string) => void;
  addArz: (arz: string) => void;
  deleteArz: (arz: string) => void;
  addApothekenName: (name: string) => void;
  deleteApothekenName: (name: string) => void;
  addArtikelName: (name: string) => void;
  deleteArtikelName: (name: string) => void;
  addArtikelNummer: (nr: string) => void;
  deleteArtikelNummer: (nr: string) => void;
  addOrt: (name: string) => void;
  deleteOrt: (name: string) => void;
  addPlz: (num: string) => void;
  deletePlz: (num: string) => void;
  addAtc: (num: string) => void;
  deleteAtc: (num: string) => void;
  addWirkstoffgruppe: (num: string) => void;
  deleteWirkstoffgruppe: (num: string) => void;
  addRechnungsempfanger: (num: string) => void;
  deleteRechnungsempfanger: (num: string) => void;
  setStartMonth: (date: string) => void;
  setEndMonth: (date: string) => void;
  addPkvGkvMarker: (marker: string ) => void;
  deletePkvGkvMarker: (marker: string ) => void;
  setVertragsFilter: (
    iks: string[] | undefined,
    pzns: string[] | undefined,
    startMonth: string,
    endMonth: string
  ) => void;
}

// Define the type for keys that can be arrays
type ArrayKeys = keyof Pick<SearchAggregationApothekenRequest, 
  'markerPkvGkv' | 'apothekenIk' | 'pzn' | 'nameArz' | 'nameApotheke' | 'artikelname' | 'artikelnummer' | 'ort' | 'postleitzahl' | 'atc3code' | 'atc3wirkstoffgruppe' | 'hersteller'>;

const updateArrayInFilterValues = (
  set: any,
  key: ArrayKeys,
  value: string,
  action: 'add' | 'delete'
) => {
  set((state: AggregationFilterStore) => {
    const currentArray = (state.filterValues[key] as string[]) || [];
    let updatedArray;
    if (action === 'add') {
      updatedArray = [...currentArray, value];
    } else if (action === 'delete') {
      updatedArray = currentArray.filter((item: string) => item !== value);
    }
    return {
      filterValues: {
        ...state.filterValues,
        [key]: updatedArray,
      },
    };
  });
};

export const useFilterStore = create<AggregationFilterStore>((set, get) => ({
  filterValues: {
    markerPkvGkv: ["PKV", "GKV"], // default value for PKV/GKV
  },

  setVertragsFilter: (
    iks: string[] | undefined,
    pzns: string[] | undefined,
    startMonth: string,
    endMonth: string
  ) => {
    get().setFilterValues({});
    get().setFilterValues({ apothekenIk: iks, pzn: pzns });
    get().setStartMonth(startMonth);
    get().setEndMonth(endMonth);
  },
  setFilterValues: (values: SearchAggregationApothekenRequest) => {
    set((state) => ({
      filterValues: values,
    }));
  },
  resetSingleFilter: (name: string) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        [name]: [],
      },
    }));
  },
  setStartMonth: (date: string) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        abrechnungsmonatVon: date,
      },
    }));
  },
  setEndMonth: (date: string) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        abrechnungsmonatBis: date,
      },
    }));
  },
  addPkvGkvMarker: (newMarker: string) => {
    updateArrayInFilterValues(set, 'markerPkvGkv', newMarker, 'add');
  },
  deletePkvGkvMarker: (marker: string) => {
    updateArrayInFilterValues(set, 'markerPkvGkv', marker, 'delete');
  },
  addApothekenIk: (newIk: string) => {
    updateArrayInFilterValues(set, 'apothekenIk', newIk, 'add');
  },
  deleteApothekenIk: (Ik: string) => {
    updateArrayInFilterValues(set, 'apothekenIk', Ik, 'delete');
  },
  addPZN: (pzn: string) => {
    updateArrayInFilterValues(set, 'pzn', pzn, 'add');
  },
  deletePZN: (pzn: string) => {
    updateArrayInFilterValues(set, 'pzn', pzn, 'delete');
  },
  addArz: (arz: string) => {
    updateArrayInFilterValues(set, 'nameArz', arz, 'add');
  },
  deleteArz: (arz: string) => {
    updateArrayInFilterValues(set, 'nameArz', arz, 'delete');
  },
  addApothekenName: (name: string) => {
    updateArrayInFilterValues(set, 'nameApotheke', name, 'add');
  },
  deleteApothekenName: (name: string) => {
    updateArrayInFilterValues(set, 'nameApotheke', name, 'delete');
  },
  addArtikelName: (name: string) => {
    updateArrayInFilterValues(set, 'artikelname', name, 'add');
  },
  deleteArtikelName: (name: string) => {
    updateArrayInFilterValues(set, 'artikelname', name, 'delete');
  },
  addArtikelNummer: (nr: string) => {
    updateArrayInFilterValues(set, 'artikelnummer', nr, 'add');
  },
  deleteArtikelNummer: (nr: string) => {
    updateArrayInFilterValues(set, 'artikelnummer', nr, 'delete');
  },
  addOrt: (name: string) => {
    updateArrayInFilterValues(set, 'ort', name, 'add');
  },
  deleteOrt: (name: string) => {
    updateArrayInFilterValues(set, 'ort', name, 'delete');
  },
  addPlz: (num: string) => {
    updateArrayInFilterValues(set, 'postleitzahl', num, 'add');
  },
  deletePlz: (num: string) => {
    updateArrayInFilterValues(set, 'postleitzahl', num, 'delete');
  },
  addAtc: (num: string) => {
    updateArrayInFilterValues(set, 'atc3code', num, 'add');
  },
  deleteAtc: (num: string) => {
    updateArrayInFilterValues(set, 'atc3code', num, 'delete');
  },
  addWirkstoffgruppe: (num: string) => {
    updateArrayInFilterValues(set, 'atc3wirkstoffgruppe', num, 'add');
  },
  deleteWirkstoffgruppe: (num: string) => {
    updateArrayInFilterValues(set, 'atc3wirkstoffgruppe', num, 'delete');
  },
  addRechnungsempfanger: (num: string) => {
    updateArrayInFilterValues(set, 'hersteller', num, 'add');
  },
  deleteRechnungsempfanger: (num: string) => {
    updateArrayInFilterValues(set, 'hersteller', num, 'delete');
  },
}));
