import GroupOverview from "./subpages/GroupOverview";
import {ApothekenNavigation} from "./ApothekenAbrechnungen";
import PageLayout from "./PageLayout";

const ApothekenGruppen = () => {
  return (
    <PageLayout
        mainContent={<GroupOverview/>}
      navItems={ApothekenNavigation}
    />
  );
};

export default ApothekenGruppen;
