/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeraCheckRequest
 */
export interface HeraCheckRequest {
    /**
     * YYYYMM
     * @type {string}
     * @memberof HeraCheckRequest
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckRequest
     */
    arz?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HeraCheckRequest
     */
    markerPkvGkv?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HeraCheckRequest
     */
    pzn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HeraCheckRequest
     */
    apothekenIk?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckRequest
     */
    differenzHeraMin?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckRequest
     */
    differenzHeraMax?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckRequest
     */
    differenzHapMin?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckRequest
     */
    differenzHapMax?: number;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckRequest
     */
    fehlerart?: string;
}

/**
 * Check if a given object implements the HeraCheckRequest interface.
 */
export function instanceOfHeraCheckRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraCheckRequestFromJSON(json: any): HeraCheckRequest {
    return HeraCheckRequestFromJSONTyped(json, false);
}

export function HeraCheckRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraCheckRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': !exists(json, 'month') ? undefined : json['month'],
        'arz': !exists(json, 'arz') ? undefined : json['arz'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'apothekenIk': !exists(json, 'apothekenIk') ? undefined : json['apothekenIk'],
        'differenzHeraMin': !exists(json, 'differenzHeraMin') ? undefined : json['differenzHeraMin'],
        'differenzHeraMax': !exists(json, 'differenzHeraMax') ? undefined : json['differenzHeraMax'],
        'differenzHapMin': !exists(json, 'differenzHapMin') ? undefined : json['differenzHapMin'],
        'differenzHapMax': !exists(json, 'differenzHapMax') ? undefined : json['differenzHapMax'],
        'fehlerart': !exists(json, 'fehlerart') ? undefined : json['fehlerart'],
    };
}

export function HeraCheckRequestToJSON(value?: HeraCheckRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'arz': value.arz,
        'markerPkvGkv': value.markerPkvGkv,
        'pzn': value.pzn,
        'apothekenIk': value.apothekenIk,
        'differenzHeraMin': value.differenzHeraMin,
        'differenzHeraMax': value.differenzHeraMax,
        'differenzHapMin': value.differenzHapMin,
        'differenzHapMax': value.differenzHapMax,
        'fehlerart': value.fehlerart,
    };
}

