import {useToast} from "@chakra-ui/react";
import {ExportAggregationApothekenFormatEnum, HeraCheckRequest} from "../autogen";
import {useFilterStore} from "../hooks/ApothekenFilterStore";
import {useCustomToasts} from "../hooks/useCustomToast";
import {CustomApi, msalConfig} from "./authConfig";
import {useHeraStore} from "../hooks/heraStore";
import {PublicClientApplication} from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

export const useHeraProArzExport = () => {
  const api = new CustomApi(msalInstance);
  const values = useHeraStore();
  const request: HeraCheckRequest = { month: values.month, arz: values.arz };

  const { onSuccessToast, onErrorToast } = useCustomToasts();
  const handleExport = async (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => {
    try {
      const checkApi = await api.getCheckApi();
      const file_data = await checkApi.exportHeraProArz(request, format, separator);
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file_data);
      element.download = "Mediversum-" + Date.now() + "." + format;
      document.body.appendChild(element);
      element.click();
      onSuccessToast("Daten erfolgreich geladen");
    } catch (error) {
      onErrorToast("Fehler beim Laden");
    }
  };
  return { handleExport };
};

export const useHeraProPznExport = () => {
  const api = new CustomApi(msalInstance);
  const values = useHeraStore();
  const request: HeraCheckRequest = { month: values.month, arz: values.arz };

  const { onSuccessToast, onErrorToast } = useCustomToasts();
  const handleExport = async (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => {
    try {
      const checkApi = await api.getCheckApi();
      const file_data = await checkApi.exportHeraProPzn(request, format, separator);
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file_data);
      element.download = "Mediversum-" + Date.now() + "." + format;
      document.body.appendChild(element);
      element.click();
      onSuccessToast("Daten erfolgreich geladen");
    } catch (error) {
      onErrorToast("Fehler beim Laden");
    }
  };
  return { handleExport };
};

export const useEinzelpruefungsExport = () => {
  const { onSuccessToast, onErrorToast } = useCustomToasts();
  const api = new CustomApi(msalInstance);
  const values = useHeraStore();
  const request: HeraCheckRequest = { month: values.month, arz: values.arz };

  const handleExport = async (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => {
    try {
      const checkApi = await api.getCheckApi();
      const file_data = await checkApi.exportHeraEinzelAbrechnungen(request, format, separator);
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file_data);
      element.download = "Mediversum-" + Date.now() + "." + format;
      document.body.appendChild(element);
      element.click();
      onSuccessToast("Daten erfolgreich geladen");
    } catch (error) {
      onErrorToast("Fehler beim Laden");
    }
  };
  return { handleExport };
};

export const useAggregationsExport = () => {
  const filterValues = useFilterStore((state) => state.filterValues);
  const { onSuccessToast } = useCustomToasts();
  const toast = useToast();
  const api = new CustomApi(msalInstance);

  const handleExport = async (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => {
    try {
      const aggregationApi = await api.getAggregationApi();
      const file_data = await aggregationApi.exportAggregationApotheken(
        filterValues,
        format,
        separator
      );
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file_data);
      element.download = "Mediversum-" + Date.now() + "." + format;
      document.body.appendChild(element);
      element.click();
      onSuccessToast("Tabelle erfolgreich gespeichert.");
    } catch (error) {
      toast({
        title: "Oops!",
        description: "Tabelle konnte nicht heruntergeladen werden",
        status: "error",
        duration: 4000,
      });
    }
  };
  return { handleExport };
};
