/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationRequest } from './PaginationRequest';
import {
    PaginationRequestFromJSON,
    PaginationRequestFromJSONTyped,
    PaginationRequestToJSON,
} from './PaginationRequest';
import type { SearchSalesQuantitiesRequest } from './SearchSalesQuantitiesRequest';
import {
    SearchSalesQuantitiesRequestFromJSON,
    SearchSalesQuantitiesRequestFromJSONTyped,
    SearchSalesQuantitiesRequestToJSON,
} from './SearchSalesQuantitiesRequest';

/**
 * 
 * @export
 * @interface SearchSalesQuantitiesPaginatedRequest
 */
export interface SearchSalesQuantitiesPaginatedRequest {
    /**
     * 
     * @type {PaginationRequest}
     * @memberof SearchSalesQuantitiesPaginatedRequest
     */
    pagination?: PaginationRequest;
    /**
     * 
     * @type {SearchSalesQuantitiesRequest}
     * @memberof SearchSalesQuantitiesPaginatedRequest
     */
    searchRequest?: SearchSalesQuantitiesRequest;
}

/**
 * Check if a given object implements the SearchSalesQuantitiesPaginatedRequest interface.
 */
export function instanceOfSearchSalesQuantitiesPaginatedRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchSalesQuantitiesPaginatedRequestFromJSON(json: any): SearchSalesQuantitiesPaginatedRequest {
    return SearchSalesQuantitiesPaginatedRequestFromJSONTyped(json, false);
}

export function SearchSalesQuantitiesPaginatedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSalesQuantitiesPaginatedRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationRequestFromJSON(json['pagination']),
        'searchRequest': !exists(json, 'searchRequest') ? undefined : SearchSalesQuantitiesRequestFromJSON(json['searchRequest']),
    };
}

export function SearchSalesQuantitiesPaginatedRequestToJSON(value?: SearchSalesQuantitiesPaginatedRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationRequestToJSON(value.pagination),
        'searchRequest': SearchSalesQuantitiesRequestToJSON(value.searchRequest),
    };
}

