import {useMsal} from "@azure/msal-react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {FiChevronDown} from "react-icons/fi";
import {GoLaw} from "react-icons/go";
import {MdOutlineAttachMoney, MdOutlineLocalPharmacy} from "react-icons/md";
import {RxAvatar} from "react-icons/rx";
import {useLocation, useNavigate} from "react-router-dom";
import {colors} from "../colorStyles";
import {useHeraStore} from "../hooks/heraStore";
import {usePruefungAvailables} from "../hooks/useCheckData";
import {useEffect, useState} from "react";
import {CustomApi} from "../services/authConfig";

/**
 * This Navbar is displayed on top of the site for all pages
 */
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
    const [localVersion, setLocalVersion] = useState("");
    const [apiVersion, setApiVersion] = useState("");
  const { instance } = useMsal();
  const isNarrowScreen = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const fetchVersions = async () => {
            const api = new CustomApi(instance);
            try {
                const apiVersionData = await api.getVersion();
                console.log("API version:", apiVersionData)
                setApiVersion(apiVersionData);
            } catch (error) {
                console.error("Error fetching versions:", error);
            }

            try {
                const response = await fetch("/version.json");
                const data = await response.json();
                setLocalVersion(data.version);
            } catch (error) {
                console.error("Error fetching local version:", error);
            }
        };

        fetchVersions();
    }, [instance]);

  const activateTab = (num: number) => {
    navigate(indexToLink[num]);
  };
  const indexToLink: Record<number, string> = {
    0: "/apothekencenter",
    1: "/pruefungscenter",
    2: "/marktcenter",
  };

  const setMonth = useHeraStore((state) => state.setMonth);
  const month = useHeraStore((state) => state.month);
  const { monthOptions } = usePruefungAvailables();

  const setOldestMonth = () => {
    if (monthOptions && monthOptions.length > 0 && !month) {
      // Get the latest month
      const latestMonth = monthOptions[0].value;
      // Set the latest month in the store
      setMonth(latestMonth);
    }
  };

  return (
    <Box
      bg={colors.primary[1000]}
      height={"100%"}
      borderBottomRadius={"md"}
      position={"relative"}
    >
      <HStack height={"100%"} justifyContent={"flex-start"} width={"100%"}>
        <Image
          objectFit="contain"
          maxHeight={"100%"}
          borderRadius="full"
          src="/images/logo_round.png"
          marginLeft={4}
          p={2}
        />
        <Tabs position={"absolute"} bottom={0} left={"15vw"}>
          <TabList>
            <Tab
              fontSize={"2xl"}
              color={
                location.pathname.startsWith("/apothekencenter")
                  ? colors.secondary
                  : "white"
              }
              onClick={() => activateTab(0)}
            >
              <Icon as={MdOutlineLocalPharmacy} m={1}></Icon>
              Apothekencenter
            </Tab>
            <Tab
              fontSize={"2xl"}
              color={
                location.pathname.startsWith("/pruefungscenter")
                  ? colors.secondary
                  : "white"
              }
              onClick={() => {activateTab(1); setOldestMonth();}}
            >
              <Icon as={GoLaw} m={1}></Icon>
              Rechnungsprüfung
            </Tab>
            <Tab
              fontSize={"2xl"}
              color={
                location.pathname.startsWith("/marktcenter")
                  ? colors.secondary
                  : "white"
              }
              onClick={() => activateTab(2)}
            >
              <MdOutlineAttachMoney />
              Marktprüfung
            </Tab>
          </TabList>
        </Tabs>

        <Spacer />
        
        <Flex height="100%" alignItems={"center"} p={4}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} as={RxAvatar} />
                {!isNarrowScreen && (
                  <Text fontSize={"xl"} color="white" whiteSpace="nowrap">
                    {instance.getAllAccounts()[0]?.idTokenClaims?.name}
                  </Text>
                )}
                <Box display={{ base: "none", md: "flex" }} color={"white"}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200" zIndex={9999}>
              <MenuItem
                onClick={() => {
                  instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                }}
              >
                Abmelden
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href =
                    "https://www.d-fine.com/service-navigation/datenschutz/";
                }}
              >
                Datenschutzerklärung
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href =
                    "https://www.d-fine.com/service-navigation/impressum/";
                }}
              >
                Impressum
              </MenuItem>
                <MenuItem>
                    <>V: GUI {localVersion}, API {apiVersion}</>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Box>
  );
};

export default Navbar;
