import {Button} from "@chakra-ui/button";
import {Box, Flex, HStack, Stack, Text} from "@chakra-ui/layout";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/modal";
import {isEqual} from "lodash";
import {useNavigate} from "react-router-dom";
import {SpecialContractKey, SpecialContractResponse} from "../../autogen";
import {colors} from "../../colorStyles";
import {useFilterStore} from "../../hooks/ApothekenFilterStore";
import {createUpdateObject, useCreateContract, useDeleteContract, useUpdateContract,} from "../../hooks/useContracts";
import MonthFilter from "../generic/MonthFilter";
import NumFilter, {TagBox} from "../generic/NumFilter";
import {useState} from "react";

interface VertragsMenuProps {
    contract: SpecialContractResponse | undefined;
    updatedContract: SpecialContractResponse;
    setUpdatedContract: (contract: SpecialContractResponse) => void;
    isOpen: boolean;
    onClose: () => void;
    isUpdateMenu: boolean;
}

const VertragsMenu = ({
                          updatedContract,
                          setUpdatedContract,
                          contract,
                          isOpen,
                          onClose,
                          isUpdateMenu,
                      }: VertragsMenuProps) => {
    const navigate = useNavigate();
    const [isDeletable, setIsDeletable] = useState(false);

    const cleanClose = () => {
        onClose();
        setIsDeletable(false);
    };

    // Functions needed to update the contract object
    const addPZN = (newPZN: string) => {
        setUpdatedContract({
            ...updatedContract,
            filter: {
                ...updatedContract?.filter,
                pzns: [...(updatedContract.filter?.pzns || []), newPZN],
            },
        });
    };

    const deletePZN = (oldPZN: string) => {
        setUpdatedContract({
            ...updatedContract,
            filter: {
                ...updatedContract?.filter,
                pzns: updatedContract.filter?.pzns?.filter((pzn) => pzn !== oldPZN),
            },
        });
    };

    const addIK = (newPZN: string) => {
        setUpdatedContract({
            ...updatedContract,
            filter: {
                ...updatedContract?.filter,
                apothekenIks: [...(updatedContract.filter?.apothekenIks || []), newPZN],
            },
        });
    };

    const deleteIK = (oldPZN: string) => {
        setUpdatedContract({
            ...updatedContract,
            filter: {
                ...updatedContract?.filter,
                apothekenIks: updatedContract.filter?.apothekenIks?.filter(
                    (pzn) => pzn !== oldPZN
                ),
            },
        });
    };

    const updateStartDate = (newDate: string) => {
        setUpdatedContract({
            ...updatedContract,
            keys: {
                validFrom: parseInt(newDate),
                validTo: updatedContract.keys.validTo,
                groupName: updatedContract.keys.groupName,
            },
        });
    };

    const updateEndDate = (newDate: string) => {
        setUpdatedContract({
            ...updatedContract,
            keys: {
                validFrom: updatedContract.keys.validFrom,
                validTo: parseInt(newDate),
                groupName: updatedContract.keys.groupName,
            },
        });
    };

    const deleteContract = useDeleteContract();
    const updateContract = useUpdateContract();
    const createContract = useCreateContract();

    const setVertragsFilter = useFilterStore((state) => state.setVertragsFilter);
    const activateVertragsFilter = (
        iks: string[] | undefined,
        pzns: string[] | undefined,
        startMonth: string,
        endMonth: string
    ) => {
        setVertragsFilter(iks, pzns, startMonth, endMonth);
        navigate("/apothekencenter/abrechnungen");
    };

    return (
            <Modal size={"5xl"} isOpen={isOpen} onClose={cleanClose}>
                <ModalOverlay/>
                <ModalContent
                    backgroundColor={colors.primary[1000]}
                    textColor={"white"}
                    fontSize={"2xl"}
                >
                    <ModalHeader>
                        <Text fontSize={"3xl"}>
                            Vertragsdetails {" "}
                            {contract ? contract.keys?.groupName : ""}{" "}
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Flex direction={"row"} justifyContent={"space-between"}>
                            <Box h={"100%"} width={"50%"}>
                                <Flex direction={"row"} justifyContent={"space-between"}></Flex>
                                <Text>Zeitraum:</Text>
                                <Flex direction={"row"}>
                                    <MonthFilter
                                        name="Start Monat"
                                        setValue={(newDate) => updateStartDate(newDate)}
                                        datestring={contract?.keys.validFrom.toString()}
                                    />
                                    <MonthFilter
                                        name="End Monat"
                                        setValue={(newDate) => updateEndDate(newDate)}
                                        datestring={contract?.keys.validTo.toString()}
                                    />
                                </Flex>
                            </Box>
                            <Box h={"100%"} width={"50%"}>
                                <Text>Anzahl Einträge: {contract && isEqual(contract, updatedContract)
                                    ? contract.count
                                    : "..."}
                                </Text>
                            </Box>
                        </Flex>

                        <Stack direction={"row"} justify={"space-evenly"} marginTop={4}>
                            <Box h={"100%"} width={"50%"}>
                                <FilterDisplay
                                    title="PZNs "
                                    updatedContract={updatedContract}
                                    values={
                                        updatedContract?.filter?.pzns
                                            ? updatedContract.filter.pzns
                                            : []
                                    }
                                    addValue={addPZN}
                                    deleteValue={deletePZN}
                                />
                            </Box>
                            <Box h={"100%"} width={"50%"}>
                                <FilterDisplay
                                    title="Apotheken IKs "
                                    updatedContract={updatedContract}
                                    values={
                                        updatedContract?.filter?.apothekenIks
                                            ? updatedContract.filter.apothekenIks
                                            : []
                                    }
                                    addValue={addIK}
                                    deleteValue={deleteIK}
                                />
                            </Box>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        {isUpdateMenu! && <Button
                            marginRight={"auto"}
                            colorScheme="qyte"
                            onClick={() => {
                                activateVertragsFilter(
                                    updatedContract.filter?.apothekenIks,
                                    updatedContract.filter?.pzns,
                                    updatedContract.keys.validFrom.toString(),
                                    updatedContract.keys.validTo.toString()
                                );
                            }}
                        >
                            Abrechnungen anzeigen
                        </Button>}
                        {isUpdateMenu ? (
                            <Button
                                m={4}
                                colorScheme="qyte"
                                onClick={() => {
                                    updateContract(createUpdateObject(contract, updatedContract));
                                    cleanClose();
                                }}
                            >
                                Speichern
                            </Button>
                        ) : (
                            <Button
                                m={4}
                                colorScheme="qyte"
                                onClick={() => {
                                    createContract(updatedContract);
                                    cleanClose();
                                }}
                            >
                                Anlegen
                            </Button>
                        )}
                        {isUpdateMenu && (
                            <Button
                                colorScheme={isDeletable ? "red" : "qyte"}
                                onClick={() => {
                                    if (isDeletable) {
                                        deleteContract(
                                            contract?.keys || ({} as SpecialContractKey)
                                        );
                                        cleanClose();
                                    }
                                    setIsDeletable(!isDeletable);
                                }}
                                onBlur={() => {
                                    if (isDeletable) {
                                        setIsDeletable(false);
                                    }
                                }}
                            >
                                {isDeletable ? "Wirklich löschen?" : "Löschen"}
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
    );
};

interface FilterDisplayProps {
    title: string;
    values: string[];
    updatedContract: SpecialContractResponse;
    addValue: (newVal: string) => void;
    deleteValue: (oldVal: string) => void;
}

const FilterDisplay = ({
                           title,
                           values,
                           addValue,
                           deleteValue,
                       }: FilterDisplayProps) => {
    return (
        <Flex marginY={2} direction={"column"}>
            <HStack>
                <Box textColor={"black"}>
                    <NumFilter
                        placeholder={title.slice(0, -1)}
                        inputType="text"
                        values={values}
                        addValue={addValue}
                    />
                </Box>
            </HStack>
            <Flex
                direction={"row"}
                flexWrap={"wrap"}
                backgroundColor={"white"}
                marginY={4}
                borderRadius={"md"}
                p={1}
            >
                <TagBox values={values} deleteValue={deleteValue}/>
            </Flex>
        </Flex>
    );
};

export default VertragsMenu;
