import {HeraCheckPznData} from "../../autogen";
import Card from "../subpages/Card";
import {usePznPruefungsData} from "../../hooks/useCheckData";
import {useProPznSortingStore} from "../../hooks/sortingStore";
import formatFloat, {FormatedFloat} from "../../components/generic/FloatFormater";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import FilterBarProPzn from "../../components/rechnungsprüfung/FilterBarProPzn";


const searchNames = [
    "pzn",
    "abrechnungsmonat",
    "nameArz",
    "summeDifferenzHeraNachMengeAbgaben",
    "summeDifferenzHapNachMengeAbgaben",
    "lauerSummeHeraNachMengeAbgaben",
    "lauerSummeHapNachMengeAbgaben",
    "arzSummeHeraNachMengeAbgaben",
    "arzSummeHapNachMengeAbgaben"
];


interface PruefungTableMap {
    [key: string]: (dataRow: HeraCheckPznData | undefined) => number | string | FormatedFloat | undefined;
}

const tableMap: PruefungTableMap = {
    PZN: (row) => row?.pzn,
    Abrechnungsmonat: (row) => row?.abrechnungsmonat,
    ARZ: row => row?.nameArz,
    "Summe Differenz HeRa": (row) => formatFloat(row?.summeDifferenzHeraNachMengeAbgaben, true),
    "Summe Differenz HaP": (row) => formatFloat(row?.summeDifferenzHapNachMengeAbgaben, true),
    "Summe HeRa Lauer": (row) => formatFloat(row?.lauerSummeHeraNachMengeAbgaben, true),
    "Summe HaP Lauer": (row) => formatFloat(row?.lauerSummeHapNachMengeAbgaben, true),
    "Summe HeRa ARZ": (row) => formatFloat(row?.arzSummeHeraNachMengeAbgaben, true),
    "Summe HaP ARZ": (row) => formatFloat(row?.arzSummeHapNachMengeAbgaben, true),
};

const CheckCardPzn = () => {
    const {data, fetchNextPage} = usePznPruefungsData();
    const dataPerRow = data?.pages.map((page) => page.data?.data).flat();
    const colNames = Object.keys(tableMap);
    const dataDisplayVals = dataPerRow?.map((rowData) => {
        return colNames.map((column) => tableMap[column](rowData));
    });

    const getNumFetchedRows: () => number = () => {
        return (
            data?.pages.reduce((acc, page) => acc + (page.data?.data.length || 0), 0) || 0
        );
    };

    return (
        <Grid
            templateAreas={`"filter main"`}
            gridTemplateColumns={"20% 80%"}
            height={"82vh"}
            width={"100%"}
        >
            <GridItem area={"filter"}>
                <Box width={"100%"} height={"100%"} padding={2}>
                    <FilterBarProPzn/>
                </Box>
            </GridItem>
            <GridItem area={"main"}>
                <Box
                    width="100%"
                    height="100%"
                    paddingTop={4}
                    overflow={"hidden"}
                >
                    <div style={{ height: '82vh', overflow: 'auto' }}>
                        {Card(colNames, searchNames, useProPznSortingStore, dataDisplayVals, getNumFetchedRows, fetchNextPage)}
                    </div>
                </Box>
            </GridItem>
        </Grid>
    );
};

export default CheckCardPzn;
