import {Box} from "@chakra-ui/layout";
import {HStack, Spacer, Stack, Switch, Text} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {FilterProps} from "../generic/FilterTemplate";
import {useEinzelabrechnungenFilterStore} from "../../hooks/EinzelabrechnungenFilterStore";
import {useCheckData} from "../../hooks/useCheckData";
import msalInstance, {CustomApi} from "../../services/authConfig";
import {RangeFilterProperty} from "../generic/RangeFilter";
import FilterBar from "../generic/FilterBar";
import HeraSingleFilter from "./HeraSignleFilter";

const FilterBarEinzelabrechnungen = () => {
  const filterStore = useEinzelabrechnungenFilterStore();
  const { data } = useCheckData();

  const [errorCauseOptions, setErrorCauseOptions] = useState<string[]>([]);

  useEffect(() => {
    const getErrorCauses = async () => {
      const api = new CustomApi(msalInstance);
      const checkApi = await api.getCheckApi();
      return checkApi.getErrorCause();
    };

    getErrorCauses()
      .then(response => {
        setErrorCauseOptions(["alle Fehlerarten", ...response]);
      })
      .catch(error => {
        console.error("Error fetching error cause options", error);
      });
  }, []);

  const filterValues = useEinzelabrechnungenFilterStore((state) => state.filterValues);


  const filters: FilterProps[] = [
{
    name: "Apotheken IK",
  id: "apothekenIk",
  inputType: "numInput",
  values: filterValues.apothekenIk ? filterValues.apothekenIk : [],
  addValue: filterStore.addApothekenIk,
  deleteValue: filterStore.deleteApothekenIk,
  myRef: useRef<HTMLDivElement>(null),
},
    {
      name: "PZN",
      id: "pzn",
      inputType: "numInput",
      values: filterValues.pzn ? filterValues.pzn : [],
      addValue: filterStore.addPZN,
      deleteValue: filterStore.deletePZN,
      myRef: useRef<HTMLDivElement>(null),
    },
  ];

  const pkvGkvFilter = {
    name: "PKV/GKV",
    id: "markerPkvGkv",
    inputType: "textInput",
    values: filterValues?.markerPkvGkv ? filterValues.markerPkvGkv : ["PKV", "GKV"],
    addValue: filterStore.addPkvGkvMarker,
    deleteValue: filterStore.deletePkvGkvMarker,
    myRef: useRef<HTMLDivElement>(null),
  };

  const differenzHeraMinFilter: RangeFilterProperty = {
    name: "HeraMin",
    id: "heraMin",
    inputType: "numInput",
    value: filterValues?.differenzHeraMin ? filterValues.differenzHeraMin : 0,
    setValue: filterStore.setDifferenzHeraMin,
    myRef: useRef<HTMLDivElement>(null),
  };

  const differenzHeraMaxFilter: RangeFilterProperty = {
    name: "HeraMax",
    id: "heraMax",
    inputType: "numInput",
    value: filterValues?.differenzHeraMax ? filterValues.differenzHeraMax : 0,
    setValue: filterStore.setDifferenzHeraMax,
    myRef: useRef<HTMLDivElement>(null),
  };

  const differenzHapMinFilter: RangeFilterProperty = {
    name: "HapMin",
    id: "hapMin",
    inputType: "numInput",
    value: filterValues?.differenzHapMin ? filterValues.differenzHapMin : 0,
    setValue: filterStore.setDifferenzHapMin,
    myRef: useRef<HTMLDivElement>(null),
  };

  const differenzHapMaxFilter: RangeFilterProperty = {
    name: "HapMax",
    id: "hapMax",
    inputType: "numInput",
    value: filterValues?.differenzHapMax ? filterValues.differenzHapMax : 0,
    setValue: filterStore.setDifferenzHapMax,
    myRef: useRef<HTMLDivElement>(null),
  };

  const errorCauseFilter = {
    name: "Fehlerart",
    id: "fehlerart",
    inputType: "select",
    values: filterValues.fehlerart ? filterValues.fehlerart : "",
    setValue: (value: string) => {
      filterStore.setErrorCause(value === "alle Fehlerarten" ? "" : value);
    },
    myRef: useRef<HTMLDivElement>(null),
  };


  const PKVGKVSwitch = ({ filter, value }: { filter: typeof pkvGkvFilter, value: string }) => (
    <Box marginY={2}>
      <Stack>
        <HStack>
          <Text textColor="white">{value}</Text>
          <Spacer />
          <Switch
            sx={{ marginRight: "0.5rem" }}
            isChecked={filter.values.includes(value)}
            onChange={(e) => {
              if (e.target.checked) {
                filter.addValue(value);
              } else {
                filter.deleteValue(value);
              }
            }}
          />
        </HStack>
      </Stack>
    </Box>
  );

  const getActiveFilterNum = () => {
    return Object.values(filterValues).filter(value => value.length > 0).length;
  };

  const EinzelabrechnungFilterElements = () => {
    return (
        <div>
          <PKVGKVSwitch filter={pkvGkvFilter} value="PKV"/>
          <PKVGKVSwitch filter={pkvGkvFilter} value="GKV"/>
          <HeraSingleFilter filters={filters}/>
          {/*Todo: ARD-218 Reaktivieren von Filterelementen */}
          {/*<Divider/>*/}
          {/*<RangeFilter minFilter={differenzHeraMinFilter} maxFilter={differenzHeraMaxFilter} text="∆ HeRa"/>*/}
          {/*<RangeFilter minFilter={differenzHapMinFilter} maxFilter={differenzHapMaxFilter} text="∆ HaP "/>*/}
          {/*<Divider/>*/}
          {/*<FilterBox*/}
          {/*    name="Fehlerart"*/}
          {/*    resetFilter={() => filterStore.setErrorCause("")}*/}
          {/*    myRef={errorCauseFilter.myRef}*/}
          {/*    filterType={errorCauseFilter.inputType}*/}
          {/*>*/}
          {/*  <Select*/}
          {/*      value={errorCauseFilter.values}*/}
          {/*      onChange={(e) => errorCauseFilter.setValue(e.target.value)}*/}
          {/*      style={{color: 'white'}}*/}
          {/*  >*/}
          {/*    {errorCauseOptions.map((option) => (*/}
          {/*        <option key={option} value={option} style={{color: 'black'}}>*/}
          {/*          {option}*/}
          {/*        </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FilterBox>*/}
        </div>
    )
  };


  return (
      FilterBar(getActiveFilterNum, data?.pages[0].paginationDetails?.totalCount, EinzelabrechnungFilterElements)
  );
};

export default FilterBarEinzelabrechnungen;
