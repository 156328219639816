import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { create } from "zustand";
import { ResponseError, SearchAggregationApothekenRequest } from "../autogen";
import { CustomApi, msalConfig } from "../services/authConfig";
import { useFilterStore } from "./ApothekenFilterStore";
import { useCustomToasts } from "./useCustomToast";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

interface ActiveTemplateStore {
  activeTemplate: string;
  setActiveTemplate: (name: string) => void;
}

export const useActiveTemplateStore = create<ActiveTemplateStore>(
  (set, get) => ({
    activeTemplate: "",
    setActiveTemplate: (templateName) => {
      set((state) => ({
        activeTemplate: templateName,
      }));
    },
  })
);

const getTemplates = async () => {
  const api = new CustomApi(msalInstance);
  const templateApi = await api.getTemplateApi();
  return templateApi.getAggregationApothekenSearchTemplates();
};

const addTemplate = async (
  name: string,
  filterValues: SearchAggregationApothekenRequest
) => {
  const saveFormat = JSON.stringify(filterValues);
  const api = new CustomApi(msalInstance);
  const templateApi = await api.getTemplateApi();

  return templateApi.saveAggregationApothekenSearchTemplate({
    templateName: name,
    aggregationApothekenSearchJson: saveFormat,
    templateDescription: "detailed description",
  });
};

const deleteTemplate = async (templateName: string) => {
  const api = new CustomApi(msalInstance);
  const templateApi = await api.getTemplateApi();
  return templateApi.deleteAggregationApothekenSearchTemplate(templateName);
};

const updateTemplate = async (
  templateName: string,
  filterValues: SearchAggregationApothekenRequest
) => {
  const saveFormat = JSON.stringify(filterValues);
  const api = new CustomApi(msalInstance);
  const templateApi = await api.getTemplateApi();

  return templateApi.updateAggregationApothekenSearchTemplate({
    templateName: templateName,
    aggregationApothekenSearchJson: saveFormat,
    templateDescription: "detailed description",
  });
};

export const useFilterTemplates = () => {
  const { data, error, refetch } = useQuery<string[], Error>({
    queryKey: ["filterTemplates"],
    queryFn: getTemplates,
  });

  return { data, error, refetch };
};

export const useAddTemplate = () => {
  const queryClient = useQueryClient();
  const filterValues = useFilterStore((state) => state.filterValues);
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, string>({
    mutationFn: (name) => addTemplate(name, filterValues),
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["filterTemplates"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, string>({
    mutationFn: deleteTemplate,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["filterTemplates"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  const filterValues = useFilterStore((state) => state.filterValues);
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, string>({
    mutationFn: (name) => updateTemplate(name, filterValues),
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["filterTemplates"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};
