/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeraCheckResponse } from './HeraCheckResponse';
import {
    HeraCheckResponseFromJSON,
    HeraCheckResponseFromJSONTyped,
    HeraCheckResponseToJSON,
} from './HeraCheckResponse';
import type { PaginationResponse } from './PaginationResponse';
import {
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface HeraPaginatedResponse
 */
export interface HeraPaginatedResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof HeraPaginatedResponse
     */
    paginationDetails?: PaginationResponse;
    /**
     * 
     * @type {HeraCheckResponse}
     * @memberof HeraPaginatedResponse
     */
    data?: HeraCheckResponse;
}

/**
 * Check if a given object implements the HeraPaginatedResponse interface.
 */
export function instanceOfHeraPaginatedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraPaginatedResponseFromJSON(json: any): HeraPaginatedResponse {
    return HeraPaginatedResponseFromJSONTyped(json, false);
}

export function HeraPaginatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraPaginatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationDetails': !exists(json, 'paginationDetails') ? undefined : PaginationResponseFromJSON(json['paginationDetails']),
        'data': !exists(json, 'data') ? undefined : HeraCheckResponseFromJSON(json['data']),
    };
}

export function HeraPaginatedResponseToJSON(value?: HeraPaginatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationDetails': PaginationResponseToJSON(value.paginationDetails),
        'data': HeraCheckResponseToJSON(value.data),
    };
}

