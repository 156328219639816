import {SalesQuantities} from "../../autogen";
import {useMarktcenterSortingStore} from "../../hooks/sortingStore";
import Card from "../subpages/Card";
import formatFloat, {FormatedFloat} from "../../components/generic/FloatFormater";
import useMarktcenterData from "../../hooks/useMarktcenterData";

const searchNames = [

    "abrechnungsmonat",
    "pzn",
    "produkt",
    "herstellerGruppe",
    "markerPkvGkv",
    "iqvMenge",
    "arzAnzahlPackungenKorrigiert",
    "differenzMenge",
    "differenzMengeProzent",
    "iqvHeraMalPackung",
    "arzHeraMalPackungKorrigiert",
    "differenzHeraIqvMonatlichesMittelZuArz",
    "differenzHeraProzent",
    "lauerHeraMonatshaelfte1von2",
    "lauerHeraMonatshaelfte2von2",
    "lauerHeraMonatlichesMittel",
    "lauerHeraPreisanstieg",
    "iqvHapMalPackung",
    "arzHapMalPackungKorrigiert",
    "differenzHapIqvMonatlichesMittelZuArz",
    "differenzHapProzent",
    "lauerHapMonatshaelfte1von2",
    "lauerHapMonatshaelfte2von2",
    "lauerHapMonatlichesMittel",
    "lauerHapPreisanstieg",
    "iqvMengeAktuellerMonat",
    "artikelname",
    "packungsgroesse",
    "arzHeraMittelwertProPackung",
];

export interface TableMap {
    [key: string]: (
        dataRow: SalesQuantities | undefined
    ) => number | string | FormatedFloat | boolean | undefined;
}

const tableMap: TableMap = {

  Abgabemonat: (dataRow) => dataRow?.abrechnungsmonat,
  PZN: (dataRow) => dataRow?.pzn,
  Produkt: (dataRow) => dataRow?.produkt,
  "Hersteller Gruppe": (dataRow) => dataRow?.herstellerGruppe,
  "PKV/GKV": (dataRow) => dataRow?.markerPkvGkv,
  "IQV Menge": (dataRow) => formatFloat(dataRow?.iqvMenge, false),
  "ARZ Anzahl Packungen korrigiert": (dataRow) => formatFloat(dataRow?.arzAnzahlPackungenKorrigiert, false),
  "Differenz Menge": (dataRow) => formatFloat(dataRow?.differenzMenge, false),
  "Differenz Menge Prozent": (dataRow) => formatFloat(dataRow?.differenzMengeProzent, false),
  "IQV HeRa Mal Packung": (dataRow) => formatFloat(dataRow?.iqvHeRaMalPackung, false),
  "ARZ HeRa Mal Packung korrigiert": (dataRow) => formatFloat(dataRow?.arzHeRaMalPackungKorrigiert, false),
  "Differenz HeRa IQV HeRa monatliches Mittel Zu ARZ": (dataRow) => formatFloat(dataRow?.differenzHeRaIqvMonatlichesMittelZuArz, true),
  "Differenz HeRa Prozent": (dataRow) => formatFloat(dataRow?.differenzHeRaProzent, false),
  "Lauer HeRa Monatshälfte 1/2": (dataRow) => formatFloat(dataRow?.lauerHeRaMonatshaelfte1von2, true),
  "Lauer HeRa Monatshälfte 2/2": (dataRow) => formatFloat(dataRow?.lauerHeRaMonatshaelfte2von2, true),
  "Lauer HeRa Monatliches Mittel": (dataRow) => formatFloat(dataRow?.lauerHeRaMonatlichesMittel, true),
  "Lauer HeRa Preisanstieg": (dataRow) => dataRow?.lauerHeRaPreisanstieg,
  "IQV HaP Mal Packung": (dataRow) => formatFloat(dataRow?.iqvHapMalPackung, false),	
  "ARZ HaP Mal Packung korrigiert": (dataRow) => formatFloat(dataRow?.arzHapMalPackungKorrigiert, false),
  "Differenz HaP IQV monatliches Mittel zu ARZ": (dataRow) => formatFloat(dataRow?.differenzHapIqvMonatlichesMittelZuArz, true),
  "Differenz HaP Prozent": (dataRow) => formatFloat(dataRow?.differenzHapProzent, true),
  "Lauer HaP Monatshälfte 1/2": (dataRow) => formatFloat(dataRow?.lauerHapMonatshaelfte1von2, true),
  "Lauer HaP Monatshälfte 2/2": (dataRow) => formatFloat(dataRow?.lauerHapMonatshaelfte2von2, true),
  "Lauer HaP monatliches Mittel": (dataRow) => formatFloat(dataRow?.lauerHapMonatlichesMittel, true),
  "Lauer HaP Preisanstieg": (dataRow) => dataRow?.lauerHapPreisanstieg,
  Artikelname: (dataRow) => dataRow?.artikelname,
  Packungsgröße: (dataRow) => dataRow?.packungsgroesse,
  "ARZ Hera Mittelwert pro Packung": (dataRow) => formatFloat(dataRow?.arzHeraMittelwertProPackung, true),
};

const getDataDisplayVals = (data: any, tableMap: TableMap) => {
    const colNames = Object.keys(tableMap);
    const dataPerRow = data?.pages.map((page: { data: any; }) => page.data).flat();
    return dataPerRow?.map((rowData: SalesQuantities | undefined) => {
      return colNames.map((column) => tableMap[column](rowData));
    });
  };
  
  const getNumFetchedRows = (data: any) => {
    return data?.pages.reduce((acc: any, page: { data: string | any[]; }) => acc + (page.data?.length || 0), 0) || 0;
  };
  
  const MarktcenterCard = () => {
    const { data, fetchNextPage } = useMarktcenterData();
    const colNames = Object.keys(tableMap);
    const dataDisplayVals = getDataDisplayVals(data, tableMap);
  
    return Card(
      colNames,
      searchNames,
      useMarktcenterSortingStore,
      dataDisplayVals,
      () => getNumFetchedRows(data),
      fetchNextPage
    );
  };

export default MarktcenterCard;
