import {AggregationApotheken} from "../../autogen";
import {useAggregationApothekenSortingStore} from "../../hooks/sortingStore";
import useApothekenData from "../../hooks/useApothekenData";
import Card from "../subpages/Card";
import formatFloat, {FormatedFloat} from "../../components/generic/FloatFormater";

const searchNames = [
    "apothekenIk",
    "ort",
    "postleitzahl",
    "pzn",
    "atc3code",
    "atc3wirkstoffgruppe",
    "artikelnummer",
    "artikelname",
    "abrechnungsmonat",
    "nameApotheke",
    "sumPackungMalHap",
    "sumAbschlagsbetragGesamtEuro",
    "sumAnzahlPackungen",
    "hersteller",
    "markerBiosimilar",
    "markerPkvGkv",
    "nameArz",
];

export interface TableMap {
    [key: string]: (
        dataRow: AggregationApotheken | undefined
    ) => number | string | FormatedFloat | undefined;
}

const tableMap: TableMap = {
    "Apotheken IK": (dataRow) => dataRow?.apothekenIk,
    Ort: (dataRow) => dataRow?.ort,
    PLZ: (dataRow) => dataRow?.postleitzahl,
    PZN: (dataRow) => dataRow?.pzn,
    ATC3: (dataRow) => dataRow?.atc3code,
    Wirkstoffgruppe: (dataRow) => dataRow?.atc3wirkstoffgruppe,
    Artikelnummer: (dataRow) => dataRow?.artikelnummer,
    Artikelname: (dataRow) => dataRow?.artikelname,
    Monat: (dataRow) => dataRow?.abrechnungsmonat,
    Apothekenname: (dataRow) => dataRow?.nameApotheke,
    "HAP (Euro)": (dataRow) => formatFloat(dataRow?.sumPackungMalHap, true),
    "Abschlagsbetrag (Euro)": (dataRow) => formatFloat(dataRow?.sumAbschlagsbetragGesamtEuro, true),
    "Anzahl Packungen": (dataRow) => formatFloat(dataRow?.sumAnzahlPackungen, false),
    Hersteller: (dataRow) => dataRow?.hersteller,
    Biosimilar: (dataRow) => dataRow?.markerBiosimilar,
    "PKV/GKV": (dataRow) => dataRow?.markerPkvGkv,
    Arz: (dataRow) => dataRow?.nameArz,
};

const ApothekenCard = () => {
    const {data, fetchNextPage} = useApothekenData();
    const dataPerRow = data?.pages.map((page) => page.data).flat();
    const colNames = Object.keys(tableMap);
    const dataDisplayVals = dataPerRow?.map((rowData) => {
        return colNames.map((column) => tableMap[column](rowData));
    });

    const getNumFetchedRows: () => number = () => {
        return (
            data?.pages.reduce((acc, page) => acc + (page.data?.length || 0), 0) || 0
        );
    };
    return Card(colNames, searchNames, useAggregationApothekenSortingStore, dataDisplayVals, getNumFetchedRows, fetchNextPage);

};

export default ApothekenCard;
