/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeraCheckArzResponse } from './HeraCheckArzResponse';
import {
    HeraCheckArzResponseFromJSON,
    HeraCheckArzResponseFromJSONTyped,
    HeraCheckArzResponseToJSON,
} from './HeraCheckArzResponse';
import type { PaginationResponse } from './PaginationResponse';
import {
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface HeraARZPaginatedResponse
 */
export interface HeraARZPaginatedResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof HeraARZPaginatedResponse
     */
    paginationDetails?: PaginationResponse;
    /**
     * 
     * @type {HeraCheckArzResponse}
     * @memberof HeraARZPaginatedResponse
     */
    data?: HeraCheckArzResponse;
}

/**
 * Check if a given object implements the HeraARZPaginatedResponse interface.
 */
export function instanceOfHeraARZPaginatedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraARZPaginatedResponseFromJSON(json: any): HeraARZPaginatedResponse {
    return HeraARZPaginatedResponseFromJSONTyped(json, false);
}

export function HeraARZPaginatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraARZPaginatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationDetails': !exists(json, 'paginationDetails') ? undefined : PaginationResponseFromJSON(json['paginationDetails']),
        'data': !exists(json, 'data') ? undefined : HeraCheckArzResponseFromJSON(json['data']),
    };
}

export function HeraARZPaginatedResponseToJSON(value?: HeraARZPaginatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationDetails': PaginationResponseToJSON(value.paginationDetails),
        'data': HeraCheckArzResponseToJSON(value.data),
    };
}

