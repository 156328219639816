/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialContractFilterBody } from './SpecialContractFilterBody';
import {
    SpecialContractFilterBodyFromJSON,
    SpecialContractFilterBodyFromJSONTyped,
    SpecialContractFilterBodyToJSON,
} from './SpecialContractFilterBody';
import type { SpecialContractKey } from './SpecialContractKey';
import {
    SpecialContractKeyFromJSON,
    SpecialContractKeyFromJSONTyped,
    SpecialContractKeyToJSON,
} from './SpecialContractKey';

/**
 * 
 * @export
 * @interface SpecialContractResponse
 */
export interface SpecialContractResponse {
    /**
     * 
     * @type {SpecialContractKey}
     * @memberof SpecialContractResponse
     */
    keys: SpecialContractKey;
    /**
     * 
     * @type {SpecialContractFilterBody}
     * @memberof SpecialContractResponse
     */
    filter?: SpecialContractFilterBody;
    /**
     * number of rows which are within the filter
     * @type {number}
     * @memberof SpecialContractResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the SpecialContractResponse interface.
 */
export function instanceOfSpecialContractResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "keys" in value;

    return isInstance;
}

export function SpecialContractResponseFromJSON(json: any): SpecialContractResponse {
    return SpecialContractResponseFromJSONTyped(json, false);
}

export function SpecialContractResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialContractResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keys': SpecialContractKeyFromJSON(json['keys']),
        'filter': !exists(json, 'filter') ? undefined : SpecialContractFilterBodyFromJSON(json['filter']),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SpecialContractResponseToJSON(value?: SpecialContractResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keys': SpecialContractKeyToJSON(value.keys),
        'filter': SpecialContractFilterBodyToJSON(value.filter),
        'count': value.count,
    };
}

