/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AggregationApotheken,
  AggregationApothekenPaginationResponse,
  ErrorBody,
  SearchAggregationApothekenPaginatedRequest,
  SearchAggregationApothekenRequest,
} from '../models/index';
import {
    AggregationApothekenFromJSON,
    AggregationApothekenToJSON,
    AggregationApothekenPaginationResponseFromJSON,
    AggregationApothekenPaginationResponseToJSON,
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    SearchAggregationApothekenPaginatedRequestFromJSON,
    SearchAggregationApothekenPaginatedRequestToJSON,
    SearchAggregationApothekenRequestFromJSON,
    SearchAggregationApothekenRequestToJSON,
} from '../models/index';

export interface ExportAggregationApothekenRequest {
    searchAggregationApothekenRequest: SearchAggregationApothekenRequest;
    format?: ExportAggregationApothekenFormatEnum;
    csvSeparator?: string;
}

export interface SearchPaginatedAggregationApothekenRequest {
    searchAggregationApothekenPaginatedRequest: SearchAggregationApothekenPaginatedRequest;
}

/**
 * 
 */
export class AggregationApothekenApi extends runtime.BaseAPI {

    /**
     * Export aggregation reports in a specified format
     */
    async exportAggregationApothekenRaw(requestParameters: ExportAggregationApothekenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.searchAggregationApothekenRequest === null || requestParameters.searchAggregationApothekenRequest === undefined) {
            throw new runtime.RequiredError('searchAggregationApothekenRequest','Required parameter requestParameters.searchAggregationApothekenRequest was null or undefined when calling exportAggregationApotheken.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.csvSeparator !== undefined) {
            queryParameters['csvSeparator'] = requestParameters.csvSeparator;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/aggregation_apotheken/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAggregationApothekenRequestToJSON(requestParameters.searchAggregationApothekenRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export aggregation reports in a specified format
     */
    async exportAggregationApotheken(searchAggregationApothekenRequest: SearchAggregationApothekenRequest, format?: ExportAggregationApothekenFormatEnum, csvSeparator?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportAggregationApothekenRaw({ searchAggregationApothekenRequest: searchAggregationApothekenRequest, format: format, csvSeparator: csvSeparator }, initOverrides);
        return await response.value();
    }

    /**
     * Get the attributes of reports
     */
    async getAggregationApothekenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AggregationApotheken>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/aggregation_apotheken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AggregationApothekenFromJSON));
    }

    /**
     * Get the attributes of reports
     */
    async getAggregationApotheken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AggregationApotheken>> {
        const response = await this.getAggregationApothekenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search for aggregation reports based on various criteria
     */
    async searchPaginatedAggregationApothekenRaw(requestParameters: SearchPaginatedAggregationApothekenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AggregationApothekenPaginationResponse>> {
        if (requestParameters.searchAggregationApothekenPaginatedRequest === null || requestParameters.searchAggregationApothekenPaginatedRequest === undefined) {
            throw new runtime.RequiredError('searchAggregationApothekenPaginatedRequest','Required parameter requestParameters.searchAggregationApothekenPaginatedRequest was null or undefined when calling searchPaginatedAggregationApotheken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/aggregation_apotheken/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAggregationApothekenPaginatedRequestToJSON(requestParameters.searchAggregationApothekenPaginatedRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AggregationApothekenPaginationResponseFromJSON(jsonValue));
    }

    /**
     * Search for aggregation reports based on various criteria
     */
    async searchPaginatedAggregationApotheken(searchAggregationApothekenPaginatedRequest: SearchAggregationApothekenPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AggregationApothekenPaginationResponse> {
        const response = await this.searchPaginatedAggregationApothekenRaw({ searchAggregationApothekenPaginatedRequest: searchAggregationApothekenPaginatedRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExportAggregationApothekenFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type ExportAggregationApothekenFormatEnum = typeof ExportAggregationApothekenFormatEnum[keyof typeof ExportAggregationApothekenFormatEnum];
