import {useFilterStore} from "../../hooks/ApothekenFilterStore";
import FilterTemplate, {FilterProps} from "../generic/FilterTemplate";


const ApothekenFilter: React.FC<{
  filters: FilterProps[];
}> = ({filters}) => {
  const resetFilter = useFilterStore((state) => state.resetSingleFilter);
  return FilterTemplate(filters, resetFilter)
}

export default ApothekenFilter;
