import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import dayjs from "dayjs";
import {ResponseError} from "../autogen";
import {
  PutRenameGroupRequest,
  SpecialContract,
  SpecialContractKey,
  SpecialContractResponse,
  SpecialContractUpdate,
} from "../autogen/models";
import {CustomApi, msalConfig} from "../services/authConfig";
import {stringToDate} from "../services/date-utils";
import {useCustomToasts} from "./useCustomToast";
import {PublicClientApplication} from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

const getContracts = async () => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.getSpecialContract();
};

export const useContracts = () => {
  const { data } = useQuery<SpecialContractResponse[], Error>({
    queryKey: ["contracts"],
    queryFn: getContracts,
    initialData: [],
  });

  const groupNames = Array.from(
    new Set(data.map((contract) => contract.keys.groupName))
  );

  const startDates = data.map((contract) =>
    stringToDate(contract.keys.validFrom.toString())
  );

  const cleanStartDates: dayjs.Dayjs[] = startDates.filter(
    (startDate) => startDate !== null
  ) as dayjs.Dayjs[];

  let minDate =
    cleanStartDates.length > 0
      ? cleanStartDates.reduce((a, b) => (a < b ? a : b), dayjs())
      : dayjs();

  const endDates = data?.map((contract) =>
    stringToDate(contract.keys.validTo.toString())
  );

  const cleanEndDates: dayjs.Dayjs[] = endDates.filter(
    (endDate) => endDate !== null
  ) as dayjs.Dayjs[];

  const contractsbyGroup = groupNames.map((groupName) => {
    return data.filter((contract) => contract.keys.groupName === groupName);
  });

  const lastGroupContracts = contractsbyGroup.map((groupContracts) =>
    groupContracts.reduce((a, b) => (a.keys.validTo > b.keys.validTo ? a : b), {
      keys: { validTo: 202101, validFrom: 202001, groupName: "" },
    })
  );

  let maxDate =
    cleanEndDates.length > 0
      ? // @ts-ignore
        cleanEndDates?.reduce((a, b) => (a > b ? a : b))
      : dayjs();

  if (!minDate || !maxDate) {
    minDate = dayjs();
    maxDate = dayjs();
  }

  const contracts = data;

  return { contracts, groupNames, minDate, maxDate, lastGroupContracts };
};

const createContract = async (contract: SpecialContract) => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.postSpecialContract(contract);
};

const deleteContract = async (keys: SpecialContractKey) => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.deleteSpecialContract(keys);
};

const deleteGroup = async (groupName: string) => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.deleteGroup(groupName);
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, string>({
    mutationFn: deleteGroup,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      const message = await error.response.text();
      onErrorToast(message);
    },
  });

  return mutate;
};

const renameGroup = async ({
  oldGroupName,
  newGroupName,
}: PutRenameGroupRequest) => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.putRenameGroup({
    oldGroupName: oldGroupName,
    newGroupName: newGroupName,
  });
};

export const useRenameGroup = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, PutRenameGroupRequest>({
    mutationFn: renameGroup,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};

export const useCreateContract = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, SpecialContract>({
    mutationFn: createContract,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};

const updateContract = async (updateObject: SpecialContractUpdate) => {
  const api = new CustomApi(msalInstance);
  const contractApi = await api.getContractApi();
  return contractApi.putSpecialContract(updateObject);
};

export const createUpdateObject = (
  oldContract: SpecialContractResponse | undefined,
  updatedContract: SpecialContractResponse | undefined
) => {
  const updateObject: SpecialContractUpdate = {
    oldKeys: oldContract?.keys,
    keys: updatedContract?.keys,
    filter: updatedContract?.filter,
  };
  console.log("Update Object", updateObject);
  return updateObject;
};

export const useUpdateContract = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, SpecialContractUpdate>({
    mutationFn: updateContract,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};
export const useDeleteContract = () => {
  const queryClient = useQueryClient();
  const { onSuccessToast, onErrorToast } = useCustomToasts();

  const { mutate } = useMutation<string, ResponseError, SpecialContractKey>({
    mutationFn: deleteContract,
    onSuccess: (responseText) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      onSuccessToast(responseText);
    },
    onError: async (error) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      const text = await error.response.text();
      onErrorToast(text);
    },
  });

  return mutate;
};
