import {create} from "zustand";

export interface HeraStore {
  month: string;
  arz: string;
  setMonth: (newMonth: string) => void;
  setArz: (newArz: string) => void;
}

export const useHeraStore = create<HeraStore>((set, get) => ({
  month: "",
  arz: "",
  setMonth: (month: string) => {
    set((state) => ({
      month: month,
    }));
  },
  setArz: (arz: string) => {
    set((state) => ({
      arz: arz,
    }));
  },
}));
