import {Box, Flex} from "@chakra-ui/layout";
import {Divider, Heading, HStack, Select, Spacer, Stack, Switch, Text} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {colors} from "../../colorStyles";
import MonthFilter, {MonthFilterProps} from "../generic/MonthFilter";
import {useMarktcenterFilterStore} from "../../hooks/MarktcenterFilterStore";
import useMarktcenterData from "../../hooks/useMarktcenterData";
import {FilterProps} from "../generic/FilterTemplate";
import FilterBox from "../apothekencenter/FilterBox";
import msalInstance, {CustomApi} from "../../services/authConfig";
import MarktcenterFilter from "./MarktcenterFilter";

const FilterBarMarktcenter = () => {
  const filterStore = useMarktcenterFilterStore();
  const { data } = useMarktcenterData();

  const filterValues = useMarktcenterFilterStore((state) => state.filterValues);

  const [herstellerOptions, setHerstellerOptions] = useState<string[]>([]);

  useEffect(() => {
    const getManufacturers = async () => {
      const api = new CustomApi(msalInstance);
      const salesQuantitiesApi = await api.getsalesQuantitiesApi();
      return salesQuantitiesApi.getManufacturers();
    };

    getManufacturers()
      .then(response => {
        setHerstellerOptions(["alle Hersteller", ...response]);
      })
      .catch(error => {
        console.error("Error fetching hersteller options", error);
      });
  }, []);

  const filters: FilterProps[] = [
    {
      name: "Positive Differenzen",
      id: "positiveDifferenzen",
      inputType: "switch",
      value: filterValues.positiveDifferenzen,
      setValue: filterStore.setPositiveDifferenzen,
      myRef: useRef<HTMLDivElement>(null),
    },
    {
      name: "Negative Differenzen",
      id: "negativeDifferenzen",
      inputType: "switch",
      value: filterValues.negativeDifferenzen,
      setValue: filterStore.setNegativeDifferenzen,
      myRef: useRef<HTMLDivElement>(null),
    },
    {
      name: "PZN",
      id: "pzn",
      inputType: "numInput",
      values: filterValues.pzn ? filterValues.pzn : [],
      addValue: filterStore.addPZN,
      deleteValue: filterStore.deletePZN,
      myRef: useRef<HTMLDivElement>(null),
    },
  ];

  const pkvGkvFilter = {
    name: "PKV/GKV",
    id: "markerPkvGkv",
    inputType: "textInput",
    values: filterValues?.markerPkvGkv ? filterValues.markerPkvGkv : ["PKV", "GKV"],
    addValue: filterStore.addPkvGkvMarker,
    deleteValue: filterStore.deletePkvGkvMarker,
    myRef: useRef<HTMLDivElement>(null),
  };

  const PKVGKVSwitch = ({ filter, value }: { filter: typeof pkvGkvFilter, value: string }) => (
    <Box marginY={2}>
      <Stack>
        <HStack>
          <Text textColor="white">{value}</Text>
          <Spacer />
          <Switch
            sx={{ marginRight: "0.5rem" }}
            isChecked={filter.values.includes(value)}
            onChange={(e) => {
              if (e.target.checked) {
                filter.addValue(value);
              } else {
                filter.deleteValue(value);
              }
            }}
          />
        </HStack>
      </Stack>
    </Box>
  );

  const herstellerFilter = {
    name: "Hersteller-Gruppe",
    id: "herstellerGruppe",
    inputType: "select",
    values: filterValues.hersteller ? filterValues.hersteller : "",
    setValue: (value: string) => {
      filterStore.setHersteller(value === "alle Hersteller" ? "" : value);
    },
    myRef: useRef<HTMLDivElement>(null),
  };

  const createMonthFilter = (name: string, datestring: string | undefined, setValue: { (date: string): void; (date: string): void; }) => ({
    name,
    datestring,
    setValue,
  });

  const monthFilters: MonthFilterProps[] = [
    createMonthFilter("Start ", filterValues.abrechnungsmonatVon, filterStore.setStartMonth),
    createMonthFilter("Ende ", filterValues.abrechnungsmonatBis, filterStore.setEndMonth),
  ];

  const getActiveFilterNum = () => {
    return Object.values(filterValues).filter(value => value.length > 0).length;
  };

  return (
    <Flex direction="column" h="100%">
      <HStack align={"flex-end"} m={2}>
        <Heading size={"xl"}>Filter</Heading>
        <Text size="xs">
          {getActiveFilterNum()} Filter aktiv,
          {data?.pages[0].paginationDetails?.totalCount || 0}
          {" Ergebnisse"}
        </Text>
      </HStack>

      <Box
        flex="1"
        overflowY="scroll"
        bg={colors.primary[1000]}
        p="2"
        borderRadius={"md"}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <FilterBox
          name="Zeitraum Abgabe"
          resetFilter={() => {
            filterStore.setStartMonth("");
            filterStore.setEndMonth("");
          }}
          myRef={useRef<HTMLDivElement>(null)}
        >
          <HStack>
            {monthFilters.map((filter, index) => (
              <MonthFilter key={index} {...filter} />
            ))}
          </HStack>
        </FilterBox>
        <Divider />
        <FilterBox
          name="Hersteller"
          resetFilter={() => filterStore.setHersteller("")}
          myRef={herstellerFilter.myRef}
          filterType={herstellerFilter.inputType}
        >
          <Select
            value={herstellerFilter.values}
            onChange={(e) => herstellerFilter.setValue(e.target.value)}
            style={{ color: herstellerFilter.values === "" ? 'black' : 'white' }}
          >
            {herstellerOptions.map((option) => (
              <option key={option} value={option} style={{ color: 'black' }}>
                {option}
              </option>
            ))}
          </Select>
        </FilterBox>
        <Divider />
        <PKVGKVSwitch filter={pkvGkvFilter} value="PKV" />
        <PKVGKVSwitch filter={pkvGkvFilter} value="GKV" />
        <MarktcenterFilter filters={filters}/>
      </Box>
    </Flex>
  );
};

export default FilterBarMarktcenter;
