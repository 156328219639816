import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    dfine: {
      1000: "#003c4f",
      900: "#1a5061",
      800: "#336372",
      700: "#4d7784",
      600: "#668a95",
      500: "#809ea7",
      400: "#99b1b9",
      300: "#b3c5ca",
      200: "#ccd8dc",
      100: "#e6eced",
    },
    qyte: {
      900: "#b78102",
      800: "#be8602",
      700: "#c58a03",
      600: "#ce9103",
      500: "#E5A103", // standard button color
      400: "#e8ab33",
      300: "#ebb559",
      200: "#eebe71",
      100: "#f1c887",
    },
    qyte_passive: {
      900: "#b78102",
      800: "#be8602",
      700: "#c58a03",
      600: "#ce9103",
      500: "#EBC162", // standard button color
      400: "#e8ab33",
      300: "#ebb559",
      200: "#eebe71",
      100: "#f1c887",
    },
    qyte_active: {
      900: "#b78102",
      800: "#be8602",
      700: "#c58a03",
      600: "#ce9103",
      500: "#c58a03", // standard button color
      400: "#e8ab33",
      300: "#ebb559",
      200: "#eebe71",
      100: "#f1c887",
    },
  },
});

export default theme;
