import {Button, IconButton} from "@chakra-ui/button";
import {Switch, Tooltip} from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
import {AddIcon} from "@chakra-ui/icons";
import {Box, Center, Grid, GridItem, HStack, Text} from "@chakra-ui/layout";
import dayjs from "dayjs";
import {useState} from "react";
import {SpecialContractResponse} from "../../autogen";
import {colors} from "../../colorStyles";
import {useContracts} from "../../hooks/useContracts";
import {arrayRange, dateToIndex, dateToString, stringToDate,} from "../../services/date-utils";
import EditableField from "../../components/generic/EditableField";
import NumFilter from "../../components/generic/NumFilter";
import VertragsMenu from "../../components/apothekencenter/VertragsMenu";


const GroupOverview = () => {
  const [displayAllContracts, setDisplayAllContracts] = useState(true);
  return (
    <Grid
      templateAreas={`"header"
                      "main"
                      `}
      gridTemplateColumns={"100%"}
      gridTemplateRows={"6vh 75vh"}
    >
      <GridItem area={"header"}>
        <HStack
          height={"100%"}
          width={"100%"}
          minH={"4em"}
          paddingRight={4}
          paddingTop={-2}
          paddingBottom={6}
        >
          <Box width={"300px"}>
            <Text fontSize={"3xl"} as={"b"}>
              Gruppen
            </Text>
          </Box>
          <Switch
            onChange={() => {
              setDisplayAllContracts(!displayAllContracts);
            }}
            colorScheme="qyte"
            p={2}
          >
            nur Gruppen mit Auffälligkeiten anzeigen
          </Switch>
        </HStack>
      </GridItem>
      <GridItem area={"main"}>
        <Box height={"100%"} width={"100%"} display={"flex"} paddingRight={4}>
          <GruppenuebersichtTable displayContracts={displayAllContracts} />
        </Box>
      </GridItem>
    </Grid>
  );
};

interface TableProps {
  displayContracts: boolean;
}

const GruppenuebersichtTable = ({ displayContracts }: TableProps) => {
  const { groupNames, minDate, maxDate, contracts, lastGroupContracts } =
    useContracts();
  const totalMonths = (maxDate.year() - minDate.year() + 2) * 12;
  const yearRange = arrayRange(minDate.year(), maxDate.year() + 1, 1);
  const quartals = [1, 2, 3, 4];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUpdateMenu, setIsUpdateMenu] = useState(true);
  const [currentContract, setCurrentContract] = useState<
    SpecialContractResponse | undefined
  >(undefined);

  const [updatedContract, setUpdatedContract] =
    useState<SpecialContractResponse>(contracts[0]);

  const handleContractClick = (contract: SpecialContractResponse) => {
    setCurrentContract(contract);
    setIsUpdateMenu(true);
    setUpdatedContract({
      keys: contract?.keys,
      count: contract?.count,
      filter: contract?.filter,
    });
    onOpen();
  };

  const handleAddClick = (contract: SpecialContractResponse) => {
    setIsUpdateMenu(false);
    setCurrentContract(contract);
    setUpdatedContract({ ...contract });
    onOpen();
  };

  const handleGroupAddClick = (groupName: string) => {
    setIsUpdateMenu(false);
    const emptyContract: SpecialContractResponse = {
      keys: {
        groupName: groupName,
        validFrom: parseInt(dateToString(dayjs())),
        validTo: parseInt(dateToString(dayjs().add(3, "month"))),
      },
      filter: {
        apothekenIks: [],
        pzns: [],
      },
    };
    setCurrentContract(emptyContract);
    setUpdatedContract({ ...emptyContract });
    onOpen();
  };

  return (
    <>
      <VertragsMenu
        updatedContract={updatedContract}
        setUpdatedContract={setUpdatedContract}
        onClose={onClose}
        isOpen={isOpen}
        contract={currentContract}
        isUpdateMenu={isUpdateMenu}
      />
      <Grid
        gridTemplateColumns={totalMonths > 0 ? `300px repeat(${totalMonths}, 20px)` : 'none'}
        gridTemplateRows={groupNames.length > 0 ? `75px repeat(${groupNames.length}, 60px)` : 'none'}
        height={"100%"}
        overflow={groupNames.length > 0 ? "scroll" : "hidden"}
      >
        <GridItem
          colStart={1}
          colEnd={2}
          position={"sticky"}
          left={0}
          top={0}
          zIndex={1000}
        >
          <Box
            height={"100%"}
            width={"100%"}
            flexDirection={"row"}
            backgroundColor={"white"}
          >
            <Box
              height={groupNames.length > 0 ? "100%" : "1fr"}
              width={"calc(100% - 16px)"}
              backgroundColor={colors.primary[1000]}
              borderTopRadius={"md"}
              p={2}
            >
              <Box margin={2}>
                <NumFilter
                  placeholder="Gruppe"
                  inputType="textInput"
                  values={groupNames}
                  addValue={(value: string) => {
                    handleGroupAddClick(value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </GridItem>
        {groupNames.length > 0 && (
  <>
        {/* Definition of Quartal Header */}
        <QuartalHeaders yearRange={yearRange} quartals={quartals} />

        {/* Definition of demarkation lines */}
        <QuartalDemarkations
          groupNames={groupNames}
          quartals={quartals}
          yearRange={yearRange}
        />

        {/* Definition of contracts */}
        <ContractDisplays
          displayContracts={displayContracts}
          contracts={contracts}
          minDate={minDate}
          groupNames={groupNames}
          handleContractClick={handleContractClick}
        />

        {/* Definition of Plus Buttons */}
        <AddContractButtons
          lastGroupContracts={lastGroupContracts}
          handleAddClick={handleAddClick}
          groupNames={groupNames}
          minDate={minDate}
          displayContracts={displayContracts}
        />

        {/* Group Sidebar */}
        <GroupSideBar groupNames={groupNames} />
        </>
)}
      </Grid>
    </>
  );
};

export default GroupOverview;

interface SizeInfoFieldProps {
  count: string | undefined;
  style?: React.CSSProperties;
}

const SizeInfoField: React.FC<SizeInfoFieldProps> = ({ count, style }) => {
  return (
    <Box
      position={"absolute"}
      top={-12.5}
      right={-1}
      maxWidth={"40px"}
      backgroundColor={colors.primary[1000]}
      border={`3px solid white`}
      zIndex={50}
      textColor={"white"}
      borderRadius={"md"}
    >
      <Center paddingX={2} style={style}>{count}</Center>
    </Box>
  );
};

interface QuartalDemarkationProps {
  yearRange: number[];
  quartals: number[];
  groupNames: string[];
}

const QuartalDemarkations = ({
  yearRange,
  quartals,
  groupNames,
}: QuartalDemarkationProps) => {
  return (
    <>
      {yearRange.map((year, yearIdx) => (
        <>
          {quartals.map((quartalNum, quartalIdx) => {
            const startCol = yearIdx * 12 + quartalIdx * 3 + 2; // + 2 because of the sidebar
            const endCol = yearIdx * 12 + quartalIdx * 3 + 3 + 2;
            return (
              <GridItem
                key={`${year} ${quartalIdx}`}
                colStart={startCol}
                colEnd={endCol}
                rowStart={2}
                rowEnd={groupNames.length + 2}
              >
                <Box
                  key={`${startCol} ${endCol}`}
                  height="100%"
                  background={quartalIdx % 2 === 0 ? "#edf2f7" : "white"}
                ></Box>
              </GridItem>
            );
          })}
        </>
      ))}
    </>
  );
};

const GroupSideBar = ({ groupNames }: { groupNames: string[] }) => {
  return (
    <>
      {groupNames.map((name, index) => (
        <GridItem
          key={name}
          colStart={1}
          colEnd={2}
          rowStart={index + 2}
          rowEnd={index + 3}
          position={"sticky"}
          left={0}
          zIndex={900}
          overflow={"visible"}
        >
          <Box
            height={"100%"}
            width={"calc(100% - 16px)"}
            backgroundColor={colors.primary[1000]}
            p={2}
            borderBottomRadius={index === groupNames.length - 1 ? "md" : ""}
          >
            <EditableField name={name} />
          </Box>
        </GridItem>
      ))}
    </>
  );
};

interface AddContractButtonProps {
  lastGroupContracts: SpecialContractResponse[];
  minDate: dayjs.Dayjs | null | undefined;
  groupNames: string[];
  handleAddClick: (contract: SpecialContractResponse) => void;
  displayContracts: boolean;
}

const AddContractButtons = ({
  lastGroupContracts,
  minDate,
  groupNames,
  handleAddClick,
  displayContracts,
}: AddContractButtonProps) => {
  return (
    <>
      {lastGroupContracts?.map((contract) => {
        const groupDate = stringToDate(contract.keys?.validTo.toString());
        const lastGroupDate = groupDate ? groupDate : dayjs();
        const startDate = lastGroupDate;
        const colStart = dateToIndex(startDate, minDate);
        const rowStart =
          groupNames.findIndex((name) => name === contract.keys?.groupName) + 2;

        var newStartDate = stringToDate(contract.keys.validTo.toString())?.add(
          1,
          "month"
        );
        newStartDate = newStartDate ? newStartDate : dayjs();
        const newEndDate = newStartDate.add(1, "month");
        const formattedStartDate = dateToString(newStartDate);
        const formattedEndDate = dateToString(newEndDate);

        const editingContract: SpecialContractResponse = {
          ...contract,
          keys: {
            groupName: contract.keys.groupName,
            validFrom: parseInt(formattedStartDate),
            validTo: parseInt(formattedEndDate),
          },
        };
        return (
          <GridItem
            key={contract.keys.groupName}
            position={"relative"}
            colStart={colStart + 1}
            colEnd={colStart + 3}
            rowStart={rowStart}
            rowEnd={rowStart + 1}
            m={2}
          >
            <IconButton
              height={"100%"}
              width={"100%"}
              colorScheme="qyte"
              borderWidth={"2px"}
              icon={<AddIcon color="white" />}
              aria-label="add-choice"
              onClick={() => {
                handleAddClick(editingContract);
              }}
              visibility={
                contract.count || displayContracts ? "visible" : "hidden"
              }
            />
          </GridItem>
        );
      })}
    </>
  );
};

function formatCount(count: number) {
  if (count >= 1_000_000_000_000) return Math.round(count / 1_000_000_000_000) + 'P';
  if (count >= 1_000_000_000) return Math.round(count / 1_000_000_000) + 'G';
  if (count >= 1_000_000) return Math.round(count / 1_000_000) + 'M';
  if (count >= 1_000) return Math.round(count / 1_000) + 'k';
  return Math.round(count).toString();
}

interface ContractDisplayProps {
  contracts: SpecialContractResponse[];
  handleContractClick: (contract: SpecialContractResponse) => void;
  minDate: dayjs.Dayjs | null | undefined;
  groupNames: string[];
  displayContracts: boolean;
}

const ContractDisplays = ({
    contracts,
    minDate,
    groupNames,
    displayContracts,
    handleContractClick,
  }: ContractDisplayProps) => {
    return (
      <>
        {contracts?.map((contract) => {
          const colStart = dateToIndex(
            stringToDate(contract.keys.validFrom.toString()),
            minDate
          );
          const colEnd = dateToIndex(
            stringToDate(contract.keys.validTo.toString()),
            minDate
          );
          const rowStart =
            groupNames.findIndex((name) => name === contract.keys?.groupName) + 2;
  
          const isSmallButton = colEnd - colStart < 4;
          const hasCount = contract.count !== undefined && contract.count > 0;
          const iksCount = contract.filter?.apothekenIks?.length || 0;
          const pznsCount = contract.filter?.pzns?.length || 0;
          const fontSize = colEnd - colStart > 4 ? "100%" : (colEnd - colStart > 1 ? "65%" : "50%");

          return (
            <GridItem
              key={`${contract.keys.groupName} ${contract.keys.validFrom}`}
              position={"relative"}
              colStart={colStart}
              colEnd={colEnd + 1}
              rowStart={rowStart}
              rowEnd={rowStart + 1}
              marginX={1}
              marginY={3}
              visibility={
                displayContracts || contract.count ? "visible" : "hidden"
              }
            >
              {hasCount ? 
                <SizeInfoField 
                  count={formatCount(contract.count ?? 0)} 
                  style={{fontSize: "100%"}} 
                /> 
                : <></>
              }
                <Tooltip 
              label={`IKs: ${iksCount} | PZNs: ${pznsCount}`}
              isDisabled={!isSmallButton || !hasCount}
            >
              <Button
                height={"100%"}
                width={"100%"}
                colorScheme={hasCount ? "qyte" : "qyte_passive"}
                onClick={() => handleContractClick(contract)}
              >
                {isSmallButton && hasCount ? (
                    <span style={{ fontSize: "100%" }}></span>
                ) : (
                  <span style={{
                    fontSize: fontSize
                  }}>
                    {colEnd - colStart <= 4 ? (
                      <>
                        {`IKs ${iksCount}`}
                        <br />
                        {`PZNs ${pznsCount}`}
                      </>
                    ) : (
                      `IKs ${iksCount} | PZNs ${pznsCount}`
                    )}
                  </span>
                )}
              </Button>
              </Tooltip>
            </GridItem>
          );
        })}
      </>
    );
  };
  

interface QuartalHeadersProps {
  yearRange: number[];
  quartals: number[];
}

interface HeaderProps {
  year: number;
  quartal: number;
  startCol: number;
  endCol: number;
}

const QuartalHeaders = ({ yearRange, quartals }: QuartalHeadersProps) => {
  const headerProps: HeaderProps[] = yearRange
    .map((year, yearIdx) => {
      return quartals.map((quartalNum, quartalIdx) => {
        const startCol = yearIdx * 12 + quartalIdx * 3 + 2; // + 2 because of the sidebar
        const endCol = yearIdx * 12 + quartalIdx * 3 + 3 + 2; // + 3 because of quartal size
        return {
          year: year,
          quartal: quartalNum,
          startCol: startCol,
          endCol: endCol,
        };
      });
    })
    .flat();
  return (
    <>
      {headerProps.map((headerProp) => (
        <GridItem
          key={`${headerProp.year} ${headerProp.quartal} ${headerProp.startCol} ${headerProp.endCol}`}
          colStart={headerProp.startCol}
          colEnd={headerProp.endCol}
          rowStart={1}
          rowEnd={2}
          position={"sticky"}
          top={0}
          zIndex={900}
        >
          <Box
            height="100%"
            textColor={"white"}
            backgroundColor={colors.primary[1000]}
          >
            <Center>
              <Text fontSize="xl" as="b">
                {headerProp.year}
              </Text>
            </Center>
            <Center>
              <Text fontSize={"lg"}>{`Q${headerProp.quartal}`}</Text>
            </Center>
          </Box>
        </GridItem>
      ))}
    </>
  );
};
