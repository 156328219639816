import {useInfiniteQuery} from "@tanstack/react-query";
import {
  AggregationApothekenPaginationResponse,
  type PaginationRequest,
  type SearchAggregationApothekenPaginatedRequest
} from "../autogen";
import {CustomApi, msalConfig} from "../services/authConfig";
import {useAggregationApothekenSortingStore} from "./sortingStore";
import {useFilterStore} from "./ApothekenFilterStore";
import {PublicClientApplication} from "@azure/msal-browser";
import batchSize from "../constants/BatchSize";

const msalInstance = new PublicClientApplication(msalConfig);

const useApothekenData = () => {
  const filterValues = useFilterStore((state) => state.filterValues);
  const sortingStore = useAggregationApothekenSortingStore();

  return useInfiniteQuery<AggregationApothekenPaginationResponse, Error>({
    initialPageParam: 0,
    getNextPageParam: (paginationResponse) => {
      if (paginationResponse.paginationDetails?.page || paginationResponse.paginationDetails?.page === 0) {
        return paginationResponse.paginationDetails?.page + 1;
      }
    },
    queryKey: [filterValues, sortingStore],
    queryFn: async ({pageParam = 0}) => {
      const api = new CustomApi(msalInstance);
      const aggregationApi = await api.getAggregationApi();

      const searchAggregationApothekenPaginatedRequest = {
        pagination: {
          page: pageParam,
          batchSize: batchSize,
          orderBy: sortingStore.column,
          descending: sortingStore.descending
        } as PaginationRequest,
        searchRequest: 
        {
          ...filterValues,
          markerPkvGkv: (filterValues.markerPkvGkv || []).length > 0 ? filterValues.markerPkvGkv : "neither pkv nor gkv selected",
        }
      } as SearchAggregationApothekenPaginatedRequest;
      const response =
          await aggregationApi.searchPaginatedAggregationApotheken(searchAggregationApothekenPaginatedRequest);
      return response;
    },
  });
};

export default useApothekenData;