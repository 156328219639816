/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialContractFilterBody } from './SpecialContractFilterBody';
import {
    SpecialContractFilterBodyFromJSON,
    SpecialContractFilterBodyFromJSONTyped,
    SpecialContractFilterBodyToJSON,
} from './SpecialContractFilterBody';
import type { SpecialContractKey } from './SpecialContractKey';
import {
    SpecialContractKeyFromJSON,
    SpecialContractKeyFromJSONTyped,
    SpecialContractKeyToJSON,
} from './SpecialContractKey';

/**
 * 
 * @export
 * @interface SpecialContract
 */
export interface SpecialContract {
    /**
     * 
     * @type {SpecialContractKey}
     * @memberof SpecialContract
     */
    keys?: SpecialContractKey;
    /**
     * 
     * @type {SpecialContractFilterBody}
     * @memberof SpecialContract
     */
    filter?: SpecialContractFilterBody;
}

/**
 * Check if a given object implements the SpecialContract interface.
 */
export function instanceOfSpecialContract(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpecialContractFromJSON(json: any): SpecialContract {
    return SpecialContractFromJSONTyped(json, false);
}

export function SpecialContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialContract {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keys': !exists(json, 'keys') ? undefined : SpecialContractKeyFromJSON(json['keys']),
        'filter': !exists(json, 'filter') ? undefined : SpecialContractFilterBodyFromJSON(json['filter']),
    };
}

export function SpecialContractToJSON(value?: SpecialContract | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keys': SpecialContractKeyToJSON(value.keys),
        'filter': SpecialContractFilterBodyToJSON(value.filter),
    };
}

