/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpecialContractKey
 */
export interface SpecialContractKey {
    /**
     * 
     * @type {string}
     * @memberof SpecialContractKey
     */
    groupName: string;
    /**
     * YYYYMM
     * @type {number}
     * @memberof SpecialContractKey
     */
    validFrom: number;
    /**
     * YYYYMM
     * @type {number}
     * @memberof SpecialContractKey
     */
    validTo: number;
}

/**
 * Check if a given object implements the SpecialContractKey interface.
 */
export function instanceOfSpecialContractKey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "validFrom" in value;
    isInstance = isInstance && "validTo" in value;

    return isInstance;
}

export function SpecialContractKeyFromJSON(json: any): SpecialContractKey {
    return SpecialContractKeyFromJSONTyped(json, false);
}

export function SpecialContractKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialContractKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupName': json['groupName'],
        'validFrom': json['validFrom'],
        'validTo': json['validTo'],
    };
}

export function SpecialContractKeyToJSON(value?: SpecialContractKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupName': value.groupName,
        'validFrom': value.validFrom,
        'validTo': value.validTo,
    };
}

