import React, {useRef} from "react";
import {FilterProps} from "../generic/FilterTemplate";
import {useProPznFilterStore} from "../../hooks/ProPznFilterStore";
import {usePznPruefungsData} from "../../hooks/useCheckData";
import {RangeFilterProperty} from "../generic/RangeFilter";
import FilterBar from "../generic/FilterBar";
import HeraPznFilter from "./HeraPznFilter";

const FilterBarProPzn = () => {
    const filterStore = useProPznFilterStore();
    const {data} = usePznPruefungsData();

    const filterValues = useProPznFilterStore((state) => state.filterValues);


    const filters: FilterProps[] = [

        {
            name: "PZN",
            id: "pzn",
            inputType: "numInput",
            values: filterValues.pzn ? filterValues.pzn : [],
            addValue: filterStore.addPZN,
            deleteValue: filterStore.deletePZN,
            myRef: useRef<HTMLDivElement>(null),
        },
    ];

    const differenzHeraMinFilter: RangeFilterProperty = {
        name: "HeraMin",
        id: "heraMin",
        inputType: "numInput",
        value: filterValues?.differenzHeraMin ? filterValues.differenzHeraMin : 0,
        setValue: filterStore.setDifferenzHeraMin,
        myRef: useRef<HTMLDivElement>(null),
    };

    const differenzHeraMaxFilter: RangeFilterProperty = {
        name: "HeraMax",
        id: "heraMax",
        inputType: "numInput",
        value: filterValues?.differenzHeraMax ? filterValues.differenzHeraMax : 0,
        setValue: filterStore.setDifferenzHeraMax,
        myRef: useRef<HTMLDivElement>(null),
    };

    const differenzHapMinFilter: RangeFilterProperty = {
        name: "HapMin",
        id: "hapMin",
        inputType: "numInput",
        value: filterValues?.differenzHapMin ? filterValues.differenzHapMin : 0,
        setValue: filterStore.setDifferenzHapMin,
        myRef: useRef<HTMLDivElement>(null),
    };

    const differenzHapMaxFilter: RangeFilterProperty = {
        name: "HapMax",
        id: "hapMax",
        inputType: "numInput",
        value: filterValues?.differenzHapMax ? filterValues.differenzHapMax : 0,
        setValue: filterStore.setDifferenzHapMax,
        myRef: useRef<HTMLDivElement>(null),
    };

    const getActiveFilterNum = () => {
        return Object.values(filterValues).filter(value => value.length > 0).length;
    };

    const proPznFilterElements = () => {
        return (
            <div>
                {/*Todo: ARD-218 Reaktivieren von Filterelementen */}
                {/*<RangeFilter minFilter={differenzHeraMinFilter} maxFilter={differenzHeraMaxFilter} text="∆ HeRa"/>*/}
                {/*<RangeFilter minFilter={differenzHapMinFilter} maxFilter={differenzHapMaxFilter} text="∆ HaP "/>*/}
                <HeraPznFilter filters={filters}/>
            </div>
        )
    };


    return (
        FilterBar(getActiveFilterNum, data?.pages[0].paginationDetails?.totalCount, proPznFilterElements)
    );
};

export default FilterBarProPzn;
