import {
  Box,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { colors } from "../../colorStyles";

const FilterBox = ({
  name,
  resetFilter,
  myRef: ref,
  children,
  filterType
}: {
  name: string;
  resetFilter?: () => void;
  myRef?: React.MutableRefObject<HTMLDivElement | null>;
  children: ReactNode;
  filterType?: string;
}) => {
  return (
    <Box marginY={2}>
      <Stack>
      {filterType === "switch" ? (
          <HStack  >
            <Text textColor="white" ref={ref}>
              {name}
            </Text>
            <Spacer />
            {children}
          </HStack>
        ) : (
          <>
            <HStack justifyContent={"space-between"}>
              <Text textColor="white" ref={ref}>
                {name}
              </Text>
              <IconButton
                aria-label={"custom"}
                as={AiFillCloseCircle}
                backgroundColor={"white"}
                color={colors.secondary}
                borderRadius={"full"}
                size={"xs"}
                onClick={resetFilter}
              ></IconButton>
            </HStack>
            {children}
          </>
        )}
        
      </Stack>
    </Box>
  );
};
export default FilterBox;
