import { create } from "zustand";
import { SearchSalesQuantitiesRequest } from "../autogen";

interface MarktcenterFilterStore {
  filterValues: SearchSalesQuantitiesRequest;
  resetSingleFilter: (name: string) => void;
  setFilterValues: (values: SearchSalesQuantitiesRequest) => void;
  setHersteller: (herstellerGruppe: string) => void;
  setStartMonth: (date: string) => void;
  setEndMonth: (date: string) => void;
  setPositiveDifferenzen: (positiveDifferenzen: boolean | string) => void;
  setNegativeDifferenzen: (negativeDifferenzen: boolean | string) => void;
  addPkvGkvMarker: (marker: string) => void;
  deletePkvGkvMarker: (marker: string) => void;
  addPZN: (pzn: string) => void;
  deletePZN: (pzn: string) => void;
}

// Define the type for keys that can be arrays
type ArrayKeys = 'markerPkvGkv' | 'pzn' | 'hersteller';

const updateArrayInFilterValues = (
  set: any,
  key: ArrayKeys,
  value: string,
  action: 'add' | 'delete'
) => {
  set((state: MarktcenterFilterStore) => {
    const currentArray = (state.filterValues[key] as string[]) || [];
    let updatedArray;
    if (action === 'add') {
      updatedArray = [...currentArray, value];
    } else if (action === 'delete') {
      updatedArray = currentArray.filter((item: string) => item !== value);
    }
    return {
      filterValues: {
        ...state.filterValues,
        [key]: updatedArray,
      },
    };
  });
};

export const useMarktcenterFilterStore = create<MarktcenterFilterStore>((set) => {

  const updateFilterValue = (key: keyof SearchSalesQuantitiesRequest, value: any) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        [key]: value,
      },
    }));
  };

  return {
    filterValues: {
      positiveDifferenzen: true, // default value for Positive Differenzen
      negativeDifferenzen: true, // default value for Negative Differenzen
      hersteller: [""], // default value for Hersteller
      markerPkvGkv: ["PKV", "GKV"], // default value for PKV/GKV
    },
    setFilterValues: (values: SearchSalesQuantitiesRequest) => {
      set((state) => ({
        filterValues: values,
      }));
    },
    resetSingleFilter: (name: string) => {
      updateFilterValue(name as keyof SearchSalesQuantitiesRequest, []);
    },
    setStartMonth: (date: string) => {
      updateFilterValue('abrechnungsmonatVon', date);
    },
    setEndMonth: (date: string) => {
      updateFilterValue('abrechnungsmonatBis', date);
    },
    setHersteller: (newId: string) => {
      updateFilterValue('hersteller', [newId]);
    },
    setPositiveDifferenzen: (value: string | boolean) => {
      const boolValue = typeof value === 'boolean' ? value : value.toLowerCase() === 'true';
      updateFilterValue('positiveDifferenzen', boolValue);
    },
    setNegativeDifferenzen: (value: string | boolean) => {
      const boolValue = typeof value === 'boolean' ? value : value.toLowerCase() === 'true';
      updateFilterValue('negativeDifferenzen', boolValue);
    },
    addPkvGkvMarker: (newMarker: string) => {
      updateArrayInFilterValues(set, 'markerPkvGkv', newMarker, 'add');
    },
    deletePkvGkvMarker: (marker: string) => {
      updateArrayInFilterValues(set, 'markerPkvGkv', marker, 'delete');
    },
    addPZN: (newId: string) => {
      updateArrayInFilterValues(set, 'pzn', newId, 'add');
    },
    deletePZN: (Id: string) => {
      updateArrayInFilterValues(set, 'pzn', Id, 'delete');
    },
  };
});
