import FilterTemplate, {FilterProps} from "../generic/FilterTemplate";
import {useMarktcenterFilterStore} from "../../hooks/MarktcenterFilterStore";


const MarktcenterFilter: React.FC<{
    filters: FilterProps[];
}> = ({filters}) => {
    const resetFilter = useMarktcenterFilterStore((state) => state.resetSingleFilter);
    return FilterTemplate(filters, resetFilter)
}

export default MarktcenterFilter;
