import {CalendarIcon} from "@chakra-ui/icons";
import {Stack} from "@chakra-ui/react";
import {Popper, PopperProps, TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Dayjs} from "dayjs";
import "dayjs/locale/de";
import {debounce} from "lodash";
import {stringToDate} from "../../services/date-utils";

export type MonthFilterProps = {
  name: string;
  datestring: string | undefined;
  setValue: (date: string) => void;
};

function MonthFilter({ name, datestring, setValue }: MonthFilterProps) {
  const handleMonthInput = (date: Dayjs | null) => {
    if (date && !isNaN(date?.year()) && !isNaN(date?.month())) {
      return (
        date?.year().toString() +
        (date?.month() + 1).toString().padStart(2, "0") // conversion to our custom date format
      );
    } else {
      return "";
    }
  };

  const debouncedSetValue = debounce((date: string) => {
    setValue(date);
  }, 500);

  return (
    <>
      <Stack p={2}>
        <ThemeProvider theme={createTheme()}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              views={["year", "month"]}
              label={name}
              value={stringToDate(datestring)}
              onAccept={(value) => setValue(handleMonthInput(value))}
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              slots={{
                openPickerIcon: CalendarIcon,
                popper: CustomPopper,
                textField: (params) => (
                    <TextField {...params} variant="filled"/>
                ),
              }}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </Stack>
    </>
  );
}
export default MonthFilter;

const CustomPopper = (props: PopperProps) => (
  <Popper {...props} sx={{ ...props.sx, zIndex: 1400 }} />
);
