import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {CustomApi, msalConfig} from "../services/authConfig";
import {Option} from "../components/generic/DropDownMenu";
import {
    type HeraARZPaginatedResponse,
    HeraCheckArzResponse,
    HeraCheckAvailables,
    type HeraCheckRequest,
    HeraPaginatedResponse,
    type HeraPaginationRequest,
    HeraPZNPaginatedResponse,
    type PaginationRequest
} from "../autogen";
import {HeraStore, useHeraStore} from "./heraStore";
import {stringDateToDisplayString} from "../services/date-utils";
import {PublicClientApplication} from "@azure/msal-browser";
import {SortingStore, useEinzelPruefungSortingStore, useProPznSortingStore} from "./sortingStore";
import batchSize from "../constants/BatchSize";
import { useEinzelabrechnungenFilterStore } from "./EinzelabrechnungenFilterStore";
import { useProPznFilterStore } from "./ProPznFilterStore";

const msalInstance = new PublicClientApplication(msalConfig);

const getPruefungAvailables = async () => {
  const api = new CustomApi(msalInstance);
  const checkApi = await api.getCheckApi();
  const data = await checkApi.getHeraCheckAvailables();

  return data;
};

const getArzPruefungsData = async (month: string, arz: string) => {
  const api = new CustomApi(msalInstance);
  const checkApi = await api.getCheckApi();
  const data = await checkApi.getHeraCheckArz({ month: month, arz: arz });
  return data;
};

export const useArzPruefungsPlainData = () => {
    const heraStore = useHeraStore();
    const {data, error} = useQuery<HeraCheckArzResponse, Error>({
        queryKey: ["ArzHeraCheck", heraStore.month],
        queryFn: () => getArzPruefungsData(heraStore.month, ""),
    });
    return {data, error};
};

function createHeraPaginationRequest(sortingStore: SortingStore, heraStore: HeraStore,  pageParam: unknown, filterValues?: any,) {
    return {
        pagination: {
            page: pageParam,
            batchSize: batchSize,
            orderBy: sortingStore.column,
            descending: sortingStore.descending
        } as PaginationRequest,

        heraCheckRequest: {
            month: heraStore.month,
            arz: heraStore.arz,
            ...filterValues
        } as HeraCheckRequest

    } as HeraPaginationRequest;
}

export const useArzPruefungsData = () => {
    const heraStore = useHeraStore();
    const sortingStore = useProPznSortingStore()
    return useInfiniteQuery<HeraARZPaginatedResponse, Error>({
        initialPageParam: 0,
        getNextPageParam: (paginationResponse) => {
            if (paginationResponse.paginationDetails?.page || paginationResponse.paginationDetails?.page === 0) {
                return paginationResponse.paginationDetails?.page + 1;
            }
        },
        queryKey: ["ArzHeraCheck", heraStore.month],
        queryFn: async ({pageParam = 0}) => {
            const api = new CustomApi(msalInstance);
            const checkApi = await api.getCheckApi();
            const heraPaginationRequest = createHeraPaginationRequest(sortingStore, heraStore, pageParam);

            const response = await checkApi.getHeraCheckArzPaginated(heraPaginationRequest);
            return response;
        },

    });
};

export const useCheckData = () => {
    const heraStore = useHeraStore();
    const filterValues = useEinzelabrechnungenFilterStore(state => state.filterValues);
    const sortingStore = useEinzelPruefungSortingStore()
    return useInfiniteQuery<HeraPaginatedResponse, Error>({
        initialPageParam: 0,
        getNextPageParam: (paginationResponse) => {
            if (paginationResponse.paginationDetails?.page || paginationResponse.paginationDetails?.page === 0) {
                return paginationResponse.paginationDetails?.page + 1;
            }
        },
        queryKey: ["heraCheck", heraStore.arz, heraStore.month, filterValues],
        queryFn: async ({pageParam = 0}) => {
            const api = new CustomApi(msalInstance);
            const checkApi = await api.getCheckApi();
            const heraPaginationRequest = {
                pagination: {
                    page: pageParam,
                    batchSize: batchSize,
                    orderBy: sortingStore.column,
                    descending: sortingStore.descending
                } as PaginationRequest,
                heraCheckRequest: {
                    month: heraStore.month,
                    arz: heraStore.arz,
                    ...filterValues,
                    markerPkvGkv: (filterValues.markerPkvGkv || []).length > 0 ? filterValues.markerPkvGkv : "neither pkv nor gkv selected",
                } as HeraCheckRequest
            } as HeraPaginationRequest;

            const response = await checkApi.getHeraCheckPaginated(heraPaginationRequest);
            return response;
        },

    });
};

export const usePznPruefungsData = () => {
    const heraStore = useHeraStore();
    const filterValues = useProPznFilterStore(state => state.filterValues);
    const sortingStore = useProPznSortingStore()
    return useInfiniteQuery<HeraPZNPaginatedResponse, Error>({
        initialPageParam: 0,
        getNextPageParam: (paginationResponse) => {
            if (paginationResponse.paginationDetails?.page || paginationResponse.paginationDetails?.page === 0) {
                return paginationResponse.paginationDetails?.page + 1;
            }
        },
        queryKey: ["PznHeraCheck", heraStore.arz, heraStore.month, filterValues],
        queryFn: async ({pageParam = 0}) => {
            const api = new CustomApi(msalInstance);
            const checkApi = await api.getCheckApi();
            const heraPaginationRequest = {
                pagination: {
                    page: pageParam,
                    batchSize: batchSize,
                    orderBy: sortingStore.column,
                    descending: sortingStore.descending
                } as PaginationRequest,
                heraCheckRequest: {
                    month: heraStore.month,
                    arz: heraStore.arz,
                    ...filterValues
                } as HeraCheckRequest
            } as HeraPaginationRequest;

            const response = await checkApi.getHeraCheckPznPaginated(heraPaginationRequest);
            return response;
        },

    });
};

export const usePruefungAvailables = () => {
  const { data, error } = useQuery<HeraCheckAvailables, Error>({
    queryKey: ["pruefungAvailables"],
    queryFn: getPruefungAvailables,
  });

  const arzs = data?.arzs;
  const months = data?.months;

  const arzOptions: Option[] | undefined = arzs?.map((arz) => {
    return { display: arz, value: arz };
  });

    const monthOptions: Option[] | undefined = months?.map((month) => {
    return { display: stringDateToDisplayString(month), value: month };
  });

  return { arzOptions, monthOptions, error };
};
