/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeraCheckPznResponse } from './HeraCheckPznResponse';
import {
    HeraCheckPznResponseFromJSON,
    HeraCheckPznResponseFromJSONTyped,
    HeraCheckPznResponseToJSON,
} from './HeraCheckPznResponse';
import type { PaginationResponse } from './PaginationResponse';
import {
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface HeraPZNPaginatedResponse
 */
export interface HeraPZNPaginatedResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof HeraPZNPaginatedResponse
     */
    paginationDetails?: PaginationResponse;
    /**
     * 
     * @type {HeraCheckPznResponse}
     * @memberof HeraPZNPaginatedResponse
     */
    data?: HeraCheckPznResponse;
}

/**
 * Check if a given object implements the HeraPZNPaginatedResponse interface.
 */
export function instanceOfHeraPZNPaginatedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraPZNPaginatedResponseFromJSON(json: any): HeraPZNPaginatedResponse {
    return HeraPZNPaginatedResponseFromJSONTyped(json, false);
}

export function HeraPZNPaginatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraPZNPaginatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationDetails': !exists(json, 'paginationDetails') ? undefined : PaginationResponseFromJSON(json['paginationDetails']),
        'data': !exists(json, 'data') ? undefined : HeraCheckPznResponseFromJSON(json['data']),
    };
}

export function HeraPZNPaginatedResponseToJSON(value?: HeraPZNPaginatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationDetails': PaginationResponseToJSON(value.paginationDetails),
        'data': HeraCheckPznResponseToJSON(value.data),
    };
}

