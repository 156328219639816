/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeraCheckHAP
 */
export interface HeraCheckHAP {
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    heraHapCheck?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    abrechnungsmonat?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    abrechnungsart?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    hersteller?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    pzn?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    artikelname?: string;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    anzahlPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    diffHeraProPackung?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    diffHeraNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    heraProPackungLauer?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    heraProPackungArz?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    heraLauerMalPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    heraArzMalPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    diffHapProPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    diffHapNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    hapProPackungLauer?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    hapProPackungARZ?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    hapLauerMalPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckHAP
     */
    hapArzMalPackungen?: number;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    apothekenIk?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    nameApotheke?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    ortApotheke?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    nameArz?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    markerPkvGkv?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckHAP
     */
    customer?: string;
}

/**
 * Check if a given object implements the HeraCheckHAP interface.
 */
export function instanceOfHeraCheckHAP(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraCheckHAPFromJSON(json: any): HeraCheckHAP {
    return HeraCheckHAPFromJSONTyped(json, false);
}

export function HeraCheckHAPFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraCheckHAP {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heraHapCheck': !exists(json, 'heraHapCheck') ? undefined : json['heraHapCheck'],
        'abrechnungsmonat': !exists(json, 'abrechnungsmonat') ? undefined : json['abrechnungsmonat'],
        'abrechnungsart': !exists(json, 'abrechnungsart') ? undefined : json['abrechnungsart'],
        'hersteller': !exists(json, 'hersteller') ? undefined : json['hersteller'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'artikelname': !exists(json, 'artikelname') ? undefined : json['artikelname'],
        'anzahlPackungen': !exists(json, 'anzahlPackungen') ? undefined : json['anzahlPackungen'],
        'diffHeraProPackung': !exists(json, 'diffHeraProPackung') ? undefined : json['diffHeraProPackung'],
        'diffHeraNachMengeAbgaben': !exists(json, 'diffHeraNachMengeAbgaben') ? undefined : json['diffHeraNachMengeAbgaben'],
        'heraProPackungLauer': !exists(json, 'heraProPackungLauer') ? undefined : json['heraProPackungLauer'],
        'heraProPackungArz': !exists(json, 'heraProPackungArz') ? undefined : json['heraProPackungArz'],
        'heraLauerMalPackungen': !exists(json, 'heraLauerMalPackungen') ? undefined : json['heraLauerMalPackungen'],
        'heraArzMalPackungen': !exists(json, 'heraArzMalPackungen') ? undefined : json['heraArzMalPackungen'],
        'diffHapProPackungen': !exists(json, 'diffHapProPackungen') ? undefined : json['diffHapProPackungen'],
        'diffHapNachMengeAbgaben': !exists(json, 'diffHapNachMengeAbgaben') ? undefined : json['diffHapNachMengeAbgaben'],
        'hapProPackungLauer': !exists(json, 'hapProPackungLauer') ? undefined : json['hapProPackungLauer'],
        'hapProPackungARZ': !exists(json, 'hapProPackungARZ') ? undefined : json['hapProPackungARZ'],
        'hapLauerMalPackungen': !exists(json, 'hapLauerMalPackungen') ? undefined : json['hapLauerMalPackungen'],
        'hapArzMalPackungen': !exists(json, 'hapArzMalPackungen') ? undefined : json['hapArzMalPackungen'],
        'apothekenIk': !exists(json, 'apothekenIk') ? undefined : json['apothekenIk'],
        'nameApotheke': !exists(json, 'name_apotheke') ? undefined : json['name_apotheke'],
        'ortApotheke': !exists(json, 'ort_apotheke') ? undefined : json['ort_apotheke'],
        'nameArz': !exists(json, 'nameArz') ? undefined : json['nameArz'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
    };
}

export function HeraCheckHAPToJSON(value?: HeraCheckHAP | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heraHapCheck': value.heraHapCheck,
        'abrechnungsmonat': value.abrechnungsmonat,
        'abrechnungsart': value.abrechnungsart,
        'hersteller': value.hersteller,
        'pzn': value.pzn,
        'artikelname': value.artikelname,
        'anzahlPackungen': value.anzahlPackungen,
        'diffHeraProPackung': value.diffHeraProPackung,
        'diffHeraNachMengeAbgaben': value.diffHeraNachMengeAbgaben,
        'heraProPackungLauer': value.heraProPackungLauer,
        'heraProPackungArz': value.heraProPackungArz,
        'heraLauerMalPackungen': value.heraLauerMalPackungen,
        'heraArzMalPackungen': value.heraArzMalPackungen,
        'diffHapProPackungen': value.diffHapProPackungen,
        'diffHapNachMengeAbgaben': value.diffHapNachMengeAbgaben,
        'hapProPackungLauer': value.hapProPackungLauer,
        'hapProPackungARZ': value.hapProPackungARZ,
        'hapLauerMalPackungen': value.hapLauerMalPackungen,
        'hapArzMalPackungen': value.hapArzMalPackungen,
        'apothekenIk': value.apothekenIk,
        'name_apotheke': value.nameApotheke,
        'ort_apotheke': value.ortApotheke,
        'nameArz': value.nameArz,
        'markerPkvGkv': value.markerPkvGkv,
        'customer': value.customer,
    };
}

