/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AggregationApothekenSearchTemplateRequest,
  ErrorBody,
} from '../models/index';
import {
    AggregationApothekenSearchTemplateRequestFromJSON,
    AggregationApothekenSearchTemplateRequestToJSON,
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
} from '../models/index';

export interface DeleteAggregationApothekenSearchTemplateRequest {
    templateName: string;
}

export interface GetAggregationApothekenSearchTemplateRequest {
    templateName: string;
}

export interface SaveAggregationApothekenSearchTemplateRequest {
    aggregationApothekenSearchTemplateRequest: AggregationApothekenSearchTemplateRequest;
}

export interface UpdateAggregationApothekenSearchTemplateRequest {
    aggregationApothekenSearchTemplateRequest: AggregationApothekenSearchTemplateRequest;
}

/**
 * 
 */
export class AggregationApothekenSearchTemplatesApi extends runtime.BaseAPI {

    /**
     * delete the template with template name
     */
    async deleteAggregationApothekenSearchTemplateRaw(requestParameters: DeleteAggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.templateName === null || requestParameters.templateName === undefined) {
            throw new runtime.RequiredError('templateName','Required parameter requestParameters.templateName was null or undefined when calling deleteAggregationApothekenSearchTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/aggregation_apotheken_search_templates/delete/{templateName}`.replace(`{${"templateName"}}`, encodeURIComponent(String(requestParameters.templateName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * delete the template with template name
     */
    async deleteAggregationApothekenSearchTemplate(templateName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteAggregationApothekenSearchTemplateRaw({ templateName: templateName }, initOverrides);
        return await response.value();
    }

    /**
     * get the template with template name
     */
    async getAggregationApothekenSearchTemplateRaw(requestParameters: GetAggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AggregationApothekenSearchTemplateRequest>> {
        if (requestParameters.templateName === null || requestParameters.templateName === undefined) {
            throw new runtime.RequiredError('templateName','Required parameter requestParameters.templateName was null or undefined when calling getAggregationApothekenSearchTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/aggregation_apotheken_search_templates/{templateName}`.replace(`{${"templateName"}}`, encodeURIComponent(String(requestParameters.templateName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AggregationApothekenSearchTemplateRequestFromJSON(jsonValue));
    }

    /**
     * get the template with template name
     */
    async getAggregationApothekenSearchTemplate(templateName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AggregationApothekenSearchTemplateRequest> {
        const response = await this.getAggregationApothekenSearchTemplateRaw({ templateName: templateName }, initOverrides);
        return await response.value();
    }

    /**
     * get the template names
     */
    async getAggregationApothekenSearchTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/aggregation_apotheken_search_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * get the template names
     */
    async getAggregationApothekenSearchTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAggregationApothekenSearchTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Save a Template with search conditions
     */
    async saveAggregationApothekenSearchTemplateRaw(requestParameters: SaveAggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aggregationApothekenSearchTemplateRequest === null || requestParameters.aggregationApothekenSearchTemplateRequest === undefined) {
            throw new runtime.RequiredError('aggregationApothekenSearchTemplateRequest','Required parameter requestParameters.aggregationApothekenSearchTemplateRequest was null or undefined when calling saveAggregationApothekenSearchTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/aggregation_apotheken_search_templates/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AggregationApothekenSearchTemplateRequestToJSON(requestParameters.aggregationApothekenSearchTemplateRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Save a Template with search conditions
     */
    async saveAggregationApothekenSearchTemplate(aggregationApothekenSearchTemplateRequest: AggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveAggregationApothekenSearchTemplateRaw({ aggregationApothekenSearchTemplateRequest: aggregationApothekenSearchTemplateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Save a Template with search conditions
     */
    async updateAggregationApothekenSearchTemplateRaw(requestParameters: UpdateAggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aggregationApothekenSearchTemplateRequest === null || requestParameters.aggregationApothekenSearchTemplateRequest === undefined) {
            throw new runtime.RequiredError('aggregationApothekenSearchTemplateRequest','Required parameter requestParameters.aggregationApothekenSearchTemplateRequest was null or undefined when calling updateAggregationApothekenSearchTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/aggregation_apotheken_search_templates/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AggregationApothekenSearchTemplateRequestToJSON(requestParameters.aggregationApothekenSearchTemplateRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Save a Template with search conditions
     */
    async updateAggregationApothekenSearchTemplate(aggregationApothekenSearchTemplateRequest: AggregationApothekenSearchTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateAggregationApothekenSearchTemplateRaw({ aggregationApothekenSearchTemplateRequest: aggregationApothekenSearchTemplateRequest }, initOverrides);
        return await response.value();
    }

}
