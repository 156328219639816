import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import { useLocation, useNavigate } from "react-router";

/**
 * subpath: the path of the page which is registerd in the router
 * name: The text to be displayed in the Navbar
 */
interface NavItems {
  subpath: string;
  name: string;
}

/**
 * rootPath: the path of the tab (f.ex "/apothekencenter")
 */
export interface PageNavigationProps {
  rootPath: string;
  navItems: NavItems[];
}

const PageNavigationBar = ({ rootPath, navItems }: PageNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <HStack justifyContent={"space-between"}>
      <HStack gap={0}>
        {navItems.map((item, idx) => {
          const itemPath = rootPath + item.subpath;
          return (
            <Button
              key={idx}
              marginX={0}
              borderLeftRadius={idx !== 0 ? "0px" : ""}
              borderRightRadius={idx !== navItems.length - 1 ? "0px" : ""}
              isActive={
                location.pathname === itemPath ||
                (idx === 0 && rootPath === location.pathname) // The base path is registered to lead to the first subpage in the nav bar for all pages currently
              }
              onClick={() => navigate(itemPath)}
              colorScheme="qyte"
            >
              {item.name}
            </Button>
          );
        })}
      </HStack>
    </HStack>
  );
};

export default PageNavigationBar;
