/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesQuantities
 */
export interface SalesQuantities {
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    abrechnungsmonat?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    pzn?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    produkt?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    herstellerID?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    herstellerGruppe?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    markerPkvGkv?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    iqvMenge?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    arzAnzahlPackungenKorrigiert?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzMenge?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzMengeProzent?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    iqvHeRaMalPackung?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    arzHeRaMalPackungKorrigiert?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzHeRaIqvMonatlichesMittelZuArz?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzHeRaProzent?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHeRaMonatshaelfte1von2?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHeRaMonatshaelfte2von2?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHeRaMonatlichesMittel?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    lauerHeRaPreisanstieg?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    iqvHapMalPackung?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    arzHapMalPackungKorrigiert?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzHapIqvMonatlichesMittelZuArz?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    differenzHapProzent?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHapMonatshaelfte1von2?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHapMonatshaelfte2von2?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    lauerHapMonatlichesMittel?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    lauerHapPreisanstieg?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    artikelname?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuantities
     */
    packungsgroesse?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesQuantities
     */
    arzHeraMittelwertProPackung?: number;
}

/**
 * Check if a given object implements the SalesQuantities interface.
 */
export function instanceOfSalesQuantities(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SalesQuantitiesFromJSON(json: any): SalesQuantities {
    return SalesQuantitiesFromJSONTyped(json, false);
}

export function SalesQuantitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesQuantities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abrechnungsmonat': !exists(json, 'abrechnungsmonat') ? undefined : json['abrechnungsmonat'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'produkt': !exists(json, 'produkt') ? undefined : json['produkt'],
        'herstellerID': !exists(json, 'herstellerID') ? undefined : json['herstellerID'],
        'herstellerGruppe': !exists(json, 'herstellerGruppe') ? undefined : json['herstellerGruppe'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'iqvMenge': !exists(json, 'iqvMenge') ? undefined : json['iqvMenge'],
        'arzAnzahlPackungenKorrigiert': !exists(json, 'arzAnzahlPackungenKorrigiert') ? undefined : json['arzAnzahlPackungenKorrigiert'],
        'differenzMenge': !exists(json, 'differenzMenge') ? undefined : json['differenzMenge'],
        'differenzMengeProzent': !exists(json, 'differenzMengeProzent') ? undefined : json['differenzMengeProzent'],
        'iqvHeRaMalPackung': !exists(json, 'iqvHeRaMal Packung') ? undefined : json['iqvHeRaMal Packung'],
        'arzHeRaMalPackungKorrigiert': !exists(json, 'arzHeRaMalPackungKorrigiert') ? undefined : json['arzHeRaMalPackungKorrigiert'],
        'differenzHeRaIqvMonatlichesMittelZuArz': !exists(json, 'differenzHeRaIqvMonatlichesMittelZuArz') ? undefined : json['differenzHeRaIqvMonatlichesMittelZuArz'],
        'differenzHeRaProzent': !exists(json, 'differenzHeRaProzent') ? undefined : json['differenzHeRaProzent'],
        'lauerHeRaMonatshaelfte1von2': !exists(json, 'lauerHeRaMonatshaelfte1von2') ? undefined : json['lauerHeRaMonatshaelfte1von2'],
        'lauerHeRaMonatshaelfte2von2': !exists(json, 'lauerHeRaMonatshaelfte2von2') ? undefined : json['lauerHeRaMonatshaelfte2von2'],
        'lauerHeRaMonatlichesMittel': !exists(json, 'lauerHeRaMonatlichesMittel') ? undefined : json['lauerHeRaMonatlichesMittel'],
        'lauerHeRaPreisanstieg': !exists(json, 'lauerHeRaPreisanstieg') ? undefined : json['lauerHeRaPreisanstieg'],
        'iqvHapMalPackung': !exists(json, 'iqvHapMalPackung') ? undefined : json['iqvHapMalPackung'],
        'arzHapMalPackungKorrigiert': !exists(json, 'arzHapMalPackungKorrigiert') ? undefined : json['arzHapMalPackungKorrigiert'],
        'differenzHapIqvMonatlichesMittelZuArz': !exists(json, 'differenzHapIqvMonatlichesMittelZuArz') ? undefined : json['differenzHapIqvMonatlichesMittelZuArz'],
        'differenzHapProzent': !exists(json, 'differenzHapProzent') ? undefined : json['differenzHapProzent'],
        'lauerHapMonatshaelfte1von2': !exists(json, 'lauerHapMonatshaelfte1von2') ? undefined : json['lauerHapMonatshaelfte1von2'],
        'lauerHapMonatshaelfte2von2': !exists(json, 'lauerHapMonatshaelfte2von2') ? undefined : json['lauerHapMonatshaelfte2von2'],
        'lauerHapMonatlichesMittel': !exists(json, 'lauerHapMonatlichesMittel') ? undefined : json['lauerHapMonatlichesMittel'],
        'lauerHapPreisanstieg': !exists(json, 'lauerHapPreisanstieg') ? undefined : json['lauerHapPreisanstieg'],
        'artikelname': !exists(json, 'artikelname') ? undefined : json['artikelname'],
        'packungsgroesse': !exists(json, 'packungsgroesse') ? undefined : json['packungsgroesse'],
        'arzHeraMittelwertProPackung': !exists(json, 'arzHeraMittelwertProPackung') ? undefined : json['arzHeraMittelwertProPackung'],
    };
}

export function SalesQuantitiesToJSON(value?: SalesQuantities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abrechnungsmonat': value.abrechnungsmonat,
        'pzn': value.pzn,
        'produkt': value.produkt,
        'herstellerID': value.herstellerID,
        'herstellerGruppe': value.herstellerGruppe,
        'markerPkvGkv': value.markerPkvGkv,
        'iqvMenge': value.iqvMenge,
        'arzAnzahlPackungenKorrigiert': value.arzAnzahlPackungenKorrigiert,
        'differenzMenge': value.differenzMenge,
        'differenzMengeProzent': value.differenzMengeProzent,
        'iqvHeRaMal Packung': value.iqvHeRaMalPackung,
        'arzHeRaMalPackungKorrigiert': value.arzHeRaMalPackungKorrigiert,
        'differenzHeRaIqvMonatlichesMittelZuArz': value.differenzHeRaIqvMonatlichesMittelZuArz,
        'differenzHeRaProzent': value.differenzHeRaProzent,
        'lauerHeRaMonatshaelfte1von2': value.lauerHeRaMonatshaelfte1von2,
        'lauerHeRaMonatshaelfte2von2': value.lauerHeRaMonatshaelfte2von2,
        'lauerHeRaMonatlichesMittel': value.lauerHeRaMonatlichesMittel,
        'lauerHeRaPreisanstieg': value.lauerHeRaPreisanstieg,
        'iqvHapMalPackung': value.iqvHapMalPackung,
        'arzHapMalPackungKorrigiert': value.arzHapMalPackungKorrigiert,
        'differenzHapIqvMonatlichesMittelZuArz': value.differenzHapIqvMonatlichesMittelZuArz,
        'differenzHapProzent': value.differenzHapProzent,
        'lauerHapMonatshaelfte1von2': value.lauerHapMonatshaelfte1von2,
        'lauerHapMonatshaelfte2von2': value.lauerHapMonatshaelfte2von2,
        'lauerHapMonatlichesMittel': value.lauerHapMonatlichesMittel,
        'lauerHapPreisanstieg': value.lauerHapPreisanstieg,
        'artikelname': value.artikelname,
        'packungsgroesse': value.packungsgroesse,
        'arzHeraMittelwertProPackung': value.arzHeraMittelwertProPackung,
    };
}

