import { Box, Divider, HStack, Link } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box 
      as="footer" 
      role="contentinfo" 
      mx="auto" 
      maxW="7xl" 
      py="12px" 
      px={{ base: '4', md: '8' }}
      position="fixed"
      bottom="0"
      width="100%"
      background="transparent"
      zIndex={9999}
      color="white"
    >
      <HStack spacing="24px">
        <Link href="https://www.d-fine.com/">© d-fine </Link>
        <Divider orientation="vertical" />
        <Link href="https://www.d-fine.com/service-navigation/impressum/">Impressum</Link>
        <Divider orientation="vertical" />
        <Link href="https://www.d-fine.com/service-navigation/datenschutz/">Datenschutzerklärung</Link>
      </HStack>
    </Box>
  );
};

export default Footer;