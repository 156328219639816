/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchSalesQuantitiesRequest
 */
export interface SearchSalesQuantitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchSalesQuantitiesRequest
     */
    abrechnungsmonatVon?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSalesQuantitiesRequest
     */
    abrechnungsmonatBis?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchSalesQuantitiesRequest
     */
    hersteller?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchSalesQuantitiesRequest
     */
    markerPkvGkv?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSalesQuantitiesRequest
     */
    positiveDifferenzen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSalesQuantitiesRequest
     */
    negativeDifferenzen?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchSalesQuantitiesRequest
     */
    pzn?: Array<string>;
}

/**
 * Check if a given object implements the SearchSalesQuantitiesRequest interface.
 */
export function instanceOfSearchSalesQuantitiesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchSalesQuantitiesRequestFromJSON(json: any): SearchSalesQuantitiesRequest {
    return SearchSalesQuantitiesRequestFromJSONTyped(json, false);
}

export function SearchSalesQuantitiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSalesQuantitiesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abrechnungsmonatVon': !exists(json, 'abrechnungsmonatVon') ? undefined : json['abrechnungsmonatVon'],
        'abrechnungsmonatBis': !exists(json, 'abrechnungsmonatBis') ? undefined : json['abrechnungsmonatBis'],
        'hersteller': !exists(json, 'hersteller') ? undefined : json['hersteller'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'positiveDifferenzen': !exists(json, 'positiveDifferenzen') ? undefined : json['positiveDifferenzen'],
        'negativeDifferenzen': !exists(json, 'negativeDifferenzen') ? undefined : json['negativeDifferenzen'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
    };
}

export function SearchSalesQuantitiesRequestToJSON(value?: SearchSalesQuantitiesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abrechnungsmonatVon': value.abrechnungsmonatVon,
        'abrechnungsmonatBis': value.abrechnungsmonatBis,
        'hersteller': value.hersteller,
        'markerPkvGkv': value.markerPkvGkv,
        'positiveDifferenzen': value.positiveDifferenzen,
        'negativeDifferenzen': value.negativeDifferenzen,
        'pzn': value.pzn,
    };
}

