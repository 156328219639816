import {Box, Heading, HStack, Text} from "@chakra-ui/react";
import {Flex} from "@chakra-ui/layout";
import {colors} from "../../colorStyles";
import React from "react";

const FilterBar = (getActiveFilterNum: Function, totalCount: number | undefined, filterElements: Function) => {
    return (
        <Flex direction="column" h="100%">
            <HStack align={"flex-end"} m={2}>
                <Heading size={"xl"}>Filter</Heading>
                <Text size="xs">
                    {getActiveFilterNum()} Filter aktiv,
                    {totalCount ?? 0}
                    {" Ergebnisse"}
                </Text>
            </HStack>

            <Box
                flex="1"
                overflowY="scroll"
                bg={colors.primary[1000]}
                p="2"
                borderRadius={"md"}
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                }}
            >
                {filterElements()}
            </Box>
        </Flex>
    )
}

export default FilterBar;