import {Button} from "@chakra-ui/button";
import {Box, Flex} from "@chakra-ui/layout";
import {Divider, Heading, HStack, Switch, Text} from "@chakra-ui/react";
import {useRef} from "react";
import {colors} from "../../colorStyles";
import {useFilterStore,} from "../../hooks/ApothekenFilterStore";
import {useActiveTemplateStore} from "../../hooks/useTemplates";
import useApothekenData from "../../hooks/useApothekenData";
import ApothekenFilter from "./ApothekenFilter";
import FilterBox from "./FilterBox";
import FilterTemplatesMenu from "./FilterTemplatesMenu";
import MonthFilter, {MonthFilterProps} from "../generic/MonthFilter";
import FilterSaveButton from "./FilterSaveButton";
import {FilterProps} from "../generic/FilterTemplate";


const FilterBarApotheken = () => {
    const filterStore = useFilterStore();
    const {data} = useApothekenData();
    const setActiveTemplate = useActiveTemplateStore(
        (state) => state.setActiveTemplate
      );
    const filterValues = useFilterStore((state) => state.filterValues);
    const filters: FilterProps[] = [
        {
            name: "Apotheken IK",
            id: "apothekenIk",
            inputType: "numInput",
            values: filterValues.apothekenIk ? filterValues.apothekenIk : [],
            addValue: filterStore.addApothekenIk,
            deleteValue: filterStore.deleteApothekenIk,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Ort",
            id: "ort",
            inputType: "textInput",
            values: filterValues.ort ? filterValues.ort : [],
            addValue: filterStore.addOrt,
            deleteValue: filterStore.deleteOrt,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "PLZ",
            id: "postleitzahl",
            inputType: "textInput",
            values: filterValues.postleitzahl ? filterValues.postleitzahl : [],
            addValue: filterStore.addPlz,
            deleteValue: filterStore.deletePlz,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "PZN",
            id: "pzn",
            inputType: "numInput",
            values: filterValues.pzn ? filterValues.pzn : [],
            addValue: filterStore.addPZN,
            deleteValue: filterStore.deletePZN,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "ATC3 Code",
            id: "atc3code",
            inputType: "textInput",
            values: filterValues.atc3code ? filterValues.atc3code : [],
            addValue: filterStore.addAtc,
            deleteValue: filterStore.deleteAtc,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Wirkstoffgruppe",
            id: "atc3wirkstoffgruppe",
            inputType: "textInput",
            values: filterValues.atc3wirkstoffgruppe
                ? filterValues.atc3wirkstoffgruppe
                : [],
            addValue: filterStore.addWirkstoffgruppe,
            deleteValue: filterStore.deleteWirkstoffgruppe,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Artikelnummer",
            id: "artikelnummer",
            inputType: "numInput",
            values: filterValues.artikelnummer ? filterValues.artikelnummer : [],
            addValue: filterStore.addArtikelNummer,
            deleteValue: filterStore.deleteArtikelNummer,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Apothekenname",
            id: "nameApotheke",
            inputType: "textInput",
            values: filterValues.nameApotheke ? filterValues.nameApotheke : [],
            addValue: filterStore.addApothekenName,
            deleteValue: filterStore.deleteApothekenName,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Hersteller",
            id: "hersteller",
            inputType: "textInput",
            values: filterValues.hersteller
                ? filterValues.hersteller
                : [],
            addValue: filterStore.addRechnungsempfanger,
            deleteValue: filterStore.deleteRechnungsempfanger,
            myRef: useRef<HTMLDivElement>(null),
        },
        {
            name: "Artikelname",
            id: "artikelname",
            inputType: "textInput",
            values: filterValues.artikelname ? filterValues.artikelname : [],
            addValue: filterStore.addArtikelName,
            deleteValue: filterStore.deleteArtikelName,
            myRef: useRef<HTMLDivElement>(null),
        }, // string
        {
            name: "ARZ",
            id: "nameArz",
            inputType: "textInput",
            values: filterValues.nameArz ? filterValues.nameArz : [],
            addValue: filterStore.addArz,
            deleteValue: filterStore.deleteArz,
            myRef: useRef<HTMLDivElement>(null),
        },
    ];

    
    const pkvGkvFilter =
    {
        name: "PKV/GKV",
        id: "markerPkvGkv",
        inputType: "textInput",
        values: filterValues.markerPkvGkv ? filterValues.markerPkvGkv : [],	
        addValue: filterStore.addPkvGkvMarker,
        deleteValue: filterStore.deletePkvGkvMarker,
        myRef: useRef<HTMLDivElement>(null),
    }

    const monthFilters: MonthFilterProps[] = [
        {
            name: "Start ",
            datestring: filterValues.abrechnungsmonatVon,
            setValue: filterStore.setStartMonth,
        },
        {
            name: "Ende ",
            datestring: filterValues.abrechnungsmonatBis,
            setValue: filterStore.setEndMonth,
        },
    ];

    const getActiveFilterNum = () => {
        let count = 0;
        var names = Object.keys(filterValues);
        for (const name of names) {
            // @ts-ignore
            if (filterValues[name].length > 0) {
                count++;
            }
        }
        return count;
    };

    return (
        <Flex direction="column" h="100%">
            <HStack align={"flex-end"} m={2}>
                <Heading size={"xl"}>Filter</Heading>
                <Text size="xs">
                    {getActiveFilterNum()} Filter aktiv,
                    {data?.pages[0].paginationDetails?.totalCount || 0}
                    {" Ergebnisse"}
                </Text>
            </HStack>
            <Box>
                <FilterTemplatesMenu/>
                <Button
                    p="2"
                    m="2"
                    colorScheme="qyte"
                    onClick={() => {
                        filterStore.setFilterValues({});
                        setActiveTemplate("");
                      }}
                >
                    Zurücksetzen
                </Button>
                <FilterSaveButton/>
            </Box>
            <Box
                flex="1"
                overflowY="scroll"
                bg={colors.primary[1000]}
                p="2"
                borderRadius={"md"}
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                }}
            >
                <FilterBox
                    name="Zeitraum"
                    resetFilter={() => {
                        filterStore.setStartMonth("");
                        filterStore.setEndMonth("");
                    }}
                    myRef={useRef<HTMLDivElement>(null)}
                >
                    <HStack>
                        <MonthFilter {...monthFilters[0]} />
                        <MonthFilter {...monthFilters[1]} />
                    </HStack>
                </FilterBox>
                <Divider />
                <FilterBox name="PKV" filterType="switch" >
    <Switch
        isChecked={pkvGkvFilter.values.includes('PKV')}
        onChange={(e) => {
            if (e.target.checked) {
                pkvGkvFilter.addValue('PKV');
            } else {
                pkvGkvFilter.deleteValue('PKV');
            }
        }}
    />
</FilterBox>
<FilterBox name="GKV" filterType="switch">
    <Switch
        isChecked={pkvGkvFilter.values.includes('GKV')}
        onChange={(e) => {
            if (e.target.checked) {
                pkvGkvFilter.addValue('GKV');
            } else {
                pkvGkvFilter.deleteValue('GKV');
            }
        }}
    />
</FilterBox>
                <ApothekenFilter filters={filters}/>
            </Box>
        </Flex>
    );
};

export default FilterBarApotheken;
