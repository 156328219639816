import {Box, Stack} from "@chakra-ui/layout";
import {Divider, Switch} from "@chakra-ui/react";
import FilterBox from "../apothekencenter/FilterBox";
import NumFilter, {TagBox} from "./NumFilter";


export interface FilterProps {
    name: string;
    id: string;
    inputType: string;
    values?: string[];
    value?: boolean;
    setValue?: (value: boolean | string) => void;
    addValue?: (value: string) => void;
    deleteValue?: (value: string) => void;
    myRef: React.MutableRefObject<HTMLDivElement | null>;
}

const FilterTemplate = (filters: FilterProps[], resetFilter: Function) => {
    return (
        <Box>
            {filters.map((filter, index) => (
                <Box key={filter.name}>
                    {!(filter.inputType === "switch" && filters[index - 1]?.inputType === "switch") && <Divider/>}
                    <FilterBox
                        name={filter.name}
                        resetFilter={() => resetFilter(filter.id)}
                        myRef={filter.myRef}
                        filterType={filter.inputType}
                    >
                        {filter.inputType === "switch" ? (
                            <Switch
                                isChecked={filter.value}
                                onChange={(e) => filter.setValue?.(e.target.checked)}
                            />
                        ) : (
                            <NumFilter
                                values={filter.values || []}
                                addValue={filter.addValue || (() => {
                                })}
                                inputType={filter.inputType}
                            />
                        )}

                        <Stack>
                            <TagBox values={filter.values || []} deleteValue={filter.deleteValue || (() => {
                            })}/>
                        </Stack>
                    </FilterBox>

                </Box>
            ))}
        </Box>
    );
};

export default FilterTemplate;