/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  PutRenameGroupRequest,
  SpecialContract,
  SpecialContractKey,
  SpecialContractResponse,
  SpecialContractUpdate,
} from '../models/index';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    PutRenameGroupRequestFromJSON,
    PutRenameGroupRequestToJSON,
    SpecialContractFromJSON,
    SpecialContractToJSON,
    SpecialContractKeyFromJSON,
    SpecialContractKeyToJSON,
    SpecialContractResponseFromJSON,
    SpecialContractResponseToJSON,
    SpecialContractUpdateFromJSON,
    SpecialContractUpdateToJSON,
} from '../models/index';

export interface DeleteGroupRequest {
    groupName: string;
}

export interface DeleteSpecialContractRequest {
    specialContractKey: SpecialContractKey;
}

export interface PostSpecialContractRequest {
    specialContract: SpecialContract;
}

export interface PutRenameGroupOperationRequest {
    putRenameGroupRequest: PutRenameGroupRequest;
}

export interface PutSpecialContractRequest {
    specialContractUpdate: SpecialContractUpdate;
}

/**
 * 
 */
export class SpecialContractApi extends runtime.BaseAPI {

    /**
     * Delete all special contract of a group
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling deleteGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/special_contract/deleteGroup/{groupName}`.replace(`{${"groupName"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete all special contract of a group
     */
    async deleteGroup(groupName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteGroupRaw({ groupName: groupName }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a special contract
     */
    async deleteSpecialContractRaw(requestParameters: DeleteSpecialContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.specialContractKey === null || requestParameters.specialContractKey === undefined) {
            throw new runtime.RequiredError('specialContractKey','Required parameter requestParameters.specialContractKey was null or undefined when calling deleteSpecialContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/special_contract/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialContractKeyToJSON(requestParameters.specialContractKey),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete a special contract
     */
    async deleteSpecialContract(specialContractKey: SpecialContractKey, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteSpecialContractRaw({ specialContractKey: specialContractKey }, initOverrides);
        return await response.value();
    }

    /**
     * Get all groups which ar defined in any special contract
     */
    async getGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/special_contract/groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all groups which ar defined in any special contract
     */
    async getGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all special contracts and additional information
     */
    async getSpecialContractRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpecialContractResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/special_contract/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialContractResponseFromJSON));
    }

    /**
     * Get all special contracts and additional information
     */
    async getSpecialContract(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpecialContractResponse>> {
        const response = await this.getSpecialContractRaw(initOverrides);
        return await response.value();
    }

    /**
     * Saves a new special contract
     */
    async postSpecialContractRaw(requestParameters: PostSpecialContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.specialContract === null || requestParameters.specialContract === undefined) {
            throw new runtime.RequiredError('specialContract','Required parameter requestParameters.specialContract was null or undefined when calling postSpecialContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/special_contract/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialContractToJSON(requestParameters.specialContract),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Saves a new special contract
     */
    async postSpecialContract(specialContract: SpecialContract, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.postSpecialContractRaw({ specialContract: specialContract }, initOverrides);
        return await response.value();
    }

    /**
     * rename all special contract of a group
     */
    async putRenameGroupRaw(requestParameters: PutRenameGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.putRenameGroupRequest === null || requestParameters.putRenameGroupRequest === undefined) {
            throw new runtime.RequiredError('putRenameGroupRequest','Required parameter requestParameters.putRenameGroupRequest was null or undefined when calling putRenameGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/special_contract/renameGroup`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutRenameGroupRequestToJSON(requestParameters.putRenameGroupRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * rename all special contract of a group
     */
    async putRenameGroup(putRenameGroupRequest: PutRenameGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.putRenameGroupRaw({ putRenameGroupRequest: putRenameGroupRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update a special contract
     */
    async putSpecialContractRaw(requestParameters: PutSpecialContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.specialContractUpdate === null || requestParameters.specialContractUpdate === undefined) {
            throw new runtime.RequiredError('specialContractUpdate','Required parameter requestParameters.specialContractUpdate was null or undefined when calling putSpecialContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/special_contract/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialContractUpdateToJSON(requestParameters.specialContractUpdate),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a special contract
     */
    async putSpecialContract(specialContractUpdate: SpecialContractUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.putSpecialContractRaw({ specialContractUpdate: specialContractUpdate }, initOverrides);
        return await response.value();
    }

}
