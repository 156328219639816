import GenericTable from "../../components/generic/Table";
import { FormatedFloat } from "../../components/generic/FloatFormater";
import { Box } from "@chakra-ui/react";

const Card = (colNames: string[], searchNames: string[], sortingStoreFunction: Function, dataDisplayVals: (string | number | FormatedFloat | boolean | undefined)[][] | undefined, getNumFetchedRows: () => number, fetchNextPage: () => void) =>
 {
    const sortingStore = sortingStoreFunction();

    return (
        <Box height="100%" overflow="auto" borderTopRadius="md">
            <GenericTable
                searchNames={searchNames}
                colNames={colNames}
                sortingStore={sortingStore}
                data={dataDisplayVals}
                getNumFetchedRows={getNumFetchedRows}
                fetchNextPage={fetchNextPage}
            />
        </Box>
    );
};

export default Card;
