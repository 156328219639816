import { IconButton } from "@chakra-ui/button";
import { AddIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { useState } from "react";
import FilterTag from "./FilterTag";
import { useCustomToasts } from "../../hooks/useCustomToast";

export interface Props {
  values: string[];
  addValue: (value: string) => void;
  inputType: string;
  placeholder?: string;
}

const validateInput = (
  values: string[],
  choice: string | undefined,
  addValue: (choice: string) => void,
  setChoice: (choice: string) => void,
  onErrorToast: (text: string) => void
) => {
  console.log("Validating...", choice);
  if (choice && !values.includes(choice)) {
    addValue(choice);
    setChoice("");
  } else if (!choice) {
    onErrorToast("Bitte tätigen Sie eine Eingabe.");
  } else if (values.includes(choice)) {
    onErrorToast("Diese Eingabe existiert bereits!");
  }
};

/**
 *
 * @values List of value that were saved by the filter
 * @returns
 */
const NumFilter = ({
  values,
  addValue,
  inputType,
  placeholder = "",
}: Props) => {
  const [filterChoice, setFilterChoice] = useState<string | undefined>();
  const myType = inputType === "textInput" ? "text" : "number";
  const { onErrorToast } = useCustomToasts();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      validateInput(
        values,
        filterChoice?.toString(),
        addValue,
        setFilterChoice,
        onErrorToast
      );
      event.preventDefault(); // Prevent form submission
    }
  };

  return (
    <InputGroup>
      <Input
        placeholder={placeholder}
        type={myType}
        backgroundColor="white"
        value={filterChoice}
        onChange={(event) => setFilterChoice(event.target.value)}
        onKeyDown={handleKeyDown}
      ></Input>
      <InputRightElement>
        <IconButton
          colorScheme="qyte"
          borderWidth={"2px"}
          icon={<AddIcon color="white" />}
          aria-label="add-choice"
          onClick={(event) => {
            validateInput(
              values,
              filterChoice?.toString(),
              addValue,
              setFilterChoice,
              onErrorToast
            );
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export const TagBox = ({
  values,
  deleteValue,
}: {
  values: string[];
  deleteValue: (val: string) => void;
}) => {
  return (
    <>
      {values.map((filter_val) => (
        <FilterTag
          value={filter_val}
          key={filter_val}
          closeTag={() => {
            deleteValue(filter_val);
          }}
        />
      ))}
    </>
  );
};

export default NumFilter;
