import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Button, Flex, Spinner, Text, Image } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import Footer from "../Footer";
import './LandingPage.css';

interface Props {
  children: ReactNode | ReactNode[];
}

/**
 * hides the content if user is not logged in
 * @returns
 */
const AuthenticationWrapper = ({ children }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);

  const loginRequest = {
    scopes: ["User.Read"],
  };

  useEffect(() => {
    if (
      !isAuthenticated && window.location.pathname !== "/" && window.location.pathname !== "/error"
    ) {
      //redirect to login page
      window.location.href = '/';
    }

  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <Spinner />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url(/images/Hintergrund_Mediversum.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          zIndex: -1
        }}></Box>
        <Flex sx={{ 
          position: 'absolute', 
          top: '15%', 
          left: 0, 
          right: 0, 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '3rem', // Increase margin-bottom to add more space between text and logo
            fontFamily: '"Eastman Alt Pack", sans-serif',
            color: 'white',
            textAlign: 'center'
          }}>
            <Text sx={{ fontSize: '6rem' }}>Mediversum</Text>
            <Text sx={{ fontSize: '2rem' }}>powered by</Text> 
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            padding: '10px 20px',
            borderRadius: '10px',
            marginBottom: '3rem' 
          }}>
            <Image src="/images/D-fine_logo.png" alt="d-fine logo" sx={{ margin: '0 2rem', height: '6rem' }} />
            <Text sx={{ fontSize: '6rem', margin: '0 10px', textColor: "rgba(255, 255, 255, 0.6)" }}>&</Text>
            <Image src="/images/LogoQyte_Offiziell.png" alt="qyte logo" sx={{ margin: '0 2rem', height: '6rem' }} />
          </Box>
          <Button
          sx={{fontSize: '2rem'}}
            p={8}
            leftIcon={<AiOutlineLogin />}
            colorScheme="qyte"
            onClick={() => {
              instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
              });
            }}
          >
            Anmelden
          </Button>
        </Flex>
        <Footer />
      </Box>
    )
  }
  return <>{children}</>;
};

export default AuthenticationWrapper;
