import { Select } from "@chakra-ui/react";

/**
 * options: the selectable options with a value for BE communication and a display values
 * value: the state, that controls the menu
 * setValue: setter function for controlling state
 *
 */
interface DropDownProps {
  options: Option[] | undefined;
  value: string;
  defaultValue: string;
  setValue: (newValue: string) => void;
  fontSize?: string | number;
  fontWeight?: string;
}

export interface Option {
  display: string;
  value: string;
}

/**
 * A dropdown menu, which can be used troughout the application consistently.
 */
const DropDownMenu = ({
  options,
  defaultValue,
  setValue,
  value,
  fontSize = "md",
  fontWeight = "normal",
}: DropDownProps) => {
  return (
    <Select
      onChange={(event) => {
        console.log(event.target.value);
        setValue(event.target.value);
      }}
      value={value}
      defaultValue={""}
      backgroundColor={"white"}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {defaultValue !== "none" && <option value={""}>{defaultValue}</option>}
      {options?.map((option) => {
        return <option value={option.value}>{option.display} </option>;
      })}
    </Select>
  );
};

export default DropDownMenu;
