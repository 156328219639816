import FilterTemplate, {FilterProps} from "../generic/FilterTemplate";
import {useProPznFilterStore} from "../../hooks/ProPznFilterStore";


const HeraPznFilter: React.FC<{
    filters: FilterProps[];
}> = ({filters}) => {
    const resetFilter = useProPznFilterStore((state) => state.resetSingleFilter);
    return FilterTemplate(filters, resetFilter)
}

export default HeraPznFilter;
