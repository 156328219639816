export const colors = {
  primary: {
    1000: "#003c4f",
    900: "#1a5061",
    800: "#336372",
    700: "#4d7784",
    600: "#668a95",
    500: "#809ea7",
    400: "#99b1b9",
    300: "#b3c5ca",
    200: "#ccd8dc",
    100: "#e6eced",
  },
  secondary: "#E5A103",
  buttons: {
    1: "#219cd6",
  },
};
