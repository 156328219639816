import { Button, ButtonGroup } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import { Stack } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import React, { useState } from "react";
import FocusLock from "react-focus-lock";
import { colors } from "../../colorStyles";
import {
  useActiveTemplateStore,
  useAddTemplate,
  useUpdateTemplate,
  useFilterTemplates,
} from "../../hooks/useTemplates";

interface inputProps {
  id: string;
  label: string;
  name: string;
  setName: (name: string) => void;
}
const MyInput = React.forwardRef<HTMLInputElement, inputProps>((props, ref) => {
  return (
    <FormControl>
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Input
        value={props.name}
        onChange={(event) => {
          props.setName(event.target.value);
        }}
        ref={ref}
        {...props}
      />
    </FormControl>
  );
});

interface FormProps {
  onCancel: () => void;
  firstFieldRef: React.Ref<HTMLInputElement>;
}
const CustomForm: React.FC<FormProps> = ({ firstFieldRef, onCancel }) => {
  const [newTemplateName, setNewTemplateName] = useState("");
  const activeTemplate = useActiveTemplateStore(
    (state) => state.activeTemplate
  );
  const setActiveTemplate = useActiveTemplateStore(
    (state) => state.setActiveTemplate
  );
  const addTemplate = useAddTemplate();
  const updateTemplate = useUpdateTemplate();
  const {refetch} = useFilterTemplates();

  return (
    <Stack spacing={4}>
      <MyInput
        name={newTemplateName}
        setName={setNewTemplateName}
        label="Name des Filterprofils"
        id="filter-name"
        ref={firstFieldRef}
      />
      <ButtonGroup display="flex" justifyContent="flex-end">
        <Button
          variant="outline"
          onClick={() => {
            updateTemplate(activeTemplate);
            refetch();
            onCancel();
          }}
        >
          Filter updaten als
        </Button>
        <Button
          onClick={() => {
            addTemplate(newTemplateName);
            setActiveTemplate(newTemplateName);
            refetch();
            onCancel();
          }}
          bg={colors.secondary}
          textColor={"white"}
        >
          speichern
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const FilterSaveButton = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  return (
    <>
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onClose}
        placement="top"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button p="2" bg={colors.primary[800]} textColor={"white"}>
            Filter speichern
          </Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <CustomForm firstFieldRef={firstFieldRef} onCancel={onClose} />
          </FocusLock>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default FilterSaveButton;
