/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregationApotheken } from './AggregationApotheken';
import {
    AggregationApothekenFromJSON,
    AggregationApothekenFromJSONTyped,
    AggregationApothekenToJSON,
} from './AggregationApotheken';
import type { PaginationResponse } from './PaginationResponse';
import {
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface AggregationApothekenPaginationResponse
 */
export interface AggregationApothekenPaginationResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AggregationApothekenPaginationResponse
     */
    paginationDetails?: PaginationResponse;
    /**
     * 
     * @type {Array<AggregationApotheken>}
     * @memberof AggregationApothekenPaginationResponse
     */
    data?: Array<AggregationApotheken>;
}

/**
 * Check if a given object implements the AggregationApothekenPaginationResponse interface.
 */
export function instanceOfAggregationApothekenPaginationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AggregationApothekenPaginationResponseFromJSON(json: any): AggregationApothekenPaginationResponse {
    return AggregationApothekenPaginationResponseFromJSONTyped(json, false);
}

export function AggregationApothekenPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationApothekenPaginationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationDetails': !exists(json, 'paginationDetails') ? undefined : PaginationResponseFromJSON(json['paginationDetails']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AggregationApothekenFromJSON)),
    };
}

export function AggregationApothekenPaginationResponseToJSON(value?: AggregationApothekenPaginationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationDetails': PaginationResponseToJSON(value.paginationDetails),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AggregationApothekenToJSON)),
    };
}

