import {HStack} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import CheckCardSingle from "./subpages/CheckCardSingle";
import HeraCheckOverview from "./subpages/HeraCheckOverview";
import PageNavigationBar from "../components/PageNavigationBar";
import CheckCardPzn from "./subpages/CheckCardPzn";
import DropDownMenu from "../components/generic/DropDownMenu";
import ExportButton from "../components/generic/ExportButton";
import {useHeraStore} from "../hooks/heraStore";
import {usePruefungAvailables} from "../hooks/useCheckData";
import {useEinzelpruefungsExport, useHeraProArzExport, useHeraProPznExport,} from "../services/export-utils";
import PageLayout from "./PageLayout";
import {ExportAggregationApothekenFormatEnum} from "../autogen";
import { useEinzelPruefungSortingStore, useProPznSortingStore } from "../hooks/sortingStore";

interface PrufungsNavProps {
  handleExport: (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => Promise<void>;
}

const CheckNav = ({handleExport}: PrufungsNavProps) => {
  const heraStore = useHeraStore();
  const location = useLocation();
  const { arzOptions, monthOptions } = usePruefungAvailables();
  return (
    <HStack
      width={"100%"}
      height={"100%"}
      paddingX={4}
      paddingTop={4}
      justifyContent={"space-between"}
    >
      <PageNavigationBar
        rootPath="/pruefungscenter"
        navItems={[
          { name: "Monatliche Prüfung", subpath: "/monatliche_pruefung" },
          { name: "Einzelabrechnungen", subpath: "/einzelabrechnungen" },
          { name: "Pro PZN", subpath: "/pro_pzn" },
        ]}
      />
      <HStack
        visibility={
          location.pathname.endsWith("/monatliche_pruefung") || location.pathname.endsWith("pruefungscenter")
            ? "hidden"
            : "visible"
        }
      >
        <DropDownMenu
            defaultValue="Alle Monate"
          setValue={heraStore.setMonth}
          value={heraStore.month}
          options={monthOptions}
        />
        <DropDownMenu
            defaultValue="Alle ARZs"
          value={heraStore.arz}
          setValue={heraStore.setArz}
          options={arzOptions}
        />
      </HStack>
      <ExportButton handleExport={handleExport} />
    </HStack>
  );
};

export const Einzelabrechnungen = () => {
  const { handleExport } = useEinzelpruefungsExport();
  const {column,descending} = useEinzelPruefungSortingStore();
  return (
    <PageLayout
        mainContent={<CheckCardSingle key={`${column}-${descending}`}/>}
        navItems={<CheckNav handleExport={handleExport}/>}
    />
  );
};

export const MonthCheck = () => {
  const { handleExport } = useHeraProArzExport();
  return (
    <PageLayout
        mainContent={<HeraCheckOverview/>}
        navItems={<CheckNav handleExport={handleExport}/>}
    />
  );
};

export const PznCheck = () => {
  const { handleExport } = useHeraProPznExport();
  const {column,descending} = useProPznSortingStore();
  return (
    <PageLayout
        mainContent={<CheckCardPzn key={`${column}-${descending}`}/>}
        navItems={<CheckNav handleExport={handleExport}/>}
    />
  );
};
