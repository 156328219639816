import { useToast } from "@chakra-ui/react";

export const useCustomToasts = () => {
  const toast = useToast();

  const onSuccessToast = (message = "") => {
    toast({
      title: "Success!",
      description: message,
      status: "success",
      duration: 4000,
    });
  };

  const onErrorToast = (message = "") => {
    toast({
      title: "Oops!",
      description: message,
      status: "error",
      duration: 4000,
    });
  };
  return { onSuccessToast, onErrorToast };
};
