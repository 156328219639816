import React, {useEffect, useRef, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {Box, Flex, IconButton, Text} from "@chakra-ui/react";
import {colors} from "../../colorStyles";
import {FormatedFloat} from "./FloatFormater";

interface GenericTableProps {
    colNames: string[];
    searchNames: string[];
    sortingStore: any; // Replace 'any' with the actual type
    data: any; // Replace 'any' with the actual type
    getNumFetchedRows: () => number;
    fetchNextPage: () => void;
}

const GenericTable: React.FC<GenericTableProps> = ({
    colNames,
    searchNames,
    sortingStore,
    data,
    getNumFetchedRows,
    fetchNextPage,
}) => {
    const headerRefs = useRef<(HTMLElement | null)[]>([]);
    const [columnWidths, setColumnWidths] = useState<number[]>([]);

    useEffect(() => {

        const headerWidths = colNames.map((_, i) => (headerRefs.current[i] as HTMLElement)?.offsetWidth || 0);

        setColumnWidths(headerWidths);
    }, [colNames]);

    return (
        <Box height="100%" overflow="auto" id="scrollableTableContainer" position="relative">
            <Box id="scrollableTable" position="relative">
                <Flex
                    direction="column"
                    minWidth="100%"
                    width="fit-content"
                >
                    <Flex
                        bg={colors.primary[1000]}
                        position="sticky"
                        verticalAlign={"bottom"}
                        top="0"
                        zIndex="1"
                    >
                        {colNames.map((colName, idx) => (
                            <Box
                                key={colName}
                                ref={el => headerRefs.current[idx] = el}
                                padding={2}
                                border={"1px solid"}
                                borderColor={"gray.200"}
                                minWidth={"11em"}
                                maxWidth={"17m"}
                                fontSize={"md"}
                                textAlign="center"
                                alignContent={"end"}
                                color={"white"}
                                textOverflow={"ellipsis"}
                                whiteSpace={"normal"}
                            >
                                <Text fontSize="md">
                                    {colName}
                                </Text>
                                <IconButton
                                    size={"xs"}
                                    inlineSize={100}
                                    aria-label={"sorting"}
                                    opacity={sortingStore.column === searchNames[idx] ? "1" : "0.2"}
                                    icon={sortingStore.descending ? <ChevronDownIcon /> : <ChevronUpIcon />}
                                    onClick={() => {
                                        sortingStore.setColumn(searchNames[idx]);
                                        sortingStore.toggleDescending();
                                    }}
                                />
                            </Box>
                        ))}
                    </Flex>
                    <InfiniteScroll
                        dataLength={getNumFetchedRows()}
                        next={fetchNextPage}
                        hasMore={true}
                        loader={false}
                        scrollableTarget="scrollableTableContainer"
                        style={{ overflow: 'unset' }}
                    >
                        {data?.map((row: any, rowIndex: number) => (
                            <Flex key={rowIndex} bg={rowIndex % 2 === 0 ? "gray.100" : "white"}>
                                {row.map((value: any, colIndex: number) => (
                                    <Box
                                        key={colIndex}
                                        width={columnWidths[colIndex]}
                                        padding={2}
                                        border="1px solid"
                                        borderColor="gray.200"
                                        textAlign={typeof value === "number" || value instanceof FormatedFloat ? "right" : "left"}
                                        alignContent={"end"}
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"normal"}
                                    >
                                        {value instanceof FormatedFloat ? value.value : value}
                                    </Box>
                                ))}
                            </Flex>
                        ))}
                    </InfiniteScroll>
                </Flex>
            </Box>
        </Box>
    );
};

export default GenericTable;
