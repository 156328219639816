/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AggregationApotheken
 */
export interface AggregationApotheken {
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    abrechnungsmonat?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    apothekenIk?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    nameApotheke?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    nameArz?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    markerPkvGkv?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    strasse?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    postleitzahl?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    pzn?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    artikelnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    artikelname?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    hersteller?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    atc3code?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    atc3wirkstoffgruppe?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApotheken
     */
    markerBiosimilar?: string;
    /**
     * 
     * @type {number}
     * @memberof AggregationApotheken
     */
    sumAnzahlPackungen?: number;
    /**
     * 
     * @type {number}
     * @memberof AggregationApotheken
     */
    sumPackungMalHap?: number;
    /**
     * 
     * @type {number}
     * @memberof AggregationApotheken
     */
    sumAbschlagsbetragGesamtEuro?: number;
}

/**
 * Check if a given object implements the AggregationApotheken interface.
 */
export function instanceOfAggregationApotheken(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AggregationApothekenFromJSON(json: any): AggregationApotheken {
    return AggregationApothekenFromJSONTyped(json, false);
}

export function AggregationApothekenFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationApotheken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abrechnungsmonat': !exists(json, 'abrechnungsmonat') ? undefined : json['abrechnungsmonat'],
        'apothekenIk': !exists(json, 'apothekenIk') ? undefined : json['apothekenIk'],
        'nameApotheke': !exists(json, 'nameApotheke') ? undefined : json['nameApotheke'],
        'nameArz': !exists(json, 'nameArz') ? undefined : json['nameArz'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'strasse': !exists(json, 'strasse') ? undefined : json['strasse'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'artikelnummer': !exists(json, 'artikelnummer') ? undefined : json['artikelnummer'],
        'artikelname': !exists(json, 'artikelname') ? undefined : json['artikelname'],
        'hersteller': !exists(json, 'hersteller') ? undefined : json['hersteller'],
        'atc3code': !exists(json, 'atc3code') ? undefined : json['atc3code'],
        'atc3wirkstoffgruppe': !exists(json, 'atc3wirkstoffgruppe') ? undefined : json['atc3wirkstoffgruppe'],
        'markerBiosimilar': !exists(json, 'markerBiosimilar') ? undefined : json['markerBiosimilar'],
        'sumAnzahlPackungen': !exists(json, 'sumAnzahlPackungen') ? undefined : json['sumAnzahlPackungen'],
        'sumPackungMalHap': !exists(json, 'sumPackungMalHap') ? undefined : json['sumPackungMalHap'],
        'sumAbschlagsbetragGesamtEuro': !exists(json, 'sumAbschlagsbetragGesamtEuro') ? undefined : json['sumAbschlagsbetragGesamtEuro'],
    };
}

export function AggregationApothekenToJSON(value?: AggregationApotheken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abrechnungsmonat': value.abrechnungsmonat,
        'apothekenIk': value.apothekenIk,
        'nameApotheke': value.nameApotheke,
        'nameArz': value.nameArz,
        'markerPkvGkv': value.markerPkvGkv,
        'strasse': value.strasse,
        'ort': value.ort,
        'postleitzahl': value.postleitzahl,
        'pzn': value.pzn,
        'artikelnummer': value.artikelnummer,
        'artikelname': value.artikelname,
        'hersteller': value.hersteller,
        'atc3code': value.atc3code,
        'atc3wirkstoffgruppe': value.atc3wirkstoffgruppe,
        'markerBiosimilar': value.markerBiosimilar,
        'sumAnzahlPackungen': value.sumAnzahlPackungen,
        'sumPackungMalHap': value.sumPackungMalHap,
        'sumAbschlagsbetragGesamtEuro': value.sumAbschlagsbetragGesamtEuro,
    };
}

