import Navbar from "../components/Navbar";
import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();

  return (
    <Grid
      templateAreas={`"nav"
                      "main"`}
      gridTemplateColumns={"100vw"}
      gridTemplateRows={"10vh 90vh"}
    >
      <GridItem area="nav">
        <Navbar />
      </GridItem>
      <GridItem area="main">
        <Box height={"100%"}>
          <Heading> Oops! </Heading>
          <Text>
            {isRouteErrorResponse(error)
              ? "Diese Seite existiert (noch) nicht"
              : "Etwas ist schiefgegangen"}{" "}
          </Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ErrorPage;
