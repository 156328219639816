import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

export default function Layout() {
  return (
    <Grid
      templateAreas={`"nav"
                      "main"`}
      gridTemplateColumns={"100vw"}
      gridTemplateRows={"7vh 93vh"}
    >
      <GridItem area="nav">
        <Navbar />
      </GridItem>
      <GridItem area="main">
        <Box height={"100%"}>
            <Outlet />
        </Box>
      </GridItem>
    </Grid>
  );
}
