/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeraCheckArzData
 */
export interface HeraCheckArzData {
    /**
     * 
     * @type {string}
     * @memberof HeraCheckArzData
     */
    nameArz: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckArzData
     */
    abrechnungsmonat: string;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckArzData
     */
    summeDifferenzHeraNachMengeAbgaben: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckArzData
     */
    summeDifferenzHapNachMengeAbgaben: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckArzData
     */
    betroffeneApotheken: number;
}

/**
 * Check if a given object implements the HeraCheckArzData interface.
 */
export function instanceOfHeraCheckArzData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nameArz" in value;
    isInstance = isInstance && "abrechnungsmonat" in value;
    isInstance = isInstance && "summeDifferenzHeraNachMengeAbgaben" in value;
    isInstance = isInstance && "summeDifferenzHapNachMengeAbgaben" in value;
    isInstance = isInstance && "betroffeneApotheken" in value;

    return isInstance;
}

export function HeraCheckArzDataFromJSON(json: any): HeraCheckArzData {
    return HeraCheckArzDataFromJSONTyped(json, false);
}

export function HeraCheckArzDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraCheckArzData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameArz': json['nameArz'],
        'abrechnungsmonat': json['abrechnungsmonat'],
        'summeDifferenzHeraNachMengeAbgaben': json['summeDifferenzHeraNachMengeAbgaben'],
        'summeDifferenzHapNachMengeAbgaben': json['summeDifferenzHapNachMengeAbgaben'],
        'betroffeneApotheken': json['betroffeneApotheken'],
    };
}

export function HeraCheckArzDataToJSON(value?: HeraCheckArzData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameArz': value.nameArz,
        'abrechnungsmonat': value.abrechnungsmonat,
        'summeDifferenzHeraNachMengeAbgaben': value.summeDifferenzHeraNachMengeAbgaben,
        'summeDifferenzHapNachMengeAbgaben': value.summeDifferenzHapNachMengeAbgaben,
        'betroffeneApotheken': value.betroffeneApotheken,
    };
}

