/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AggregationApothekenSearchTemplateRequest
 */
export interface AggregationApothekenSearchTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof AggregationApothekenSearchTemplateRequest
     */
    templateName: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApothekenSearchTemplateRequest
     */
    templateDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationApothekenSearchTemplateRequest
     */
    aggregationApothekenSearchJson?: string;
}

/**
 * Check if a given object implements the AggregationApothekenSearchTemplateRequest interface.
 */
export function instanceOfAggregationApothekenSearchTemplateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateName" in value;

    return isInstance;
}

export function AggregationApothekenSearchTemplateRequestFromJSON(json: any): AggregationApothekenSearchTemplateRequest {
    return AggregationApothekenSearchTemplateRequestFromJSONTyped(json, false);
}

export function AggregationApothekenSearchTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationApothekenSearchTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateName': json['templateName'],
        'templateDescription': !exists(json, 'templateDescription') ? undefined : json['templateDescription'],
        'aggregationApothekenSearchJson': !exists(json, 'aggregationApothekenSearchJson') ? undefined : json['aggregationApothekenSearchJson'],
    };
}

export function AggregationApothekenSearchTemplateRequestToJSON(value?: AggregationApothekenSearchTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateName': value.templateName,
        'templateDescription': value.templateDescription,
        'aggregationApothekenSearchJson': value.aggregationApothekenSearchJson,
    };
}

