import FilterTemplate, {FilterProps} from "../generic/FilterTemplate";
import {useEinzelabrechnungenFilterStore} from "../../hooks/EinzelabrechnungenFilterStore";


const HeraSingleFilter: React.FC<{
    filters: FilterProps[];
}> = ({filters}) => {
    const resetFilter = useEinzelabrechnungenFilterStore((state) => state.resetSingleFilter);
    return FilterTemplate(filters, resetFilter);
}

export default HeraSingleFilter;
