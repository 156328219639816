import {Box, Grid, GridItem} from "@chakra-ui/react";
import React from "react";

interface PageLayoutProps {
    navItems: React.ReactNode;
    mainContent: React.ReactNode;
}

const PageLayout = ({ navItems, mainContent }: PageLayoutProps) => {
  return (
    <Grid
      templateAreas={`" header"
                      " main"`}
      gridTemplateColumns={"100vw"}
      gridTemplateRows={"5vh 85vh"}
    >
      <GridItem area={"header"}>
        <Box width={"100%"} height={"100%"} padding={4}>
          {navItems}
        </Box>
      </GridItem>
      <GridItem area={"main"}>
        <Box width="100%" height="100%" padding={4}>
          {mainContent}
        </Box>
      </GridItem>
    </Grid>
  );
};


export default PageLayout;
