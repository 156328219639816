/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBody,
  HeraARZPaginatedResponse,
  HeraCheckArzResponse,
  HeraCheckAvailables,
  HeraCheckRequest,
  HeraPZNPaginatedResponse,
  HeraPaginatedResponse,
  HeraPaginationRequest,
} from '../models/index';
import {
    ErrorBodyFromJSON,
    ErrorBodyToJSON,
    HeraARZPaginatedResponseFromJSON,
    HeraARZPaginatedResponseToJSON,
    HeraCheckArzResponseFromJSON,
    HeraCheckArzResponseToJSON,
    HeraCheckAvailablesFromJSON,
    HeraCheckAvailablesToJSON,
    HeraCheckRequestFromJSON,
    HeraCheckRequestToJSON,
    HeraPZNPaginatedResponseFromJSON,
    HeraPZNPaginatedResponseToJSON,
    HeraPaginatedResponseFromJSON,
    HeraPaginatedResponseToJSON,
    HeraPaginationRequestFromJSON,
    HeraPaginationRequestToJSON,
} from '../models/index';

export interface ExportHeraEinzelAbrechnungenRequest {
    heraCheckRequest: HeraCheckRequest;
    format?: ExportHeraEinzelAbrechnungenFormatEnum;
    csvSeparator?: string;
}

export interface ExportHeraProArzRequest {
    heraCheckRequest: HeraCheckRequest;
    format?: ExportHeraProArzFormatEnum;
    csvSeparator?: string;
}

export interface ExportHeraProPznRequest {
    heraCheckRequest: HeraCheckRequest;
    format?: ExportHeraProPznFormatEnum;
    csvSeparator?: string;
}

export interface GetHeraCheckArzRequest {
    heraCheckRequest?: HeraCheckRequest;
}

export interface GetHeraCheckArzPaginatedRequest {
    heraPaginationRequest: HeraPaginationRequest;
}

export interface GetHeraCheckPaginatedRequest {
    heraPaginationRequest: HeraPaginationRequest;
}

export interface GetHeraCheckPznPaginatedRequest {
    heraPaginationRequest: HeraPaginationRequest;
}

/**
 * 
 */
export class HeraCheckApi extends runtime.BaseAPI {

    /**
     * Export Hera Einzelabrechnungs-Reports in a specified format
     */
    async exportHeraEinzelAbrechnungenRaw(requestParameters: ExportHeraEinzelAbrechnungenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.heraCheckRequest === null || requestParameters.heraCheckRequest === undefined) {
            throw new runtime.RequiredError('heraCheckRequest','Required parameter requestParameters.heraCheckRequest was null or undefined when calling exportHeraEinzelAbrechnungen.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.csvSeparator !== undefined) {
            queryParameters['csvSeparator'] = requestParameters.csvSeparator;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraCheckRequestToJSON(requestParameters.heraCheckRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export Hera Einzelabrechnungs-Reports in a specified format
     */
    async exportHeraEinzelAbrechnungen(heraCheckRequest: HeraCheckRequest, format?: ExportHeraEinzelAbrechnungenFormatEnum, csvSeparator?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportHeraEinzelAbrechnungenRaw({ heraCheckRequest: heraCheckRequest, format: format, csvSeparator: csvSeparator }, initOverrides);
        return await response.value();
    }

    /**
     * Export Hera Arz-Reports in a specified format
     */
    async exportHeraProArzRaw(requestParameters: ExportHeraProArzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.heraCheckRequest === null || requestParameters.heraCheckRequest === undefined) {
            throw new runtime.RequiredError('heraCheckRequest','Required parameter requestParameters.heraCheckRequest was null or undefined when calling exportHeraProArz.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.csvSeparator !== undefined) {
            queryParameters['csvSeparator'] = requestParameters.csvSeparator;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check_pro_arz/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraCheckRequestToJSON(requestParameters.heraCheckRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export Hera Arz-Reports in a specified format
     */
    async exportHeraProArz(heraCheckRequest: HeraCheckRequest, format?: ExportHeraProArzFormatEnum, csvSeparator?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportHeraProArzRaw({ heraCheckRequest: heraCheckRequest, format: format, csvSeparator: csvSeparator }, initOverrides);
        return await response.value();
    }

    /**
     * Export Hera pro PZN reports in a specified format
     */
    async exportHeraProPznRaw(requestParameters: ExportHeraProPznRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.heraCheckRequest === null || requestParameters.heraCheckRequest === undefined) {
            throw new runtime.RequiredError('heraCheckRequest','Required parameter requestParameters.heraCheckRequest was null or undefined when calling exportHeraProPzn.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.csvSeparator !== undefined) {
            queryParameters['csvSeparator'] = requestParameters.csvSeparator;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check_pro_pzn/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraCheckRequestToJSON(requestParameters.heraCheckRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export Hera pro PZN reports in a specified format
     */
    async exportHeraProPzn(heraCheckRequest: HeraCheckRequest, format?: ExportHeraProPznFormatEnum, csvSeparator?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportHeraProPznRaw({ heraCheckRequest: heraCheckRequest, format: format, csvSeparator: csvSeparator }, initOverrides);
        return await response.value();
    }

    /**
     * Get available Fehlerarten in hera data
     */
    async getErrorCauseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hera_check/ErrorCause`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available Fehlerarten in hera data
     */
    async getErrorCause(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getErrorCauseRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the hera data aggregated by month and Arz
     */
    async getHeraCheckArzRaw(requestParameters: GetHeraCheckArzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeraCheckArzResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check_pro_arz`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraCheckRequestToJSON(requestParameters.heraCheckRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeraCheckArzResponseFromJSON(jsonValue));
    }

    /**
     * Get the hera data aggregated by month and Arz
     */
    async getHeraCheckArz(heraCheckRequest?: HeraCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeraCheckArzResponse> {
        const response = await this.getHeraCheckArzRaw({ heraCheckRequest: heraCheckRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get the hera data aggregated by month and Arz
     */
    async getHeraCheckArzPaginatedRaw(requestParameters: GetHeraCheckArzPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeraARZPaginatedResponse>> {
        if (requestParameters.heraPaginationRequest === null || requestParameters.heraPaginationRequest === undefined) {
            throw new runtime.RequiredError('heraPaginationRequest','Required parameter requestParameters.heraPaginationRequest was null or undefined when calling getHeraCheckArzPaginated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check/pro_arz`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraPaginationRequestToJSON(requestParameters.heraPaginationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeraARZPaginatedResponseFromJSON(jsonValue));
    }

    /**
     * Get the hera data aggregated by month and Arz
     */
    async getHeraCheckArzPaginated(heraPaginationRequest: HeraPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeraARZPaginatedResponse> {
        const response = await this.getHeraCheckArzPaginatedRaw({ heraPaginationRequest: heraPaginationRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get all available months and arzs for requests about the hera data
     */
    async getHeraCheckAvailablesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeraCheckAvailables>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hera_check/availables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeraCheckAvailablesFromJSON(jsonValue));
    }

    /**
     * Get all available months and arzs for requests about the hera data
     */
    async getHeraCheckAvailables(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeraCheckAvailables> {
        const response = await this.getHeraCheckAvailablesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the hera data
     */
    async getHeraCheckPaginatedRaw(requestParameters: GetHeraCheckPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeraPaginatedResponse>> {
        if (requestParameters.heraPaginationRequest === null || requestParameters.heraPaginationRequest === undefined) {
            throw new runtime.RequiredError('heraPaginationRequest','Required parameter requestParameters.heraPaginationRequest was null or undefined when calling getHeraCheckPaginated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraPaginationRequestToJSON(requestParameters.heraPaginationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeraPaginatedResponseFromJSON(jsonValue));
    }

    /**
     * Get the hera data
     */
    async getHeraCheckPaginated(heraPaginationRequest: HeraPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeraPaginatedResponse> {
        const response = await this.getHeraCheckPaginatedRaw({ heraPaginationRequest: heraPaginationRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get the hera data aggregated by pzn
     */
    async getHeraCheckPznPaginatedRaw(requestParameters: GetHeraCheckPznPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeraPZNPaginatedResponse>> {
        if (requestParameters.heraPaginationRequest === null || requestParameters.heraPaginationRequest === undefined) {
            throw new runtime.RequiredError('heraPaginationRequest','Required parameter requestParameters.heraPaginationRequest was null or undefined when calling getHeraCheckPznPaginated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/hera_check/pro_pzn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeraPaginationRequestToJSON(requestParameters.heraPaginationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeraPZNPaginatedResponseFromJSON(jsonValue));
    }

    /**
     * Get the hera data aggregated by pzn
     */
    async getHeraCheckPznPaginated(heraPaginationRequest: HeraPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeraPZNPaginatedResponse> {
        const response = await this.getHeraCheckPznPaginatedRaw({ heraPaginationRequest: heraPaginationRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExportHeraEinzelAbrechnungenFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type ExportHeraEinzelAbrechnungenFormatEnum = typeof ExportHeraEinzelAbrechnungenFormatEnum[keyof typeof ExportHeraEinzelAbrechnungenFormatEnum];
/**
 * @export
 */
export const ExportHeraProArzFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type ExportHeraProArzFormatEnum = typeof ExportHeraProArzFormatEnum[keyof typeof ExportHeraProArzFormatEnum];
/**
 * @export
 */
export const ExportHeraProPznFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type ExportHeraProPznFormatEnum = typeof ExportHeraProPznFormatEnum[keyof typeof ExportHeraProPznFormatEnum];
