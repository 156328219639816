import {create} from "zustand";
import {HeraCheckRequest, SearchAggregationApothekenRequest} from "../autogen";

interface EinzelabrechnungenFilterStore {
  filterValues: HeraCheckRequest;
  resetSingleFilter: (name: string) => void;
  setFilterValues: (values: HeraCheckRequest) => void;
  addApothekenIk: (Ik: string) => void;
  deleteApothekenIk: (Ik: string) => void;
  addPZN: (pzn: string) => void;
  deletePZN: (pzn: string) => void;
  addPkvGkvMarker: (marker: string ) => void;
  deletePkvGkvMarker: (marker: string ) => void;
  setDifferenzHeraMin: (differenzHeraMin: number) => void;
  setDifferenzHeraMax: (differenzHeraMax: number) => void;
  setDifferenzHapMin: (differenzHapMin: number) => void;
  setDifferenzHapMax: (differenzHapMax: number) => void;
  setErrorCause: (errorCause: string) => void;	
}

// Define the type for keys that can be arrays
type ArrayKeys = keyof Pick<HeraCheckRequest, 
  'markerPkvGkv' | 'apothekenIk' | 'pzn' >;

const updateArrayInFilterValues = (
  set: any,
  key: ArrayKeys,
  value: string,
  action: 'add' | 'delete'
) => {
  set((state: EinzelabrechnungenFilterStore) => {
    const currentArray = (state.filterValues[key] as string[]) || [];
    let updatedArray;
    if (action === 'add') {
      updatedArray = [...currentArray, value];
    } else if (action === 'delete') {
      updatedArray = currentArray.filter((item: string) => item !== value);
    }
    return {
      filterValues: {
        ...state.filterValues,
        [key]: updatedArray,
      },
    };
  });
};

export const useEinzelabrechnungenFilterStore = create<EinzelabrechnungenFilterStore>((set, get) => ({
  filterValues: {
    markerPkvGkv: ["PKV", "GKV"],
    apothekenIk: [],
    pzn: [],
    differenzHeraMin: -9999999,
    differenzHeraMax: 9999999,
    differenzHapMin: -9999999,
    differenzHapMax: 9999999,
    errorCause: '',
  },
  setFilterValues: (values: SearchAggregationApothekenRequest) => {
    set((state) => ({
      filterValues: values,
    }));
  },
  resetSingleFilter: (name: string) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        [name]: [],
      },
    }));
  },
  addPkvGkvMarker: (newMarker: string) => {
    updateArrayInFilterValues(set, 'markerPkvGkv', newMarker, 'add');
  },
  deletePkvGkvMarker: (marker: string) => {
    updateArrayInFilterValues(set, 'markerPkvGkv', marker, 'delete');
  },
  addApothekenIk: (newIk: string) => {
    updateArrayInFilterValues(set, 'apothekenIk', newIk, 'add');
  },
  deleteApothekenIk: (Ik: string) => {
    updateArrayInFilterValues(set, 'apothekenIk', Ik, 'delete');
  },
  addPZN: (pzn: string) => {
    updateArrayInFilterValues(set, 'pzn', pzn, 'add');
  },
  deletePZN: (pzn: string) => {
    updateArrayInFilterValues(set, 'pzn', pzn, 'delete');
  },
  setDifferenzHapMin: (differenzHapMin: number) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        differenzHapMin,
      },
    }));
  },
  setDifferenzHapMax: (differenzHapMax: number) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        differenzHapMax,
      },
    }));
  },
  setDifferenzHeraMin: (differenzHeraMin: number) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        differenzHeraMin,
      },
    }));
  },
  setDifferenzHeraMax: (differenzHeraMax: number) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        differenzHeraMax,
      },
    }));
  },
  setErrorCause: (errorCause: string) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        errorCause,
      },
    }));
  },
}));
