/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchAggregationApothekenRequest
 */
export interface SearchAggregationApothekenRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    apothekenIk?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    markerPkvGkv?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    ort?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    postleitzahl?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    pzn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    atc3code?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    atc3wirkstoffgruppe?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    artikelnummer?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchAggregationApothekenRequest
     */
    abrechnungsmonatVon?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAggregationApothekenRequest
     */
    abrechnungsmonatBis?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    nameApotheke?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    nameArz?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    hersteller?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAggregationApothekenRequest
     */
    artikelname?: Array<string>;
}

/**
 * Check if a given object implements the SearchAggregationApothekenRequest interface.
 */
export function instanceOfSearchAggregationApothekenRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchAggregationApothekenRequestFromJSON(json: any): SearchAggregationApothekenRequest {
    return SearchAggregationApothekenRequestFromJSONTyped(json, false);
}

export function SearchAggregationApothekenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAggregationApothekenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apothekenIk': !exists(json, 'apothekenIk') ? undefined : json['apothekenIk'],
        'markerPkvGkv': !exists(json, 'markerPkvGkv') ? undefined : json['markerPkvGkv'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'atc3code': !exists(json, 'atc3code') ? undefined : json['atc3code'],
        'atc3wirkstoffgruppe': !exists(json, 'atc3wirkstoffgruppe') ? undefined : json['atc3wirkstoffgruppe'],
        'artikelnummer': !exists(json, 'artikelnummer') ? undefined : json['artikelnummer'],
        'abrechnungsmonatVon': !exists(json, 'abrechnungsmonatVon') ? undefined : json['abrechnungsmonatVon'],
        'abrechnungsmonatBis': !exists(json, 'abrechnungsmonatBis') ? undefined : json['abrechnungsmonatBis'],
        'nameApotheke': !exists(json, 'nameApotheke') ? undefined : json['nameApotheke'],
        'nameArz': !exists(json, 'nameArz') ? undefined : json['nameArz'],
        'hersteller': !exists(json, 'hersteller') ? undefined : json['hersteller'],
        'artikelname': !exists(json, 'artikelname') ? undefined : json['artikelname'],
    };
}

export function SearchAggregationApothekenRequestToJSON(value?: SearchAggregationApothekenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apothekenIk': value.apothekenIk,
        'markerPkvGkv': value.markerPkvGkv,
        'ort': value.ort,
        'postleitzahl': value.postleitzahl,
        'pzn': value.pzn,
        'atc3code': value.atc3code,
        'atc3wirkstoffgruppe': value.atc3wirkstoffgruppe,
        'artikelnummer': value.artikelnummer,
        'abrechnungsmonatVon': value.abrechnungsmonatVon,
        'abrechnungsmonatBis': value.abrechnungsmonatBis,
        'nameApotheke': value.nameApotheke,
        'nameArz': value.nameArz,
        'hersteller': value.hersteller,
        'artikelname': value.artikelname,
    };
}

