import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import AuthenticationWrapper from "./components/generic/AuthenticationWrapper";

function App() {
  {
    /* <AuthStatusBox/> */
  }
  {
    /* <FetchBox/> */
  }
  return (
    
    <AuthenticationWrapper>
      <RouterProvider router={router} />
    </AuthenticationWrapper>
  )
  
}

export default App;
