import {createBrowserRouter, Navigate} from "react-router-dom";
import ApothekenAbrechnungen from "./pages/ApothekenAbrechnungen";
import ErrorPage from "./pages/ErrorPage";
import Layout from "./pages/Layout";
import ApothekenGruppen from "./pages/ApothekenGruppen";
import {Einzelabrechnungen, MonthCheck, PznCheck,} from "./pages/CheckPages";
import MarktcenterWrapper from "./pages/MarktpruefungWrapper";


export const router = createBrowserRouter(
  [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Navigate to="/apothekencenter" replace/> },
      {
        path: "/apothekencenter",
        children: [

          {
            path: "gruppenuebersicht",
            element: <ApothekenGruppen />,
          },
          {
            path: "abrechnungen",
            element: <ApothekenAbrechnungen />,
          },
          {
            index: true, 
            element: <Navigate to="abrechnungen" replace />},
        ],
      },
      {
        path: "/pruefungscenter",
        children: [
          {
            path: "",
            element: <MonthCheck/>,
          },

          {
            path: "monatliche_pruefung",
            element: <MonthCheck/>,
          },
          {
            path: "einzelabrechnungen",
            element: <Einzelabrechnungen />,
          },
          {
            path: "pro_pzn",
            element: <PznCheck/>,
          },
        ],
      },
      {
        path: "/marktcenter",
        children: [
          {
            path: "",
            element: <MarktcenterWrapper />,
          },
          {
            path: "detailansicht",
            element: <MarktcenterWrapper />,
          }
        ]
      },
    ],
  },
]);
