import {HeraCheckHAP} from "../../autogen";
import {useCheckData} from "../../hooks/useCheckData";
import Card from "../subpages/Card"
import {useEinzelPruefungSortingStore} from "../../hooks/sortingStore";
import formatFloat, {FormatedFloat} from "../../components/generic/FloatFormater";
import {Box, Grid, GridItem} from "@chakra-ui/react";
import FilterBarEinzelabrechnungen from "../../components/rechnungsprüfung/FilterBarEinzelabrechnungen";


const searchNames = [
    "nameArz",
    "abrechnungsmonat",
    "heraHapCheck",
    "abrechnungsart",
    "markerPkvGkv",
    "apothekenIk",
    "nameApotheke",
    "ortApotheke",
    "hersteller",
    "pzn",
    "artikelname",
    "anzahlPackungen",
    "diffHeraProPackung",
    "diffHeraNachMengeAbgaben",
    "heraProPackungLauer",
    "heraProPackungArz",
    "heraLauerMalPackungen",
    "heraArzMalPackungen",
    "diffHapProPackungen",
    "diffHapNachMengeAbgaben",
    "hapProPackungLauer",
    "hapProPackungARZ",
    "hapLauerMalPackungen",
    "hapArzMalPackungen",
    "customer"
];


export interface CheckSingleTableMap {
    [key: string]: (dataRow: HeraCheckHAP | undefined) => number | string | FormatedFloat | undefined;
}


const tableMap: CheckSingleTableMap = {
    ARZ: (row) => row?.nameArz,
    Abrechnungsmonat: (row) => row?.abrechnungsmonat,
    "HERA Check": (row) => row?.heraHapCheck,
    Abrechnungsart: (row) => row?.abrechnungsart,
    "PKV/GKV": (row) => row?.markerPkvGkv,
    "Apotheken IK": (row) => row?.apothekenIk,
    Apothekenname: (dataRow) => dataRow?.nameApotheke,
    Ort: (dataRow) => dataRow?.ortApotheke,
    Hersteller: (row) => row?.hersteller,
    PZN: (row) => row?.pzn,
    Artikelname: (row) => row?.artikelname,
    "Anzahl Packungen": (row) => formatFloat(row?.anzahlPackungen, false),
    "Differenz HeRa pro Packung": (row) => formatFloat(row?.diffHeraProPackung, true),
    "Differenz HeRa nach Abgabe": (row) => formatFloat(row?.diffHeraNachMengeAbgaben, true),
    "HeRa pro Packung Lauer": (row) => formatFloat(row?.heraProPackungLauer, true),
    "HeRa pro Packung ARZ": (row) => formatFloat(row?.heraProPackungArz, true),
    "HeRa Lauer mal Packungen": (row) => formatFloat(row?.heraLauerMalPackungen, true),
    "HeRa ARZ mal Packungen": (row) => formatFloat(row?.heraArzMalPackungen, true),
    "Differenz HAP pro Packungen": (row) => formatFloat(row?.diffHapProPackungen, true),
    "Differenz HAP nach Abgabe": (row) => formatFloat(row?.diffHapNachMengeAbgaben, true),
    "HAP pro Packung Lauer": (row) => formatFloat(row?.hapProPackungLauer, true),
    "HAP pro Packung ARZ": (row) => formatFloat(row?.hapProPackungARZ, true),
    "HAP Lauer mal Packungen": (row) => formatFloat(row?.hapLauerMalPackungen, true),
    "HAP ARZ mal Packungen": (row) => formatFloat(row?.hapArzMalPackungen, true),
    Customer: (row) => row?.customer,
};

const CheckCardSingle = () => {
    const {data, fetchNextPage} = useCheckData();
    const getNumFetchedRows: () => number = () => {
        return (
            data?.pages.reduce((acc, page) => acc + (page.data?.data?.length || 0), 0) || 0
        );
    };

    const dataPerRow = data?.pages.map(
        ((page) => page.data?.data)
    ).flat();
    const colNames = Object.keys(tableMap);
    const dataDisplayVals = dataPerRow?.map((rowData) => {
        return colNames.map((column) => tableMap[column](rowData));
    });


    return (
        <Grid
            templateAreas={`"filter main"`}
            gridTemplateColumns={"20% 80%"}
            height={"82vh"}
            width={"100%"}
        >

            <GridItem area={"filter"}>
                <Box width={"100%"} height={"100%"} padding={2}>
                    <FilterBarEinzelabrechnungen/>
                </Box>
            </GridItem>
            <GridItem area={"main"}>
                <Box
                    width="100%"
                    height="100%"
                    paddingTop={4}
                    overflow={"hidden"}
                >
                    <div style={{height: '82vh', overflow: 'auto'}}>
                        {Card(colNames, searchNames, useEinzelPruefungSortingStore, dataDisplayVals, getNumFetchedRows, fetchNextPage)}
                    </div>
                </Box>
            </GridItem>


        </Grid>
    )
};

export default CheckCardSingle;