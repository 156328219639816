import {create} from "zustand";

export interface SortingStore {
  descending: boolean;
  column: string;
  setColumn: (name: string) => void;
  toggleDescending: () => void;
}

function createSortingStore(defaultSortingColumn: string = "") {
    return (create<SortingStore>((set, get) => ({
        descending: false,
        column: defaultSortingColumn,
        toggleDescending: () => {
            set((state) => ({
                descending: !state.descending,
            }));
        },
        setColumn: (columnName: string) => {
            set((state) => ({
                column: columnName,
            }));
        },
    })))
}

export const useAggregationApothekenSortingStore = createSortingStore("hersteller");

export const useEinzelPruefungSortingStore = createSortingStore();

export const useProPznSortingStore = createSortingStore();

export const useMarktcenterSortingStore = createSortingStore();
