import { Box, Grid, GridItem, HStack } from "@chakra-ui/react";
import ApothekenCard from "./subpages/ApothekenCard";
import PageNavigationBar from "../components/PageNavigationBar";
import ExportButton from "../components/generic/ExportButton";
import { useAggregationsExport } from "../services/export-utils";
import FilterBarApotheken from "../components/apothekencenter/FilterBarApotheken";

export const ApothekenNavigation = (
  <PageNavigationBar
    navItems={[
      { name: "Übersicht Gruppen", subpath: "/gruppenuebersicht" },
      { name: "Abrechnungen Apotheken", subpath: "/abrechnungen" },
    ]}
    rootPath="/apothekencenter"
  />
);

const ApothekenAbrechnungen = () => {
  const { handleExport } = useAggregationsExport();
  return (
    <Grid
      templateAreas={`"footer footer"
                      "filter main"`}
      gridTemplateColumns={"20vw 80vw"}
      gridTemplateRows={"5vh 85vh"}
    >
      <GridItem area={"footer"}>
        <HStack
          width={"100%"}
          height={"100%"}
          paddingX={4}
          paddingTop={4}
          justifyContent={"space-between"}
        >
          {ApothekenNavigation}
          <ExportButton handleExport={handleExport} />
        </HStack>
      </GridItem>
      <GridItem area={"filter"}>
        <Box width={"100%"} height={"100%"} padding={4}>
          <FilterBarApotheken/>
        </Box>
      </GridItem>
      <GridItem area={"main"} >
        <Box
          width="100%"
          height="100%"
          paddingTop={4}
          paddingBottom={4}
          paddingRight={4}
        >
          <ApothekenCard />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ApothekenAbrechnungen;
