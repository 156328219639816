/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeraCheckPznData
 */
export interface HeraCheckPznData {
    /**
     * 
     * @type {string}
     * @memberof HeraCheckPznData
     */
    pzn?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckPznData
     */
    nameArz?: string;
    /**
     * 
     * @type {string}
     * @memberof HeraCheckPznData
     */
    abrechnungsmonat?: string;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    summeDifferenzHeraNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    lauerSummeHeraNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    arzSummeHeraNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    summeDifferenzHapNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    lauerSummeHapNachMengeAbgaben?: number;
    /**
     * 
     * @type {number}
     * @memberof HeraCheckPznData
     */
    arzSummeHapNachMengeAbgaben?: number;
}

/**
 * Check if a given object implements the HeraCheckPznData interface.
 */
export function instanceOfHeraCheckPznData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraCheckPznDataFromJSON(json: any): HeraCheckPznData {
    return HeraCheckPznDataFromJSONTyped(json, false);
}

export function HeraCheckPznDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraCheckPznData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pzn': !exists(json, 'pzn') ? undefined : json['pzn'],
        'nameArz': !exists(json, 'nameArz') ? undefined : json['nameArz'],
        'abrechnungsmonat': !exists(json, 'abrechnungsmonat') ? undefined : json['abrechnungsmonat'],
        'summeDifferenzHeraNachMengeAbgaben': !exists(json, 'summeDifferenzHeraNachMengeAbgaben') ? undefined : json['summeDifferenzHeraNachMengeAbgaben'],
        'lauerSummeHeraNachMengeAbgaben': !exists(json, 'lauerSummeHeraNachMengeAbgaben') ? undefined : json['lauerSummeHeraNachMengeAbgaben'],
        'arzSummeHeraNachMengeAbgaben': !exists(json, 'arzSummeHeraNachMengeAbgaben') ? undefined : json['arzSummeHeraNachMengeAbgaben'],
        'summeDifferenzHapNachMengeAbgaben': !exists(json, 'summeDifferenzHapNachMengeAbgaben') ? undefined : json['summeDifferenzHapNachMengeAbgaben'],
        'lauerSummeHapNachMengeAbgaben': !exists(json, 'lauerSummeHapNachMengeAbgaben') ? undefined : json['lauerSummeHapNachMengeAbgaben'],
        'arzSummeHapNachMengeAbgaben': !exists(json, 'arzSummeHapNachMengeAbgaben') ? undefined : json['arzSummeHapNachMengeAbgaben'],
    };
}

export function HeraCheckPznDataToJSON(value?: HeraCheckPznData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pzn': value.pzn,
        'nameArz': value.nameArz,
        'abrechnungsmonat': value.abrechnungsmonat,
        'summeDifferenzHeraNachMengeAbgaben': value.summeDifferenzHeraNachMengeAbgaben,
        'lauerSummeHeraNachMengeAbgaben': value.lauerSummeHeraNachMengeAbgaben,
        'arzSummeHeraNachMengeAbgaben': value.arzSummeHeraNachMengeAbgaben,
        'summeDifferenzHapNachMengeAbgaben': value.summeDifferenzHapNachMengeAbgaben,
        'lauerSummeHapNachMengeAbgaben': value.lauerSummeHapNachMengeAbgaben,
        'arzSummeHapNachMengeAbgaben': value.arzSummeHapNachMengeAbgaben,
    };
}

