import dayjs from "dayjs";

export const dateToIndex = (
  date: dayjs.Dayjs | null,
  minDate: dayjs.Dayjs | null | undefined
) => {
  if (date && minDate) {
    const yearIdx = date.year() - minDate.year();

    return yearIdx * 12 + date.month() + 2; // + 2 because of the sidebar
  }
  return 1;
};
export const stringToDate = (datestring: string | undefined) => {
  if (datestring) {
    const year = parseInt(datestring.substring(0, 4));
    const month = parseInt(datestring.substring(4, 6));
    const date = dayjs(new Date(year, month - 1)); // Months are zero indexed in js
    return date;
  }
  return null;
};

/**
 *
 * @param date
 * @returns string in format YYYYMM
 */
export const dateToString = (date: dayjs.Dayjs) => {
  const dateString =
    date.year().toString() + (date.month() + 1).toString().padStart(2, "0"); // js months are zero indexed, therefore + 1

  return dateString;
};

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

export const getTodayString = () => {
  const date = new Date()
  let month = date.getMonth() + 1 // months are zero indexed -> +1
  let paddedMonthString = month.toString().padStart(2, "0");
  let yearString = date.getFullYear().toString() // months are zero indexed -> +1

  return yearString + paddedMonthString;
};


type MonthMap = {
  [key: string]: string;
};

export const stringDateToDisplayString = (stringDate: string) => {
  const toMonthname: MonthMap = {
    "01": "Januar",
    "02": "Februar",
    "03": "März",
    "04": "April",
    "05": "Mai",
    "06": "Juni",
    "07": "Juli",
    "08": "August",
    "09": "September",
    "10": "Oktober",
    "11": "November",
    "12": "Dezember",
  };
  return toMonthname[stringDate.slice(4, 6)] + " " + stringDate.slice(0, 4);
};
