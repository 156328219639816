import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { useState } from "react";
import { ExportAggregationApothekenFormatEnum } from "../../autogen";

interface ExportButtonProps {
  handleExport: (
    format: ExportAggregationApothekenFormatEnum,
    separator: string
  ) => void;
}

/**
 * This component is for exporting tables to csv or xlsx
 * Currently there is only one endpoint for which this can be used,
 * but in the future more endpoints with the same options will be available.
 */
const ExportButton = ({ handleExport }: ExportButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [format, setFormat] = useState<string>("csv");
  const [separatorSelectable, setSeparatorSelectable] = useState<boolean>(true);
  const [separator, setSeparator] = useState<string>(";");

  const handleFormatChange = (value: string) => {
    setSeparatorSelectable(!separatorSelectable);
    setFormat(value);
  };

  return (
    <>
      <Button colorScheme="qyte" onClick={onOpen}>
        <Box as="span" display={{ base: "block", sm: "inline" }} isTruncated>
          Tabelle 
        </Box>
        &nbsp;
        <Box as="span" display={{ base: "block", sm: "inline" }} isTruncated>
          exportieren
        </Box>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Daten Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              onChange={handleFormatChange}
              value={format}
              marginBottom={4}
            >
              <Text> Datei-Format:</Text>
              <Stack direction="row" justifyContent={"left"} width={"100%"}>
                <Radio value="csv">csv</Radio>
                <Radio value="xlsx">xlsx</Radio>
              </Stack>
            </RadioGroup>
            <Box visibility={separatorSelectable ? "visible" : "hidden"}>
              <RadioGroup onChange={setSeparator} value={separator}>
                <Text> Separator: </Text>
                <Stack direction="row" justifyContent={"left"} width={"100%"}>
                  <Radio value=";">;</Radio>
                  <Radio value=",">,</Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="qyte"
              onClick={() =>
                handleExport(
                  format as ExportAggregationApothekenFormatEnum,
                  separator
                )
              }
            >
              Herunterladen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportButton;
