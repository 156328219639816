import { Box, Grid, GridItem, HStack } from "@chakra-ui/react";
import PageNavigationBar from "../components/PageNavigationBar";
import MarktcenterCard from "./subpages/MarktcenterCard";
import FilterBarMarktcenter from "../components/marktcenter/FilterBarMarktcenter";

export const MarktpruefungNavigation = (
  <PageNavigationBar
    navItems={[
      { name: "Detailansicht", subpath: "/detailansicht" },
    //  { name: "Herstellerübersicht", subpath: "/herstelleruebersicht" },
    //  { name: "Fallübersicht", subpath: "/falluebersicht" },
    ]}
    rootPath="/marktcenter"
  />
);

const MarktcenterWrapper = () => {
  return (
    <Grid
      templateAreas={`"footer footer"
                      "filter main"`}
      gridTemplateColumns={"20vw 80vw"}
      gridTemplateRows={"5vh 85vh"}
    >
      <GridItem area={"footer"}>
        <HStack
          width={"100%"}
          height={"100%"}
          paddingX={4}
          paddingTop={4}
          justifyContent={"space-between"}
        >
          {MarktpruefungNavigation}
        </HStack>
      </GridItem>
      <GridItem area={"filter"}>
        <Box width={"100%"} height={"100%"} padding={4}>
          <FilterBarMarktcenter/>
        </Box>
      </GridItem>
      <GridItem area={"main"} >
        <Box
          width="100%"
          height="100%"
          paddingTop={4}
          paddingBottom={4}
          paddingRight={4}
        >
          <MarktcenterCard />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default MarktcenterWrapper;
