/* tslint:disable */
/* eslint-disable */
/**
 * ArzneimittelRabattDashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeraCheckRequest } from './HeraCheckRequest';
import {
    HeraCheckRequestFromJSON,
    HeraCheckRequestFromJSONTyped,
    HeraCheckRequestToJSON,
} from './HeraCheckRequest';
import type { PaginationRequest } from './PaginationRequest';
import {
    PaginationRequestFromJSON,
    PaginationRequestFromJSONTyped,
    PaginationRequestToJSON,
} from './PaginationRequest';

/**
 * 
 * @export
 * @interface HeraPaginationRequest
 */
export interface HeraPaginationRequest {
    /**
     * 
     * @type {PaginationRequest}
     * @memberof HeraPaginationRequest
     */
    pagination?: PaginationRequest;
    /**
     * 
     * @type {HeraCheckRequest}
     * @memberof HeraPaginationRequest
     */
    heraCheckRequest?: HeraCheckRequest;
}

/**
 * Check if a given object implements the HeraPaginationRequest interface.
 */
export function instanceOfHeraPaginationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeraPaginationRequestFromJSON(json: any): HeraPaginationRequest {
    return HeraPaginationRequestFromJSONTyped(json, false);
}

export function HeraPaginationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeraPaginationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationRequestFromJSON(json['pagination']),
        'heraCheckRequest': !exists(json, 'heraCheckRequest') ? undefined : HeraCheckRequestFromJSON(json['heraCheckRequest']),
    };
}

export function HeraPaginationRequestToJSON(value?: HeraPaginationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationRequestToJSON(value.pagination),
        'heraCheckRequest': HeraCheckRequestToJSON(value.heraCheckRequest),
    };
}

