import {Button, IconButton} from "@chakra-ui/button";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {Stack, Text} from "@chakra-ui/layout";
import {Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/menu";
import {HStack} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {SearchAggregationApothekenRequest} from "../../autogen";
import {useFilterStore} from "../../hooks/ApothekenFilterStore";
import {useActiveTemplateStore, useDeleteTemplate, useFilterTemplates,} from "../../hooks/useTemplates";
import {CustomApi, msalConfig} from "../../services/authConfig";
import {PublicClientApplication} from "@azure/msal-browser";
import {FaRegTrashCan} from "react-icons/fa6";

const msalInstance = new PublicClientApplication(msalConfig);

const FilterTemplatesMenu = () => {
  const activeTemplate = useActiveTemplateStore(
    (state) => state.activeTemplate
  );
  const setActiveTemplate = useActiveTemplateStore(
    (state) => state.setActiveTemplate
  );
  const { data, refetch } = useFilterTemplates();
  const [filters, setFilters] = useState(data);
  
  useEffect(() => {
    setFilters(data);
  }, [data]);

  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await refetch();
  };

  const [api, setApi] = useState<CustomApi | null>(null);


  useEffect(() => {
    const customApi = new CustomApi(msalInstance);
    setApi(customApi);
  }, []);

  useEffect(() => {
    setFilters(data);
  }, [data]);

  return (
    <Stack>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} onClick={handleButtonClick}>
          {activeTemplate || "Filterprofil wählen"}
        </MenuButton>
        <MenuList zIndex={1000} opacity="100%">
        {(filters?.length || 0) > 0 ? (
          filters?.map((name) => (
            <SelectFilterButton
              key={name}
              templateName={name}
              setChoice={() => setActiveTemplate(name)}
              api={api}
              filters={filters}
              setFilters={setFilters}
            />
          ))
        ) : (
          <MenuItem>kein Template angelegt</MenuItem>
        )}
        </MenuList>
      </Menu>
    </Stack>
  );
};

const SelectFilterButton: React.FC<{
  templateName: string;
  setChoice: () => void;
  api: CustomApi | null;
  filters: string[] | undefined;
  setFilters: (filters: string[]) => void;
}> = ({ templateName, setChoice, api, filters, setFilters }) => {
  const setFilterValues = useFilterStore((state) => state.setFilterValues);
  const activeTemplate = useActiveTemplateStore(
    (state) => state.activeTemplate
  );
  const setActiveTemplate = useActiveTemplateStore(
    (state) => state.setActiveTemplate
  );
  const deleteTemplate = useDeleteTemplate();

  const validate = (response: string | undefined) => {
    if (response) {
      const parsed = JSON.parse(response);
      return parsed as SearchAggregationApothekenRequest;
    } else {
      return {};
    }
  };

  const handleSelect = async () => {
    if (api) {
      const templateApi = await api.getTemplateApi();

      setChoice();
      templateApi.getAggregationApothekenSearchTemplate(templateName).then((res) => {
        setFilterValues(validate(res.aggregationApothekenSearchJson));
      });
    }
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (activeTemplate === templateName) {
      setActiveTemplate("");
      setFilterValues({});
    }
    deleteTemplate(templateName);
    const newFilters = filters?.filter((filter: string) => filter !== templateName);
    setFilters(newFilters ? newFilters : []);
  };

  const [isDeletable, setIsDeletable] = useState(false);

  return (
    <MenuItem onClick={handleSelect}>
      <HStack justifyContent={"space-between"} width={"100%"}>
        <Text>{templateName}</Text>
        <IconButton
              aria-label="delete-tmplt"
              icon={<FaRegTrashCan />}
              colorScheme={isDeletable ? "red" : "qyte"}
              onClick={(event) => {
                event.stopPropagation();
                if (isDeletable) {
                  handleDelete(event);
                }
                setIsDeletable(!isDeletable);
              }}
              onBlur={() => {
                if (isDeletable) {
                  setIsDeletable(false);
                }
              }}
            />
      </HStack>
    </MenuItem>
  );
};

export default FilterTemplatesMenu;