const priceDigits = 2;
const quantityDigits = 2; //is independent of price but is for now identical

function determineDecimalPlaces(isPrice: boolean) {
    if (isPrice)
        return priceDigits
    else
        return quantityDigits
}

function switchUsWithEuDelimiters(stringNumber: string) {
    stringNumber = stringNumber?.replaceAll(",", "@")
    stringNumber = stringNumber?.replaceAll(".", ",")
    stringNumber = stringNumber?.replaceAll("@", ".")

    return stringNumber
}

function formatFloat(number: number | undefined, isPrice: boolean): FormatedFloat {
    if (typeof number === "number") {
        let decimalPlaces = determineDecimalPlaces(isPrice)
        let stringNumber = number.toFixed(decimalPlaces)

        return new FormatedFloat(switchUsWithEuDelimiters(stringNumber))
    } else
        return new FormatedFloat("")
}

export default formatFloat

export class FormatedFloat {
    value: string;

    constructor(value: string) {
        this.value = value;
    }
}
