import {useInfiniteQuery} from "@tanstack/react-query";
import {
  SalesQuantitiesPaginationResponse,
  type PaginationRequest,
  SearchSalesQuantitiesPaginatedRequest
} from "../autogen";
import {CustomApi, msalConfig} from "../services/authConfig";
import {useMarktcenterSortingStore} from "./sortingStore";
import {PublicClientApplication} from "@azure/msal-browser";
import batchSize from "../constants/BatchSize";
import { useMarktcenterFilterStore } from "./MarktcenterFilterStore";

const msalInstance = new PublicClientApplication(msalConfig);

const useMarktcenterData = () => {
  const filterValues = useMarktcenterFilterStore((state) => state.filterValues);
  const sortingStore = useMarktcenterSortingStore();

  return useInfiniteQuery<SalesQuantitiesPaginationResponse, Error>({
    initialPageParam: 0,
    getNextPageParam: (paginationResponse) => {
      if (paginationResponse.paginationDetails?.page || paginationResponse.paginationDetails?.page === 0) {
        return paginationResponse.paginationDetails?.page + 1;
      }
    },
    queryKey: [filterValues, sortingStore],
    queryFn: async ({pageParam = 0}) => {
      const api = new CustomApi(msalInstance);
      const marktcenterApi = await api.getMarktcenterApi();

      const searchMarktcenterPaginatedRequest = {
        pagination: {
          page: pageParam,
          batchSize: batchSize,
          orderBy: sortingStore.column,
          descending: sortingStore.descending
        } as PaginationRequest,
        searchRequest: 
        {
          ...filterValues,
          markerPkvGkv: (filterValues.markerPkvGkv || []).length > 0 ? filterValues.markerPkvGkv : "neither pkv nor gkv selected",
        }
      } as SearchSalesQuantitiesPaginatedRequest;
      const response =
          await marktcenterApi.searchPaginatedSalesQuantities(searchMarktcenterPaginatedRequest);
      return response;
    },
  });
};

export default useMarktcenterData;