import {Box, HStack, IconButton, Input, Text} from "@chakra-ui/react";
import {useState} from "react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {FaRegCheckSquare, FaRegEdit} from "react-icons/fa";
import {FaRegTrashCan} from "react-icons/fa6";
import {useDeleteGroup, useRenameGroup} from "../../hooks/useContracts";
import {colors} from "../../colorStyles";

interface EditableFieldProps {
  name: string;
}

/**
 *
 * @name string initial value of the field
 * Deletion function must be refactored for generic use
 * @returns
 */
const EditableField = ({ name }: EditableFieldProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [nameValue, setNameValue] = useState(name);

  return (
    <HStack
      height={"100%"}
      backgroundColor={colors.buttons[1]}
      borderRadius={"md"}
      paddingX={2}
    >
      {isEditable ? (
        <Input
          value={nameValue}
          backgroundColor={"white"}
          onChange={(event) => setNameValue(event.target.value)}
        />
      ) : (
        <Text
          maxWidth={"70%"}
          maxHeight={"100%"}
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textColor={"white"}
        >
          {nameValue}
        </Text>
      )}
      <EditMenu
        name={name}
        nameValue={nameValue}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      />
    </HStack>
  );
};

interface EditMenuProps {
  isEditable: boolean;
  setIsEditable: (val: boolean) => void;
  name: string;
  nameValue: string;
}

const EditMenu = ({
  name,
  isEditable,
  nameValue,
  setIsEditable,
}: EditMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const deleteGroup = useDeleteGroup();
  const renameGroup = useRenameGroup();

  return (
    <HStack marginLeft={"auto"} borderRadius={"md"} backgroundColor={"#f1c887"}>
      {isOpen ? (
        <>
            <Box paddingLeft={2}
                 onBlur={() => {
                     if (!isDeletable || !isEditable) {
                         setIsOpen(false);
                     }
                 }}
            >
            <IconButton
              size={"xs"}
              borderRadius={"md"}
              aria-label="delete-group"
              icon={<FaRegTrashCan />}
              colorScheme={isDeletable ? "red" : "qyte"}
              onClick={() => {
                if (isDeletable) {
                  deleteGroup(nameValue);
                }
                setIsDeletable(!isDeletable);
                setIsEditable(false);
              }}
              onBlur={() => {
                if (isDeletable) {
                  setIsDeletable(false);
                }
              }}
            />{" "}
          </Box>
          <Box>
            <IconButton
              size={"xs"}
              borderRadius={"md"}
              aria-label="save-group"
              colorScheme={isEditable ? "red" : "qyte"}
              icon={isEditable ? <FaRegCheckSquare /> : <FaRegEdit />}
              onClick={() => {
                if (isEditable && name !== nameValue) {
                  renameGroup(
                    { oldGroupName: name, newGroupName: nameValue },
                    {
                      onError: () => {},
                      onSuccess: () => {
                        setIsEditable(false);
                      },
                    }
                  );
                } else if (!isEditable) {
                  setIsEditable(true);
                } else if (isEditable && name === nameValue) {
                  setIsEditable(false);
                }
                setIsDeletable(false);
              }}
            />
          </Box>
        </>
      ) : (
        <></>
      )}
      <IconButton
        aria-label="menu-button"
        onClick={() => {
            if (!isEditable) {
                setIsOpen(!isOpen)
            }
        }}
        icon={<BsThreeDotsVertical />}
        size={"sm"}
        colorScheme={isOpen ? "qyte_active" : "qyte"}
      />
    </HStack>
  );
};

export default EditableField;
