import {
  AggregationApothekenApi,
  AggregationApothekenSearchTemplatesApi,
  Configuration,
  ConfigurationParameters,
  HeraCheckApi,
  SalesQuantitiesApi,
  SpecialContractApi,
} from "../autogen";
import {IPublicClientApplication, PublicClientApplication} from "@azure/msal-browser";

// Configuration object to be passed to MSAL instance on creation
export const msalConfig = {
  auth: {
    clientId: window.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" + window.REACT_APP_TENANT_ID + "/",
    redirectUri: window.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Scopes you add here will be prompted for user consent during sign-in
export const loginRequest = {
  scopes: ["User.Read"],
};

const acquireToken = async (instance: IPublicClientApplication) => {
  await instance.initialize(); // Ensure the MSAL instance is initialized
  const signedInUser = instance.getAllAccounts()[0];

  const request = {
    scopes: [
      window.REACT_APP_CLIENT_ID + "/.default",
      // "https://graph.microsoft.com/.default",
    ],
    account: signedInUser,
  };

  try {
    const res = await instance.acquireTokenSilent(request);
    return res.accessToken;
  } catch (err) {
    console.error(err);
    // In case of an error (e.g., token expiration), you can fallback to interactive token acquisition
    const res = await instance.acquireTokenPopup(request);
    return res.accessToken;
  }
};

export class CustomApi {
  instance: IPublicClientApplication;

  constructor(instance: IPublicClientApplication) {
    this.instance = instance;
  }

  sleep(ms: number) {
    return new Promise((res) => setTimeout(res, ms));
  }

  async getConfig() {
    const controller = new AbortController();
    const token = await acquireToken(this.instance);
    const params: ConfigurationParameters = {
      basePath: window.REACT_APP_BACKEND_URI,
      fetchApi: (url, init = {}) => {
        return fetch(url, {
          ...init,
          signal: controller.signal,
          headers: {
            ...init.headers,
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        });
      },
    };
    return new Configuration(params);
  }

  async getAggregationApi() {
    const configuration = await this.getConfig();
    return new AggregationApothekenApi(configuration);
  }

  async getMarktcenterApi() {
    const configuration = await this.getConfig();
    return new SalesQuantitiesApi(configuration);
  }

  async getTemplateApi() {
    const configuration = await this.getConfig();
    return new AggregationApothekenSearchTemplatesApi(configuration);
  }

  async getContractApi() {
    const configuration = await this.getConfig();
    return new SpecialContractApi(configuration);
  }

  async getCheckApi() {
    const configuration = await this.getConfig();
    return new HeraCheckApi(configuration);
  }

	async getVersion() {
	        const token = await acquireToken(this.instance);
	        const response = await fetch(window.REACT_APP_BACKEND_URI + "/version", {
	        headers: {
	                Authorization: "Bearer " + token,
	                Accept: "text/plain",
	            },
	    });
	        if (!response.ok) {
	            throw new Error(`HTTP error! status: ${response.status}`);
	        }
	        return await response.text(); // Get the response as text
	    }
    
  async getsalesQuantitiesApi() {
    const configuration = await this.getConfig();
    return new SalesQuantitiesApi(configuration);
  }
}

// Instantiate the MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Ensure that the MSAL instance is initialized before exporting it
const initializeMsalInstance = async () => {
  await msalInstance.initialize();
};

initializeMsalInstance().then(() => {
  console.log("MSAL instance initialized");
}).catch((error) => {
  console.error("MSAL instance initialization failed:", error);
});

export default msalInstance;
