import {Box, Button, Grid, GridItem, Heading, HStack, Text, VStack,} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {colors} from "../../colorStyles";
import {useHeraStore} from "../../hooks/heraStore";
import {useArzPruefungsPlainData, usePruefungAvailables,} from "../../hooks/useCheckData";
import DropDownMenu from "../../components/generic/DropDownMenu";
import floatFormater from "../../components/generic/FloatFormater";
import { useEffect } from "react";

interface FancyFieldProps {
  type?: string;
  content: string | number;
    alignItems: string;
}

const FancyField = ({type, content, alignItems}: FancyFieldProps) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-evenly"}
      paddingLeft={"5%"}
      paddingRight={"5%"}
      alignItems={alignItems === "" ? "center" : alignItems}
      borderRadius={"md"}
      height={"100%"}
      backgroundColor={type === "heading" ? colors.buttons[1] : "white"}
      textColor={type === "heading" ? "white" : "black"}
    >
      <Text as="b">{content}</Text>
    </Box>
  );
};

const HeraCheckOverview = () => {

  const { monthOptions } = usePruefungAvailables();
  const navigate = useNavigate();
  const heraStore = useHeraStore();
  const {data} = useArzPruefungsPlainData();
  const rowNum = data ? data.data.length + 1 : 1;


  const handlePznClick = (arz: string) => {
    heraStore.setArz(arz);
    navigate("/pruefungscenter/pro_pzn");
  };
  const handleAbrechnungClick = (arz: string) => {
    heraStore.setArz(arz);
    navigate("/pruefungscenter/einzelabrechnungen");
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      borderRadius={"md"}
      backgroundColor={colors.primary[1000]}
      padding={4}
      overflow={"auto"}
      marginTop={4}
    >
      <VStack>
        <HStack>
          <Heading
            whiteSpace={"nowrap"}
            textColor={"white"}
            as={"b"}
            fontSize={"2xl"}
          >
            Ausgewählter Monat:
          </Heading>
          <DropDownMenu
            setValue={heraStore.setMonth}
            options={monthOptions}
            value={heraStore.month || (monthOptions && monthOptions.length > 0 ? monthOptions[monthOptions.length -1].value : "")}
            defaultValue="none"
            fontSize={24}
            fontWeight="bold"
          />
        </HStack>
        <Grid
          paddingTop={4}
          templateRows={`repeat(${rowNum}, 1fr)`}
          templateColumns={`repeat(${6}, 1fr)`}
          gap={4}
          textColor={"white"}
        >
          <GridItem rowStart={1} colStart={1}>
              <FancyField
                  type="heading"
                  content={"ARZ"}
                  alignItems={"center"}></FancyField>
          </GridItem>
          <GridItem rowStart={1} colStart={2}>
            <FancyField
              type="heading"
              content={"Gesamtdifferenz HeRa"}
              alignItems={"center"}
            ></FancyField>
          </GridItem>
          <GridItem rowStart={1} colStart={3}>
            <FancyField
              type="heading"
              content={"Gesamtdifferenz HaP"}
              alignItems={"center"}
            ></FancyField>
          </GridItem>
          <GridItem rowStart={1} colStart={4}>
            <FancyField
              type="heading"
              content={"Betroffene Apotheken"}
              alignItems={"center"}
            ></FancyField>
          </GridItem>
          <GridItem rowStart={1} colStart={5}>
            <Button
              onClick={() => handleAbrechnungClick("")}
              height={"100%"}
              width={"100%"}
            >
              Alle Einzelabrechnungen
            </Button>
          </GridItem>
          <GridItem rowStart={1} colStart={6}>
            <Button
              onClick={() => handlePznClick("")}
              height={"100%"}
              width={"100%"}
            >
              {" "}
              Alle Pro PZN{" "}
            </Button>
          </GridItem>

          {data?.data.map((arzData, arzIdx) => {
            return (
              <>
                <GridItem rowStart={arzIdx + 2} colStart={1}>
                    <FancyField
                        content={arzData.nameArz}
                        alignItems={"left"}/>
                </GridItem>
                <GridItem rowStart={arzIdx + 2} colStart={2}>
                  <FancyField
                      content={floatFormater(arzData.summeDifferenzHeraNachMengeAbgaben, true).value}
                      alignItems={"end"}
                  />
                </GridItem>
                <GridItem rowStart={arzIdx + 2} colStart={3}>
                  <FancyField
                      content={floatFormater(arzData.summeDifferenzHapNachMengeAbgaben, true).value}
                      alignItems={"end"}
                  />
                </GridItem>
                <GridItem rowStart={arzIdx + 2} colStart={4}>
                    <FancyField
                        content={arzData.betroffeneApotheken}
                        alignItems={"end"}/>
                </GridItem>
                <GridItem rowStart={arzIdx + 2} colStart={5} display="flex" alignItems="center" justifyContent="center">
                  <Button
                    colorScheme="qyte"
                    onClick={() => handleAbrechnungClick(arzData.nameArz)}
                    height={"100%"}
                    width={"75%"}
                    size="sm"
                  >
                    Einzelabrechnungen
                  </Button>
                </GridItem>
                <GridItem rowStart={arzIdx + 2} colStart={6} display="flex" alignItems="center" justifyContent="center">
                  <Button
                    colorScheme="qyte"
                    onClick={() => handlePznClick(arzData.nameArz)}
                    height={"100%"}
                    width={"75%"}
                    size="sm"
                  >
                    Pro PZN
                  </Button>
                </GridItem>
              </>
            );
          })}
        </Grid>
      </VStack>
    </Box>
  );
};

export default HeraCheckOverview;
